import React from "react";

function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={12}
      viewBox="0 0 15 12"
    >
      <path
        id="icons8_mail"
        d="M6.438,8A2.446,2.446,0,0,0,4,10.438v7.125A2.446,2.446,0,0,0,6.438,20H16.563A2.446,2.446,0,0,0,19,17.563V10.438A2.446,2.446,0,0,0,16.563,8Zm0,1.125H16.563a1.3,1.3,0,0,1,1.313,1.313v.415L11.5,14.3,5.125,10.852v-.415A1.3,1.3,0,0,1,6.438,9.125ZM5.125,12.131l6.108,3.3a.563.563,0,0,0,.535,0l6.108-3.3v5.432a1.3,1.3,0,0,1-1.312,1.313H6.438a1.3,1.3,0,0,1-1.312-1.312Z"
        transform="translate(-4 -8)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default MailIcon;
