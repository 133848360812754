import React from "react";
import OperationalReportIcon from "../../../../../assets/images/operation Report.png";
import AccordionComponent from "../../../../../components/accordion/AccordionComponent";
import Distribution from "./Distribution";
import Storage from "./Storage";
import Turbidity from "./Turbidity";

function OperationalReport({ siteName, siteId, timeZone }) {
  const commonProps = {
    siteId,
    siteName,
    timeZone,
  };

  return (
    <AccordionComponent name="Operational Report" icon={OperationalReportIcon}>
      <Turbidity {...commonProps} />
      <Storage {...commonProps} />
      <Distribution {...commonProps} />
    </AccordionComponent>
  );
}

export default OperationalReport;
