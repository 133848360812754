import { Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SITE, UPDATE_SITE } from "../../../../api/endpoints";
import { useRequest } from "../../../../api/hooks";
import LocationInput2 from "../../../../assets/icons/LocationInput2";
import ModuleIcon from "../../../../assets/icons/ModuleIcon";
import ChemicalConsumption from "../../../../assets/images/Chemical Consumption.png";
import ChlorineManagement from "../../../../assets/images/Chlorination module.png";
import CoagulationManagement from "../../../../assets/images/Coagulation Module.png";
import FiltrationManagement from "../../../../assets/images/Filteration Module.png";
import MaintenanceReport from "../../../../assets/images/Maintenance Report.png";
import ManagementReport from "../../../../assets/images/Managment Report.png";
import OperationalReport from "../../../../assets/images/operation Report.png";
import Button from "../../../form/button/Button";
import ButtonRow from "../../../form/button/ButtonRow";
import NextButton from "../../../form/button/NextButton";
import SelectBox from "../../../form/selectBox/SelectBox";
import Item from "./Item";
import classes from "./Styles.module.scss";

const modules = [
  { name: "Maintenance Report", icon: MaintenanceReport },
  { name: "Filtration Management", icon: FiltrationManagement },
  { name: "Operational Report", icon: OperationalReport },
  { name: "Management Report", icon: ManagementReport },
  { name: "Chlorine Management", icon: ChlorineManagement },
  { name: "Coagulation Management", icon: CoagulationManagement },
  { name: "Chemical Consumption", icon: ChemicalConsumption },
];

function Modules({ type = "site" }) {
  let { siteID } = useParams();
  let { pathname } = useLocation();
  let addMode = pathname?.includes("add");
  let navigate = useNavigate();
  // type => site or page => This component is used on another page (Edit Modules Page)
  let [sitesOptions, setSiteOptions] = useState([]);
  let [site, setSite] = useState(null);
  let [selectedModules, setSelectedModules] = useState([]);
  let [modulesOptions] = useState([
    "Select All",
    ...modules?.map((item) => item?.name),
  ]);

  let [{ data: sites, setData: setSites, loading: sitesLoading }] =
    useRequest(SITE);

  let [, saveRequest] = useRequest(UPDATE_SITE, {
    method: "PUT",
  });

  useEffect(() => {
    if (siteID && sites) {
      let find = sites?.find((item) => Number(item?.id) === Number(siteID));
      selectSiteHandle(`${find?.city} - ${find?.siteName}`, find);
      setSite(find);
    }
  }, [siteID, sites]);

  function addModule(name) {
    if (name === "Select All") {
      setSelectedModules([...modules]);
    } else {
      let find = modules?.find((i) => i?.name === name);
      if (!selectedModules?.includes(find)) {
        setSelectedModules([...selectedModules, find]);
      }
    }
  }

  function deleteModule(name) {
    let list = selectedModules?.filter((m) => m?.name !== name);
    setSelectedModules([...list]);
  }

  useEffect(() => {
    if (sites) {
      let list = sites?.map((item) => {
        return `${item?.city} - ${item?.siteName}`;
      });
      setSiteOptions([...list]);
    }
  }, [sites]);

  function selectSiteHandle(selected, existSite) {
    let find = existSite;
    if (!existSite) {
      let siteName = selected?.split(" - ")?.[1];
      find = sites?.find((item) => item?.siteName === siteName);
    }
    let modulesList = [];
    for (let i = 0; i < find?.modules?.length; i++) {
      for (let a = 0; a < modules?.length; a++) {
        let moduleName = modules[a]?.name?.slice(0, 3);

        if (moduleName === find?.modules[i]) {
          if (!modulesList?.includes(modules[a])) {
            modulesList.push(modules[a]);
          }
        }
      }
    }
    setSelectedModules([...modulesList]);
    setSite(find);
  }

  function saveHandle() {
    if (!site) {
      return toast.error("Please select the site first");
    }
    let modules = selectedModules?.map((item) => item?.name?.slice(0, 3));
    saveRequest({
      ...site,
      modules,
    });
    let list = [...sites];
    let find = list?.find((item) => item?.id === site?.id);
    let index = list?.indexOf(find);
    list[index] = {
      ...site,
      modules,
    };
    return setSites([...list]);
  }

  async function nextHandle() {
    await saveHandle();
    navigate(`${addMode ? "/add-site/" : "/edit-sites/"}${siteID}/image`);
  }

  return (
    <div>
      {type === "site" && (
        <div>3. Choose or edit different modules for the site.</div>
      )}
      <br />
      <br />
      <Grid container>
        <Grid item xs={6}>
          <SelectBox
            label="Site Name"
            Icon={LocationInput2}
            setSelected={selectSiteHandle}
            selected={`${site?.city} - ${site?.siteName}`}
            loading={sitesLoading}
            items={sitesOptions}
            placeholder="Select an User to EditE"
          />
          <br />
          <br />
          <div className={classes.select_container}>
            <div>Add or Edit Modules To Selected Site</div>
            <br />
            <SelectBox
              label="Module"
              Icon={ModuleIcon}
              items={modulesOptions}
              setSelected={addModule}
              placeholder="Select a Item to Assigned as an Modules"
            />
            <Divider sx={{ mt: "25px", mb: "25px" }} />
            <div className={classes.items_container}>
              {selectedModules?.map((item) => {
                return (
                  <Item
                    deleteModule={deleteModule}
                    name={item?.name}
                    icon={item?.icon}
                  />
                );
              })}
            </div>
          </div>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>

      <ButtonRow justifyContent="flex-end">
        {type === "site" ? (
          <NextButton onClick={nextHandle} />
        ) : (
          <>
            <Button onClick={saveHandle}>Save</Button>
          </>
        )}
      </ButtonRow>
    </div>
  );
}

export default Modules;
