import { Stack } from "@mui/material";
import React from "react";
// import warning from "../../../../../assets/images/warning.png";
import errorImage from "../../../../../assets/images/notFound.png";

function Warning({ message, error }) {
  return (
    <Stack direction="column" alignItems="center" spacing={2} sx={{ my: 2 }}>
      {/* <img src={error ? warning : errorImage} alt="warning img" /> */}
      <img src={errorImage} alt="warning img" />
      <div>{message}</div>
    </Stack>
  );
}

export default Warning;
