import { Stack } from "@mui/material";
import React from "react";
import { LocationIcon } from "../../../../assets/icons";
import classes from "./Site.module.scss";
import { useParams } from "react-router-dom";

export default function SiteItem({ site, ...props }) {
  let { siteId } = useParams();
  return (
    <Stack
      direction="row"
      sx={{
        background: Number(siteId) === Number(site?.id) ? "white" : "#FCFCFC",
      }}
      className={classes.root_SiteLocation}
      {...props}
    >
      <div className={classes.badge_style}>
        <LocationIcon />
      </div>
      <div className={classes.info_location}>
        <span>{site?.siteName}</span>
        <span
          style={{
            color: "#9E9E9E",
            fontSize: "11px",
          }}
        ></span>
      </div>
    </Stack>
  );
}
