import React from "react";

function Upload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      viewBox="0 0 50 50"
    >
      <path
        id="icons8_download_from_ftp"
        d="M7,3A5.005,5.005,0,0,0,2,8V38a5.005,5.005,0,0,0,5,5h5V38h7.5V25.5h15V38H42v5h5a5.005,5.005,0,0,0,5-5V30.5a21.194,21.194,0,0,1-2.534-.151l-.044-.01a22.685,22.685,0,0,1-2.349-.41,22.5,22.5,0,0,1-17.007-17c-.172-.767-.308-1.551-.405-2.344a.5.5,0,0,1-.01-.054A21.11,21.11,0,0,1,29.5,8H27L22,3ZM34.5,8A17.538,17.538,0,0,0,52,25.5v-5A12.461,12.461,0,0,1,39.5,8Zm10,0A7.538,7.538,0,0,0,52,15.5v-5A2.46,2.46,0,0,1,49.5,8Zm-20,22.5V43H17L27,53,37,43H29.5V30.5Z"
        transform="translate(-2 -3)"
        fill="#000c83"
      />
    </svg>
  );
}

export default Upload;
