import { LinearProgress } from "@mui/material";
import React from "react";
import { CITY_NAMES } from "../../api/endpoints";
import { useRequest } from "../../api/hooks";
import City from "./components/city/City";

function CitySelect({ setCity }) {
  let [{ data: cities, loading: cityLoading }] = useRequest(CITY_NAMES);

  return (
    <div>
      {cityLoading ? (
        <LinearProgress />
      ) : (
        <City setCity={setCity} cities={cities} />
      )}
      <br />
    </div>
  );
}

export default CitySelect;
