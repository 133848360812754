import React from "react";
import { localStorageTokenName } from "../config";
let UserStateContext = React.createContext();
let UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      // throw new Error(`Unhandled action type: ${action.type}`);
      return { ...state, isAuthenticated: false };
    }
  }
}

function UserProvider({ children }) {
  let [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem(localStorageTokenName),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  let context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  let context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser({ loginData, dispatch, navigate }) {
  localStorage.setItem(localStorageTokenName, loginData?.token);
  localStorage.setItem("user_data", JSON.stringify(loginData?.user));
  dispatch({ type: "LOGIN_SUCCESS" });
  navigate("/");
}

function signOut(dispatch, navigate) {
  localStorage.removeItem(localStorageTokenName);
  localStorage.removeItem("user_data");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  navigate("/login");
}
