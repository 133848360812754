import React, { useEffect } from "react";
import SiteInformation from "./components/siteInformation/SiteInformation";
import { useRequest } from "../../api/hooks";
import { SITE } from "../../api/endpoints";
import { LinearProgress } from "@mui/material";
import MaintenanceReport from "./components/modules/maintenance/MaintenanceReport";
import FiltrationManagement from "./components/modules/FiltrationManagement";
import OperationalReport from "./components/modules/operational/OperationalReport";
import ChlorineManagement from "./components/modules/ChlorineManagement";
import ManagementReport from "./components/modules/ManagementReport";
import CoagulationManagement from "./components/modules/CoagulationManagement";
import ChemicalConsumption from "./components/modules/chemical/ChemicalConsumption";
import { useParams } from "react-router-dom";

function SiteDetails() {
  let { siteId } = useParams();
  let [{ data: site, loading: siteLoading }, fetchSite] = useRequest(
    SITE + "/" + siteId,
    { method: "GET", autoFetch: false }
  );

  useEffect(() => {
    fetchSite();
  }, [siteId]);

  return (
    <>
      {siteLoading ? (
        <LinearProgress />
      ) : (
        <>
          {site && (
            <>
              <SiteInformation site={site} siteName={site?.siteName} />
              <br />
              <br />
            </>
          )}
          {site?.modules?.includes("Mai") && (
            <MaintenanceReport
              siteName={site?.siteName}
              siteId={siteId}
              timeZone={site?.timeZoneInfoId}
            />
          )}
          {site?.modules?.includes("Fil") && (
            <FiltrationManagement
              siteName={site?.siteName}
              siteId={siteId}
              timeZone={site?.timeZoneInfoId}
            />
          )}
          {site?.modules?.includes("Ope") && (
            <OperationalReport
              siteName={site?.siteName}
              siteId={siteId}
              timeZone={site?.timeZoneInfoId}
            />
          )}
          {site?.modules?.includes("Man") && (
            <ManagementReport
              siteName={site?.siteName}
              siteId={siteId}
              timeZone={site?.timeZoneInfoId}
            />
          )}
          {site?.modules?.includes("Chl") && (
            <ChlorineManagement
              siteName={site?.siteName}
              siteId={siteId}
              timeZone={site?.timeZoneInfoId}
            />
          )}
          {site?.modules?.includes("Coa") && (
            <CoagulationManagement
              siteName={site?.siteName}
              siteId={siteId}
              timeZone={site?.timeZoneInfoId}
            />
          )}
          {site?.modules?.includes("Che") && (
            <ChemicalConsumption
              siteName={site?.siteName}
              siteId={siteId}
              timeZone={site?.timeZoneInfoId}
            />
          )}
        </>
      )}
    </>
  );
}

export default SiteDetails;
