import React from "react";

function ThirstIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height="14.675"
      viewBox="0 0 15 14.675"
      {...props}
    >
      <path
        id="icons8_Thirst"
        d="M9.5,1.953a1.194,1.194,0,0,0-.894.4h0A27.7,27.7,0,0,0,5.861,6.027a10.414,10.414,0,0,0-1.819,5.147,5.455,5.455,0,0,0,10.909,0,10.4,10.4,0,0,0-1.818-5.137,28.393,28.393,0,0,0-2.739-3.677v0A1.2,1.2,0,0,0,9.5,1.953Zm0,1.023a.17.17,0,0,1,.127.057h0a27.534,27.534,0,0,1,2.637,3.538,9.734,9.734,0,0,1,1.667,4.6,4.432,4.432,0,0,1-8.864,0A9.742,9.742,0,0,1,6.732,6.561,26.843,26.843,0,0,1,9.369,3.033.171.171,0,0,1,9.5,2.976ZM3.36,7.424a.682.682,0,1,0,.682.682A.682.682,0,0,0,3.36,7.424Zm12.273,0a.682.682,0,1,0,.682.682A.682.682,0,0,0,15.632,7.424ZM2.678,11.174a.682.682,0,1,0,.682.682A.682.682,0,0,0,2.678,11.174Zm13.636,0a.682.682,0,1,0,.682.682A.682.682,0,0,0,16.314,11.174ZM3.36,14.583a.682.682,0,1,0,.682.682A.682.682,0,0,0,3.36,14.583Zm12.273,0a.682.682,0,1,0,.682.682A.682.682,0,0,0,15.632,14.583Z"
        transform="translate(-1.996 -1.953)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default ThirstIcon;
