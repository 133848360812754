import React from "react";

function DateFilter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.96"
      height={21}
      viewBox="0 0 20.96 21"
    >
      <g id="Group_10803" data-name="Group 10803" transform="translate(0 3)">
        <path
          id="icons8_Tear-Off_Calendar"
          d="M9.25,6A3.262,3.262,0,0,0,6,9.25v11.5A3.262,3.262,0,0,0,9.25,24h11.5A3.262,3.262,0,0,0,24,20.75V9.25A3.262,3.262,0,0,0,20.75,6Zm0,1.5h11.5A1.739,1.739,0,0,1,22.5,9.25V10H7.5V9.25A1.739,1.739,0,0,1,9.25,7.5Zm-1.75,4h15v9.25a1.739,1.739,0,0,1-1.75,1.75H9.25A1.739,1.739,0,0,1,7.5,20.75ZM16.75,15A1.762,1.762,0,0,0,15,16.75v2a1.762,1.762,0,0,0,1.75,1.75h2a1.762,1.762,0,0,0,1.75-1.75v-2A1.762,1.762,0,0,0,18.75,15Zm0,1.5h2a.239.239,0,0,1,.25.25v2a.239.239,0,0,1-.25.25h-2a.239.239,0,0,1-.25-.25v-2A.239.239,0,0,1,16.75,16.5Z"
          transform="translate(-6 -6)"
          fill="#484848"
        />
        <g
          id="Ellipse_7990"
          data-name="Ellipse 7990"
          transform="translate(12.96)"
          fill="#ff4040"
          stroke="#f6f6f6"
          strokeWidth={3}
        >
          <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
          <circle cx="2.5" cy="2.5" r={4} fill="none" />
        </g>
      </g>
    </svg>
  );
}

export default DateFilter;
