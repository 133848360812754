import L from "leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import React, { useEffect, useState } from "react";
import {
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";
import LocationIconNotOk from "../../../../assets/images/LocationIconNotOk.png";
import LocationIcon from "../../../../assets/images/locationMarker.png";
import Button from "../../../../components/form/button/Button";
import { useNavigate, useParams } from "react-router-dom";

const okSite = new L.Icon({
  iconUrl: LocationIcon,
  iconRetinaUrl: LocationIcon,
  iconAnchor: [45, 60],
  iconSize: [85, 95],
});
const notOkSite = new L.Icon({
  iconUrl: LocationIconNotOk,
  iconRetinaUrl: LocationIconNotOk,
  iconAnchor: [45, 60],
  iconSize: [85, 95],
});

function FlyTo({ location }) {
  const map = useMap();
  if (location) map.flyTo(location, 8);
}
function FlyToSite({ location }) {
  const map = useMap();
  if (location) map.flyTo(location, 14);
}

function Map({ sites }) {
  let { city, siteId } = useParams();

  const [loc, updLoc] = useState([49.2827, -123.1207]);
  const [locSite, updLocSite] = useState(null);
  let navigate = useNavigate();
  let { BaseLayer } = LayersControl;

  useEffect(() => {
    const geocoder = L.Control.Geocoder.nominatim();
    if (city)
      geocoder.geocode(city, (results) => {
        var r = results[0];
        if (r) {
          const { lat, lng } = r?.center;
          updLoc({ lat, lng });
          updLocSite(null);
        }
      });
  }, [city]);

  useEffect(() => {
    if (siteId) {
      let find = sites?.find((item) => item?.id === Number(siteId));
      if (find) {
        updLocSite([find?.locationY, find?.locationX]);
      }
    }
  }, [siteId]);

  return (
    <div style={{ margin: 15, borderRadius: 5 }}>
      <MapContainer
        style={{ width: "100%", height: "675px" }}
        zoomControl={false}
        boxZoom={false}
        scrollWheelZoom={true}
        center={loc}
        zoom={loc ? 12 : 3}
      >
        <LayersControl>
          <BaseLayer checked name="Google Map">
            <TileLayer
              url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
          </BaseLayer>
          <BaseLayer name="OpenStreetMap">
            <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </BaseLayer>
          <BaseLayer name="OpenTopoMap">
            <TileLayer url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png" />
          </BaseLayer>
          <BaseLayer name="satellite">
            <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          </BaseLayer>
        </LayersControl>

        <FlyTo location={loc} />
        {locSite && <FlyToSite location={locSite} />}
        {sites?.map((item) => {
          return (
            <Marker
              icon={item?.status === "OK" || !item?.status ? okSite : notOkSite}
              position={[item?.locationY, item?.locationX]}
            >
              <Popup>
                <b>SiteName : </b>
                {item?.siteName}
                <br />
                <br />
                <Button
                  onClick={() => {
                    navigate(`/system/${city}/${siteId}`);
                  }}
                >
                  Check System
                </Button>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
}

export default Map;
