import React, { useEffect } from "react";
import CitySelect from "../../components/siteSelect/CitySelect";
import SiteDetails from "./SiteDetails";
import SiteSelect from "../../components/siteSelect/SiteSelect";
import { useParams } from "react-router-dom";
import { SITE_BY_CITY } from "../../api/endpoints";
import { useRequest } from "../../api/hooks";

function System() {
  let { city, siteId } = useParams();

  let [{ data: sites, loading: siteLoading }, fetchSites] = useRequest(
    SITE_BY_CITY,
    {
      method: "POST",
      data: {
        city,
      },
      autoFetch: false,
    }
  );
  useEffect(() => {
    if (city) {
      fetchSites();
    }
  }, [city]);

  return (
    <div>
      <CitySelect />
      <SiteSelect loading={siteLoading} sites={sites} />
      {siteId && <SiteDetails />}
    </div>
  );
}

export default System;
