import { ButtonBase, Chip, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import AlarmIcon from "../../../../../assets/icons/AlarmIcon";
import PlayListIcon from "../../../../../assets/icons/PlayListIcon";
import SignalInput from "../../../../../assets/icons/SignalInput";
import DashedDivider from "../../../../dashedDivider/DashedDivider";
import Button from "../../../../form/button/Button";
import ButtonRow from "../../../../form/button/ButtonRow";
import PrimaryInput from "../../../../form/inputs/primaryInput/PrimaryInput";
import SelectBox from "../../../../form/selectBox/SelectBox";
import SensorItem from "./SensorItem";
import classes from "./Style.module.scss";

import { toast } from "react-hot-toast";
import { GET_SENSORS_TYPE } from "../../../../../api/endpoints";
import { useRequest } from "../../../../../api/hooks";
import SensorTypeModal from "../../../../sensorTypeModal/SensorTypeModal";
import MenuSettingIcon from "../../../../../assets/icons/MenuSettingIcon";
import { useSelector } from "react-redux";

let initialData = {
  sensorCode: "",
  description: "",
  sensorTypeId: null,
  alertConditions: "",
  formula: "",
};

function SensorForm({ siteId, sensors, setSensors }) {
  const [sensorForEdit, setSensorForEdit] = useState(null);
  const [sensorTypeModal, setSensorTypeModal] = useState(false);
  const [descError, setDescError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [formValues, setFormValues] = useState({
    ...initialData,
  });

  const [sortedSensorTypes, setSortedSensorTypes] = useState([]);
  const [formulaDisabled, setFormulaDisabled] = useState(true);

  let sensorTypes = useSelector((state) => state?.sensorTypes?.list);
  let sensorTypesLoading = useSelector((state) => state?.sensorTypes?.loading);

  useEffect(() => {
    if (sensorTypes) {
      let list = sensorTypes?.map((item) => {
        return {
          ...item,
          parameter: `${item?.parameter} (${item?.unit})`,
        };
      });
      setSortedSensorTypes([...list]);
    }
  }, [sensorTypes]);

  useEffect(() => {
    if (sensorForEdit) {
      setFormValues({ ...sensorForEdit });
    }
  }, [sensorForEdit]);

  function openModal() {
    setSensorTypeModal(true);
  }

  function closeModal() {
    setSensorTypeModal(false);
  }

  const sensorTypeHandle = (typeId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      sensorTypeId: typeId,
    }));
  };
  const physicalHandle = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      isVirtual: value === "Virtual" ? true : false,
      formula: value === "Virtual" ? prevValues?.formula : "",
    }));
    setFormulaDisabled(value === "Virtual" ? false : true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  function deleteSensorHandle(sensor) {
    let list = [...sensors];
    let filter = list?.filter(
      (i) => i?.id !== sensor?.id || i?.sensorCode !== sensor?.sensorCode
    );
    setSensors([...filter]);
  }

  function editSensorHandle() {
    let list = [...sensors];
    let item = list?.find((i) => i?.id === sensorForEdit?.id);
    let index = list?.indexOf(item);
    list[index] = formValues;
    setSensors([...list]);
    resetForm();
  }

  function addSensorHandle() {
    if (!formValues?.sensorCode || !formValues?.description) {
      if (!formValues?.sensorCode) {
        setNameError(true);
      }
      if (!formValues?.description) {
        setDescError(true);
      }
      return toast.error("Sensor Code and Description is Required");
    }

    setSensors([...sensors, { ...formValues }]);
    resetForm();
  }
  function resetForm() {
    setSensorForEdit(null);
    setFormValues({ ...initialData });
    setDescError(false);
    setNameError(false);
  }

  return (
    <div className={classes.sensor_container} id="sensorFormEdit">
      <div className={classes.title}>
        {sensorForEdit ? "Edit Sensor" : " Add New Sensor"}
      </div>
      <div>
        For add new sensor complete the following information and click on the
        Add button.
      </div>
      <br />
      <br />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <PrimaryInput
              label="Sensor Code *"
              name="sensorCode"
              Icon={SignalInput}
              value={formValues?.sensorCode}
              onChange={handleChange}
              placeholder="Enter Sensor Code"
              required={true}
              error={nameError}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectBox
              label="Physical / Virtual Sensor"
              name="isVirtual"
              Icon={SignalInput}
              selected={formValues?.isVirtual ? "Virtual" : "Physical"}
              setSelected={physicalHandle}
              placeholder="Select an Item"
              items={["Physical", "Virtual"]}
            />
          </Grid>
          <Grid item xs={4}>
            <PrimaryInput
              name="formula"
              label="Formula"
              Icon={SignalInput}
              value={formValues?.formula}
              onChange={handleChange}
              placeholder="Enter the formula"
              disabled={formulaDisabled}
            />
          </Grid>
          <Grid item container alignItems="flex-end" spacing={0} xs={4}>
            <Grid item xs={10}>
              <SelectBox
                name="sensorTypeId"
                label="Sensor Type"
                loading={sensorTypesLoading}
                Icon={SignalInput}
                optionPropertyName="parameter"
                optionValuePropertyName="id"
                selected={formValues?.sensorTypeId}
                setSelected={sensorTypeHandle}
                placeholder="Select an Sensor Type"
                items={sortedSensorTypes}
              />
            </Grid>
            <Grid item xs={2}>
              <ButtonBase
                onClick={openModal}
                sx={{ borderRadius: "50%", cursor: "pointer", mb: 1 }}
              >
                <Chip
                  label={<MenuSettingIcon />}
                  sx={{
                    borderRadius: "50%",
                    cursor: "pointer",
                    background: "transparent",
                  }}
                />
              </ButtonBase>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <PrimaryInput
              label="Alert Condition"
              name="alertConditions"
              Icon={AlarmIcon}
              value={formValues?.alertConditions}
              onChange={handleChange}
              placeholder="Enter Alert Condition"
            />
          </Grid>
          <Grid item xs={4}>
            <PrimaryInput
              label="Description *"
              name="description"
              Icon={PlayListIcon}
              value={formValues?.description}
              onChange={handleChange}
              placeholder="Enter Description"
              error={descError}
              required
            />
          </Grid>
        </Grid>
        <ButtonRow justifyContent="flex-end" mt={4} spacing={2}>
          {sensorForEdit && (
            <Button variant="outlined" onClick={resetForm}>
              Cancel
            </Button>
          )}

          <Button
            variant="contained"
            onClick={sensorForEdit ? editSensorHandle : addSensorHandle}
          >
            {sensorForEdit ? "Edit Sensor" : "Add Sensor"}
          </Button>
        </ButtonRow>
      </Container>
      <DashedDivider />
      <Container>
        <Grid container spacing={3}>
          {sensors?.map((item) => {
            return (
              <Grid item xs={6}>
                <SensorItem
                  sensorTypes={sensorTypes}
                  sensor={item}
                  deleteSensor={deleteSensorHandle}
                  setSensorForEdit={setSensorForEdit}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
      {sensorTypeModal && (
        <SensorTypeModal
          open={sensorTypeModal}
          onClose={closeModal}
          siteId={siteId}
        />
      )}
    </div>
  );
}

export default SensorForm;
