import React from "react";

function ArrowIcon({ color = "#c1c1c1", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.288"
      height={9}
      viewBox="0 0 5.288 9"
      {...props}
    >
      <path
        id="Path_4"
        data-name="Path 4"
        d="M106.622,4.149,102.617.144a.493.493,0,0,0-.7,0l-.295.295a.493.493,0,0,0,0,.7L104.989,4.5l-3.367,3.367a.493.493,0,0,0,0,.7l.295.295a.493.493,0,0,0,.7,0l4.009-4.009a.5.5,0,0,0,0-.7Z"
        transform="translate(-101.478 0)"
        fill={color}
      />
    </svg>
  );
}

export default ArrowIcon;
