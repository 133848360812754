import React from "react";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={13}
      viewBox="0 0 13 13"
    >
      <path
        id="icons8_edit_5"
        d="M16.515,9.633,17.35,8.8A2.224,2.224,0,0,0,14.2,5.653l-.834.834Zm-3.871-2.42L6.462,13.4a1.2,1.2,0,0,0-.29.47L5.026,17.329a.513.513,0,0,0,.649.648l3.462-1.146a1.2,1.2,0,0,0,.471-.29l6.182-6.182Z"
        transform="translate(-5 -5.003)"
        fill="#8f8f8f"
      />
    </svg>
  );
}

export default EditIcon;
