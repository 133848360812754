import React from "react";
import classes from "./Header.module.scss";
import { Grid, Menu, MenuItem } from "@mui/material";
import logo from "../../assets/images/logo.png";
import avatar from "../../assets/images/avatar.png";
import SearchIcon from "../../assets/icons/SearchIcon";
import ArrowIcon from "../../assets/icons/ArrowIcon";
import { signOut, useUserDispatch } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

function Header() {
  let navigate = useNavigate();
  let dispatch = useUserDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const signOutHandle = () => {
    signOut(dispatch, navigate);
  };
  return (
    <Grid container alignItems="center" className={classes.header}>
      {/* --------- Logo --------- */}
      <Grid item container alignItems="center" xs={2.5}>
        <img src={logo} alt="logo" />
      </Grid>
      <Grid item container justifyContent="space-between" xs={9.5}>
        {/* --------- Search --------- */}
        <Grid item xs container alignItems="center" pl="30px" spacing={1}>
          <Grid item>
            <SearchIcon />
          </Grid>
          <Grid item>
            <input
              type="text"
              placeholder="Search for sites, users..."
              className={classes.search_input}
            />
          </Grid>
        </Grid>

        {/* --------- Avatar --------- */}
        <Grid
          item
          xs
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
        >
          <Grid item>
            <img
              src={avatar}
              className={classes.avatar_container}
              alt="logo"
              onClick={handleMenu}
            />
          </Grid>
          <Grid item>
            <ArrowIcon
              style={{ transform: "rotate(90deg)", cursor: "pointer" }}
              onClick={handleMenu}
            />
          </Grid>
        </Grid>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
          {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
          <MenuItem onClick={signOutHandle}>Sign out</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}

export default Header;
