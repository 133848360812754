import React from "react";

function SignalInput({ color = "#9E9E9E", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height="13.758"
      viewBox="0 0 16 13.758"
      {...props}
    >
      <path
        id="icons8_rfid_signal"
        d="M14.981,5.978A.573.573,0,0,0,14.7,7.041a6.827,6.827,0,0,1,0,11.624.573.573,0,1,0,.6.972,8.006,8.006,0,0,0,0-13.568A.573.573,0,0,0,14.981,5.978Zm-7.868,0a.573.573,0,0,0-.309.092,8.009,8.009,0,0,0,.02,13.568.573.573,0,1,0,.6-.972A6.832,6.832,0,0,1,7.412,7.044a.573.573,0,0,0-.3-1.062Zm6.405,2.672A.573.573,0,0,0,13.2,9.7a3.79,3.79,0,0,1,0,6.3.573.573,0,1,0,.642.948,4.966,4.966,0,0,0,0-8.2.573.573,0,0,0-.323-.1Zm-4.941,0a.573.573,0,0,0-.306.1,4.966,4.966,0,0,0,0,8.2.573.573,0,1,0,.642-.948,3.79,3.79,0,0,1,0-6.3.573.573,0,0,0-.336-1.05Zm2.477,2.672a1.527,1.527,0,1,0,1.527,1.527A1.527,1.527,0,0,0,11.055,11.33Z"
        transform="translate(-3.063 -5.978)"
        fill={color}
      />
    </svg>
  );
}

export default SignalInput;
