import React from "react";

function WaterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.134"
      height="15.156"
      viewBox="0 0 15.134 15.156"
    >
      <path
        id="Union_600"
        data-name="Union 600"
        d="M2170.339,804.327a5.561,5.561,0,0,1,1.529-2.95.4.4,0,0,1,.6,0,5.568,5.568,0,0,1,1.529,2.95,1.828,1.828,0,1,1-3.656,0Zm-5.5.012L2160.5,800a1.729,1.729,0,0,1,0-2.435l4.154-4.154-.607-.608a.469.469,0,1,1,.663-.664l7.046,7.046a.469.469,0,0,1,0,.664l-4.487,4.486a1.729,1.729,0,0,1-2.435,0Zm1.034-5.565,2.609.062,1.874.275-5.034-5.034-4.155,4.154a.78.78,0,0,0-.23.527l2.4-.1Z"
        transform="translate(-2159.362 -791.5)"
        fill="#bbb"
        stroke="#bbb"
        strokeWidth={1}
      />
    </svg>
  );
}

export default WaterIcon;
