import { ButtonBase, Chip, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import ChartChipIcon from "../../../../../../assets/icons/ChartChipIcon";
import DangerIcon from "../../../../../../assets/icons/DangerIcon";
import EditIcon from "../../../../../../assets/icons/EditIcon";
import OKIcon from "../../../../../../assets/icons/OKIcon";
import NewSensorModal from "../../../../../../components/AddAndEditSite/components/sensors/EditSensorModal";
import Chart from "./Chart";

function Item({ sensor, setSensor, timeZone }) {
  const [openSensorEditModal, setOpenSensorEditModal] = useState(false);
  const [openChartModal, setOpenChartModal] = useState(false);

  function closeEditSensorModal() {
    setOpenSensorEditModal(false);
  }

  function closeChartModal() {
    setOpenChartModal(false);
  }

  return (
    <Grid
      Container
      direction="column"
      sx={{
        p: "15px",
        my: "4px",
      }}
      spacing={2}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={7}>
          <div>{sensor?.sensorCode}</div>
        </Grid>
        <Grid item xs={4} sx={{ color: "#484848", fontSize: "11px" }}>
          {Number(sensor?.sensorValue)?.toFixed(2)} {sensor?.sensorType?.unit}
        </Grid>
        <Grid item xs={1}>
          <div>
            {sensor?.status ? (
              sensor?.status?.toUpperCase() === "OK" ? (
                <OKIcon />
              ) : (
                <DangerIcon />
              )
            ) : null}
          </div>
        </Grid>
      </Grid>
      <br />
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6} sx={{ color: "#484848", fontSize: "11px" }}>
          {sensor?.sensorType?.parameter +
            " ( " +
            sensor?.sensorType?.unit +
            " ) "}{" "}
          <br /> {sensor?.description}
        </Grid>
        <Grid item xs={5}>
          <ButtonBase
            onClick={() => {
              setOpenChartModal(true);
            }}
          >
            <Chip
              sx={{ pl: "10px", cursor: "pointer" }}
              size="small"
              icon={<ChartChipIcon />}
              label="Chart"
            />
          </ButtonBase>
        </Grid>
        <Grid item xs={1}>
          <ButtonBase
            onClick={() => {
              setOpenSensorEditModal(true);
            }}
          >
            <EditIcon />
          </ButtonBase>
        </Grid>
      </Grid>
      <NewSensorModal
        open={openSensorEditModal}
        closeModal={closeEditSensorModal}
        sensor={sensor}
        setSensor={setSensor}
      />
      <Modal open={openChartModal} onClose={closeChartModal}>
        <Chart timeZone={timeZone} sensor={sensor} title={sensor?.sensorCode} />
      </Modal>
    </Grid>
  );
}

export default Item;
