import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ADD_SITE, SITE, UPDATE_SITE } from "../../../../api/endpoints";
import { useRequest } from "../../../../api/hooks";
import ButtonRow from "../../../form/button/ButtonRow";
import NextButton from "../../../form/button/NextButton";
import SensorForm from "./components/SensorForm";
import SiteForm from "./components/SiteForm";
import { LinearProgress } from "@mui/material";

function Information() {
  let { lat, lng, siteID } = useParams();
  let navigate = useNavigate();

  let [{ data: siteData, loading }, fetch] = useRequest(SITE + "/" + siteID, {
    method: "GET",
    autoFetch: false,
  });

  let [{ data: addData, loading: addLoading }, addHandle] = useRequest(
    ADD_SITE,
    {
      method: "POST",
    }
  );

  let [{ loading: editLoading }, editHandle] = useRequest(UPDATE_SITE, {
    method: "Put",
  });

  const [siteInfo, setSiteInfo] = useState({
    siteName: "",
    city: "",
    locationX: Number(lng),
    locationY: Number(lat),
    waterOrWaste: "water",
    wellOrSurface: "well",
    pumpEnergy: null,
    numberOfHomes: null,
    people: null,
    volumeOfTanks: null,
    timeZoneInfoId: "",
  });

  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    if (siteID) {
      fetch();
    }
  }, [siteID]);

  useEffect(() => {
    if (addData?.id) {
      navigate(`/add-site/${addData?.id}/modules`);
    }
  }, [addData]);

  useEffect(() => {
    if (siteData) {
      setSiteInfo({
        id: siteData?.id,
        siteName: siteData?.siteName,
        city: siteData?.city,
        locationX: Number(lng),
        locationY: Number(lat),
        waterOrWaste: siteData?.waterOrWaste,
        wellOrSurface: siteData?.wellOrSurface,
        pumpEnergy: siteData?.pumpEnergy,
        numberOfHomes: siteData?.numberOfHomes,
        people: siteData?.people,
        volumeOfTanks: siteData?.volumeOfTanks,
        timeZoneInfoId: siteData?.timeZoneInfoId,
      });
      setSensors(siteData?.sensors ? [...siteData?.sensors] : []);
    }
  }, [siteData]);

  function nextHandle() {
    if (siteID) {
      editHandle({
        ...siteData,
        ...siteInfo,
        sensors: [...sensors],
      }).then((res) => {
        if (res?.result?.id) {
          navigate(`/edit-sites/${res?.result?.id}/modules`);
        }
      });
    } else {
      addHandle({
        ...siteInfo,
        sensors: [...sensors],
      });
    }
  }

  return (
    <div>
      <div>
        2. Complete the following information and click on the Next button.
      </div>
      <br />
      <br />
      {loading && <LinearProgress />}
      <SiteForm values={siteInfo} setValues={setSiteInfo} />
      <SensorForm siteId={siteID} sensors={sensors} setSensors={setSensors} />
      <ButtonRow justifyContent="flex-end">
        <NextButton loading={addLoading || editLoading} onClick={nextHandle} />
      </ButtonRow>
    </div>
  );
}

export default Information;
