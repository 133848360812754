import React from "react";

function LocationInput2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.567"
      height={16}
      viewBox="0 0 15.567 16"
    >
      <path
        id="icons8_address_1"
        d="M13.784,5A5.2,5.2,0,0,0,8.6,10.189c0,2.078,2.569,5.7,4.1,7.673a1.377,1.377,0,0,0,2.177,0c1.532-1.974,4.1-5.6,4.1-7.673A5.2,5.2,0,0,0,13.784,5Zm0,3.892a1.3,1.3,0,1,1-1.3,1.3A1.3,1.3,0,0,1,13.784,8.892ZM8.476,15.811a1.515,1.515,0,0,0-1.383.9l-.96,2.162A1.513,1.513,0,0,0,7.515,21H20.053a1.514,1.514,0,0,0,1.383-2.128l-.96-2.162a1.515,1.515,0,0,0-1.383-.9H17.685q-.413.626-.9,1.3h2.3a.216.216,0,0,1,.2.128l.96,2.162a.216.216,0,0,1-.2.3H7.515a.216.216,0,0,1-.2-.3l.961-2.162a.217.217,0,0,1,.2-.128h2.3q-.485-.672-.9-1.3Z"
        transform="translate(-6.001 -5)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default LocationInput2;
