import React from "react";

function BottleIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.182"
      height={16}
      viewBox="0 0 10.182 16"
      {...props}
    >
      <path
        id="icons8_water_bottle"
        d="M14,2a1.091,1.091,0,0,0-1.091,1.091h-.545a.545.545,0,1,0,0,1.091h4.727a.545.545,0,0,1,0,1.091H12.364a.546.546,0,0,0-.533.69A4.387,4.387,0,0,0,10,9.455a14.975,14.975,0,0,0,2.243,7.78h0A1.638,1.638,0,0,0,13.629,18h2.924a1.638,1.638,0,0,0,1.386-.766,14.97,14.97,0,0,0,2.244-7.78,4.431,4.431,0,0,0-1.936-3.572,1.627,1.627,0,0,0-.973-2.773V3.091A1.091,1.091,0,0,0,16.182,2Zm-.777,4.364h3.736a3.6,3.6,0,0,1,2.132,3.091,13.961,13.961,0,0,1-2.077,7.2.539.539,0,0,1-.462.254H13.629a.539.539,0,0,1-.462-.255,13.963,13.963,0,0,1-2.076-7.2A3.6,3.6,0,0,1,13.223,6.364Z"
        transform="translate(-10 -2)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default BottleIcon;
