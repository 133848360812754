export const BASE_URL = `https://apiv2.aquaintelligent.com/`;
// export const BASE_URL = `http://aqua.pasasystem.ir/`;
export const AUTHENTICATION = BASE_URL + "Authentication";

//Site
export const SITE = "site";
export const UPDATE_SITE = "/Site/UpdateSite";
export const ADD_SITE = "site/AddSite";
export const SITE_UPLOAD_PICTURE = "Site/UploadPicture";
export const SITE_BY_CITY = "Site/groupbycity";
export const SITE_REMOVE_PICTURE = "/Site/removePic";
export const CITY_NAMES = "site/cityNames";

//Sensors
export const SENSORS = "/Sensors";
export const ADD_SENSORS_LIST = "/Sensors/addSensorsList";
export const GET_SENSORS_BY_ID = "/Sensors/?siteId=";
export const GET_SENSORS_TYPE = "/Sensors/sensorTypes";
export const ADD_SENSORS_TYPE = "/Sensors/AddSensorType";
export const UPDATE_SENSORS_TYPE = "/Sensors/UpdateSensorType";
export const DELETE_SENSORS_TYPE = "/Sensors/RemoveSensorType";

//Data
export const FILTER_DATA = "/data/filterData";

//Users
export const USERS = "users";

//Reports
export const REPORTS = "Reports";
export const GET_ALL_REPORTS = "Reports/getAll";

//Filtration
export const GET_FILTRATION = "Filteration/GetFilteration";

//Chlorine
export const GET_CHLORINE_INFO = "Chlorine/GetChlorineManagementInformation";
export const GET_CHLORINE_STORAGE_TANK =
  "Chlorine/GetStorageTankChlorineResidualData";
export const UPDATE_CHLORINE_STORAGE_TANK =
  "Chlorine/UpdateStorageTankChlorineResidual";
export const DELETE_CHLORINE_STORAGE_TANK =
  "Chlorine/DeleteStorageTankChlorineResidual";

export const GET_CHLORINE_DISTRIBUTION =
  "/Chlorine/GetDistributionSystemChlorineResidualData";
export const UPDATE_CHLORINE_DISTRIBUTION =
  "/Chlorine/UpdateDistributionSystemChlorineResidual";
export const DELETE_CHLORINE_DISTRIBUTION =
  "/Chlorine/DeleteDistributionSystemChlorineResidual";

export const GET_LOCATIONS = "Chlorine/GetLocations/";
export const ADD_LOCATION = "Chlorine/AddLocation";
export const DELETE_LOCATION = "Chlorine/RemoveLocation";

//Coagulation
export const GET_COAGULATION =
  "/Coagulation/GetCoagulationManagementInformation/";

//Ordering
export const GET_CHEMICAL_ORDERING =
  "Ordering/GetChemicalOrderingManagementInformation";

//Turbidity
export const ADD_TURBIDITY = "Turbidites/AddTurbidity";
export const GET_TURBIDITY = "Turbidites/GetTurbidity";
export const UPDATE_TURBIDITY = "Turbidites/UpdateTurbidity";
export const DELETE_TURBIDITY = "Turbidites/DeleteTurbidity";

//Maintenance
export const GET_MAINTENANCE_ALERTS = "alerts/persistent";
export const ADD_MAINTENANCE_ACTION = "MaintenanceReports/AddActionTypeReport";
export const ADD_MAINTENANCE_REFILL = "MaintenanceReports/AddRefillTypeReport";
export const ADD_MAINTENANCE_ORDER = "MaintenanceReports/AddOrderTypeReport";
