import React from "react";

function ModuleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.818"
      height={16}
      viewBox="0 0 13.818 16"
    >
      <path
        id="icons8_module"
        d="M11.893,2a.546.546,0,0,0-.232.06L5.3,5.3a.546.546,0,0,0-.3.486v7.56a.545.545,0,0,0,.249.459l6.364,4.108a.545.545,0,0,0,.592,0L18.569,13.8a.546.546,0,0,0,.249-.459V5.786a.545.545,0,0,0-.3-.486L12.157,2.059A.546.546,0,0,0,11.893,2Zm.016,1.158,5.242,2.67L15.319,6.935,12.156,5.324a.547.547,0,0,0-.5,0L8.5,6.935,6.667,5.827Zm0,3.265L14.221,7.6,11.909,9,9.6,7.6Zm-5.818.332,1.818,1.1v3.908a.546.546,0,0,0,.249.458l3.205,2.069v2.164l-5.273-3.4Zm11.636,0v6.295l-5.273,3.4V14.288L15.66,12.22a.545.545,0,0,0,.249-.458V7.854ZM9,8.514l2.364,1.43V12.99L9,11.464Zm5.818,0v2.95L12.454,12.99V9.943Z"
        transform="translate(-5 -1.999)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default ModuleIcon;
