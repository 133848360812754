/* eslint-disable array-callback-return */
import { ClickAwayListener, Grid, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PrismaZoom from "react-prismazoom";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SENSORS } from "../../../../api/endpoints";
import { useRequest } from "../../../../api/hooks";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import EditSensorIcon from "../../../../assets/icons/EditSensorIcon";
import FullScreen from "../../../../assets/icons/FullScreen";
import SensorModal from "../../../../components/AddAndEditSite/components/sensors/EditSensorModal";
import Button from "../../../../components/form/button/Button";
import Sensor from "../../../../components/sensor/Sensor";
import classes from "./SiteInformation.module.scss";
import SensorsList from "./components/sensorList/SensorsList";

function SiteInformation({ site, siteName }) {
  let sensorTypes = useSelector((state) => state?.sensorTypes?.list);
  let { siteId } = useParams();
  let navigate = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [sensorList, setSensorList] = useState([]);
  const [sensorForAction, setSensorForAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  let [, updateSensorReq] = useRequest(SENSORS, {
    method: "PUT",
    autoFetch: false,
  });

  let image_container = useRef();

  useEffect(() => {
    if (image_container.current) {
      if (isFullScreen === true) {
        image_container.current?.requestFullscreen();
      } else if (isFullScreen === false) {
        document?.exitFullscreen();
      }
    }
  }, [isFullScreen]);

  useEffect(() => {
    if (site?.sensors && sensorTypes) {
      let list = site?.sensors?.map((item) => {
        let typeId = item?.sensorTypeId;
        let type = sensorTypes?.find((i) => i?.id === typeId);
        return {
          ...item,
          sensorType: { ...type },
        };
      });
      setSensorList([...list]);
    }
  }, [site?.sensors, sensorTypes]);

  function fullscreenHandle() {
    setIsFullScreen(!isFullScreen);
  }

  function updateSensorHandle(sensor) {
    let list = [...sensorList];
    let find = list?.find((item) => item?.id === sensor?.id);
    let index = list?.indexOf(find);
    list[index] = sensor;
    setSensorList([...list]);
  }

  function noPhotoHandle() {
    navigate(`/edit-sites/${siteId}/image`);
  }

  const sensorContextMenuHandle = (sensor) => {
    setSensorForAction(sensor);
  };

  const closeMenu = () => {
    setSensorForAction(null);
  };
  const onEdit = () => {
    openModalHandle();
  };

  function closeModalHandle() {
    setOpenModal(false);
    setSensorForAction(null);
  }

  function openModalHandle() {
    setOpenModal(true);
  }

  function deleteHandle(s) {
    let newList = sensorList?.filter((item) => item?.id !== s?.id);
    setSensorList([...newList]);
  }

  const onDelete = () => {
    updateSensorReq(null, null, {
      method: "DELETE",
      url: SENSORS + "/" + sensorForAction?.id,
    }).then(function () {
      deleteHandle(sensorForAction);
      setSensorForAction(null);
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={8} ref={image_container}>
        <div className={classes.image_container}>
          {site?.picture ? (
            <>
              <FullScreen
                className={classes.full_screen}
                onClick={fullscreenHandle}
              />
              <PrismaZoom style={{ width: "100%", height: "100%" }}>
                <img
                  width="100%"
                  height="100%"
                  src={site?.picture}
                  alt="site "
                />
                {sensorList?.map((item) => {
                  if (item?.fromLeft !== null && item?.fromTop !== null) {
                    return (
                      <div
                        aria-describedby={"sensor" + item?.id}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          sensorContextMenuHandle(item);
                        }}
                        style={{
                          position: "absolute",
                          left: item?.fromLeft + "%",
                          top: item?.fromTop + "%",
                          cursor: "pointer",
                          transform: "scale(0) !important",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <Sensor
                            key={"abc" + item?.id}
                            value={item?.sensorValue}
                            name={item?.sensorCode}
                            unit={item?.sensorType?.unit}
                            status={item?.status}
                            top={item?.fromTop}
                            left={item?.fromLeft}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </PrismaZoom>

              {sensorForAction && (
                <ClickAwayListener onClickAway={closeMenu}>
                  <div
                    className={classes.sensor_clicked_menu}
                    style={{
                      zIndex: 1,
                      left: sensorForAction?.fromLeft + "%",
                      top: sensorForAction?.fromTop + "%",
                    }}
                  >
                    <div
                      className={classes.sensor_clicked_menu_item}
                      onClick={onEdit}
                    >
                      <div>
                        <EditSensorIcon />
                      </div>
                      &nbsp; &nbsp;
                      <div>Edit Information</div>
                    </div>
                    <div
                      className={classes.sensor_clicked_menu_item}
                      onClick={onDelete}
                    >
                      <div>
                        <DeleteIcon color="red" />
                      </div>
                      &nbsp; &nbsp;
                      <div style={{ color: "red" }}>Delete Sensor</div>
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </>
          ) : (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: "100%", minHeight: "400px" }}
            >
              No photo has been uploaded for this site
              <Button onClick={noPhotoHandle} sx={{ marginTop: 4 }}>
                Upload Picture
              </Button>
            </Stack>
          )}
        </div>
      </Grid>
      <Grid item md={4}>
        <SensorsList
          setSensor={updateSensorHandle}
          sensors={sensorList}
          siteName={siteName}
          timeZone={site?.timeZoneInfoId}
        />
      </Grid>
      <SensorModal
        setSensor={updateSensorHandle}
        sensor={sensorForAction}
        open={openModal}
        closeModal={closeModalHandle}
      />
    </Grid>
  );
}

export default SiteInformation;
