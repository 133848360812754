import React from "react";
import classes from "./City.module.scss";
import classnames from "classnames";

function Item({ city, active, ...props }) {
  return (
    <div
      className={classnames(classes.item_container, {
        [classes.active]: active === city,
      })}
      {...props}
    >
      {city}
    </div>
  );
}

export default Item;
