import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { BottomArrowIcon } from "../../assets/icons";
import classes from "./AccordionComponent.module.scss";
import { Stack } from "@mui/material";

export default function AccordionComponent({ icon, name, children, ...props }) {
  return (
    <Accordion
      sx={{
        background: "#FCFCFC",
        mb: 2,
        border: "1px solid #D6D6D6",
        borderRadius: "6px",
      }}
      {...props}
    >
      <AccordionSummary
        expandIcon={
          <div className={classes.arrow_style}>
            <BottomArrowIcon />
          </div>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction="row" alignItems="center">
          <span>
            <img src={icon} alt="icon" />
          </span>
          <span style={{ padding: "0 12px 0px 17px" }}>{name}</span>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
