import React from "react";

function MenuLocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.513"
      height={18}
      viewBox="0 0 17.513 18"
    >
      <path
        id="icons8_address_1"
        d="M14.757,5a5.844,5.844,0,0,0-5.838,5.838c0,2.338,2.89,6.412,4.613,8.632a1.549,1.549,0,0,0,2.45,0c1.723-2.22,4.613-6.295,4.613-8.632A5.844,5.844,0,0,0,14.757,5Zm0,4.378A1.459,1.459,0,1,1,13.3,10.838,1.459,1.459,0,0,1,14.757,9.378ZM8.785,17.162a1.7,1.7,0,0,0-1.556,1.011l-1.08,2.432A1.7,1.7,0,0,0,7.7,23H21.809a1.7,1.7,0,0,0,1.556-2.394l-1.08-2.432a1.7,1.7,0,0,0-1.556-1.011H19.145q-.465.7-1.009,1.459h2.593a.243.243,0,0,1,.222.144l1.08,2.432a.243.243,0,0,1-.221.342H7.7a.243.243,0,0,1-.222-.342l1.081-2.432a.244.244,0,0,1,.222-.144h2.592q-.545-.756-1.008-1.459Z"
        transform="translate(-6.001 -5)"
        fill="#21446c"
      />
    </svg>
  );
}

export default MenuLocationIcon;
