import React from "react";

export default function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={35}
      height={35}
      viewBox="0 0 35 35"
    >
      <g
        id="Group_10710"
        data-name="Group 10710"
        transform="translate(-536 -168)"
      >
        <rect
          id="Rectangle_4073"
          data-name="Rectangle 4073"
          width={35}
          height={35}
          rx={6}
          transform="translate(536 168)"
          fill="#21446c"
        />
        <g
          id="Group_10647"
          data-name="Group 10647"
          transform="translate(1336.5 -3353)"
        >
          <path
            id="Subtraction_836"
            data-name="Subtraction 836"
            d="M47.723,11.715H43.334a21.88,21.88,0,0,1,.535-3.558A16.384,16.384,0,0,1,45.48,3.912,9.115,9.115,0,0,1,47.87,1.05a4.608,4.608,0,0,1,5.855,0,9.1,9.1,0,0,1,2.39,2.862,16.39,16.39,0,0,1,1.612,4.245,21.88,21.88,0,0,1,.536,3.558H53.873a24.711,24.711,0,0,0-.829-4.943,8.255,8.255,0,0,0-1.009-2.327,1.323,1.323,0,0,0-2.473,0,8.233,8.233,0,0,0-1.009,2.327,24.719,24.719,0,0,0-.829,4.942Z"
            transform="translate(-833.298 3529)"
            fill="#fff"
          />
          <g
            id="Group_10645"
            data-name="Group 10645"
            transform="translate(-791.5 3542.001)"
          >
            <path
              id="Subtraction_840"
              data-name="Subtraction 840"
              d="M17.257,45.342H13.109A5.964,5.964,0,0,0,16.426,40.5h.831a.725.725,0,0,1,.743.7v3.436a.725.725,0,0,1-.743.7Zm-16.514,0a.725.725,0,0,1-.743-.7V41.2a.725.725,0,0,1,.743-.7h.831A5.965,5.965,0,0,0,4.89,45.342H.743Z"
              transform="translate(0 -40.5)"
              fill="#fff"
            />
            <path
              id="Intersection_164"
              data-name="Intersection 164"
              d="M19.478,43.765A4.437,4.437,0,0,1,17.547,40.5H30.066a4.439,4.439,0,0,1-1.931,3.265,7.645,7.645,0,0,1-8.657,0Z"
              transform="translate(-14.806 -40.5)"
              fill="#07fbbe"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
