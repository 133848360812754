import React from "react";
import classes from "./Styles.module.scss";
import { Grid } from "@mui/material";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import LocationInput from "../../../../assets/icons/LocationInput";

function Item({ name, deleteLocation }) {
  function deleteHandle() {
    deleteLocation(name);
  }

  return (
    <Grid container alignItems="center" p="14px" className={classes.item}>
      <Grid item xs={1}>
        <LocationInput />
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        {name}
      </Grid>
      <Grid item xs={1} onClick={deleteHandle} sx={{ cursor: "pointer" }}>
        <CloseIcon />
      </Grid>
    </Grid>
  );
}

export default Item;
