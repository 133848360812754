import React from "react";
import classes from "./Styles.module.scss";
import { Grid } from "@mui/material";
import CloseIcon from "../../../../assets/icons/CloseIcon";

function Item({ icon, name, deleteModule }) {
  function deleteHandle() {
    deleteModule(name);
  }

  return (
    <Grid container alignItems="center" p="14px" className={classes.item}>
      <Grid item xs={2}>
        <img src={icon} alt="module icon" />
      </Grid>
      <Grid item xs={9}>
        {name}
      </Grid>
      <Grid item xs={1} onClick={deleteHandle} sx={{ cursor: "pointer" }}>
        <CloseIcon />
      </Grid>
    </Grid>
  );
}

export default Item;
