import React from "react";
import SiteLocations from "../../../components/AddAndEditSite/components/Locations/SiteLocations";

function EditLocations() {
  return (
    <div>
      <div style={{ fontSize: 20, fontWeight: "bold", marginBottom: "10px" }}>
        Edit Locations
      </div>
      Complete the following information.
      <SiteLocations type="page" />
    </div>
  );
}

export default EditLocations;
