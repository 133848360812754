import React from "react";

function DollarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={17}
      viewBox="0 0 17 17"
    >
      <path
        id="icons8_Us_Dollar_Circled"
        d="M12.5,4A8.5,8.5,0,1,0,21,12.5,8.51,8.51,0,0,0,12.5,4Zm0,1.275A7.225,7.225,0,1,1,5.275,12.5,7.215,7.215,0,0,1,12.5,5.275Zm-.222,1.691a.638.638,0,0,0-.628.647V8.1a2.426,2.426,0,0,0-2.125,2.379,2.454,2.454,0,0,0,2.444,2.444h.85a1.381,1.381,0,0,1,0,2.763h-.956a1.059,1.059,0,0,1-1.028-.794.638.638,0,1,0-1.234.321,2.328,2.328,0,0,0,2.049,1.712v.461a.638.638,0,1,0,1.275,0v-.447a2.646,2.646,0,0,0-.106-5.291h-.85a1.169,1.169,0,0,1,0-2.338h.214a.638.638,0,0,0,.207,0h.323a1.059,1.059,0,0,1,1.026.784.638.638,0,1,0,1.231-.333,2.328,2.328,0,0,0-2.044-1.691V7.613a.638.638,0,0,0-.647-.647Z"
        transform="translate(-4 -4)"
        fill="#8f8f8f"
      />
    </svg>
  );
}

export default DollarIcon;
