import React from "react";

function AlertSensorIcon({ width = "16.773", height = "16.774", ...props }) {
  return (
    <svg
      id="Group_10296"
      data-name="Group 10296"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16.548 16.548"
      {...props}
    >
      <circle
        id="Ellipse_4697"
        data-name="Ellipse 4697"
        cx="8.274"
        cy="8.274"
        r="8.274"
        fill="#fff"
      />
      <path
        id="Union_479"
        data-name="Union 479"
        d="M.428,10.144A1.218,1.218,0,0,1,0,9.207a1.18,1.18,0,0,1,.432-.942A1.606,1.606,0,0,1,1.512,7.9a1.566,1.566,0,0,1,1.077.371,1.2,1.2,0,0,1,.42.938,1.226,1.226,0,0,1-.415.949,1.557,1.557,0,0,1-1.082.375A1.552,1.552,0,0,1,.428,10.144ZM.836,7.021A.393.393,0,0,1,.42,6.675L.147.373A.388.388,0,0,1,.563,0H2.429a.388.388,0,0,1,.415.373l-.289,6.3a.393.393,0,0,1-.415.345Z"
        transform="translate(6.77 3.009)"
        fill="#ff2a2a"
        stroke="#fff"
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default AlertSensorIcon;
