import React from "react";

function UserTagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
    >
      <path
        id="icons8_user_tag"
        d="M8.611,3V3a3.7,3.7,0,0,0-.218,7.39.558.558,0,0,0,.218.048h.02v0A3.717,3.717,0,0,0,8.632,3V3Zm.01,1.118a2.6,2.6,0,1,1-2.6,2.6A2.6,2.6,0,0,1,8.621,4.118Zm7.209,6.7a.977.977,0,0,0-.7.288L11.1,15.136a.984.984,0,0,0,0,1.391l2.185,2.185a.984.984,0,0,0,1.391,0l4.034-4.034a.978.978,0,0,0,.288-.7v-2.4a.766.766,0,0,0-.765-.765ZM4.385,11.93A1.437,1.437,0,0,0,3,13.411v.566a3.456,3.456,0,0,0,1.777,2.987,6.994,6.994,0,0,0,3.618.92,7.66,7.66,0,0,0,2.581-.431l-.4-.4a1.715,1.715,0,0,1-.357-.525,6.937,6.937,0,0,1-1.823.24c-2.061,0-4.279-.873-4.279-2.791v-.566c0-.2.123-.364.269-.364h7.754l.928-.928a1.3,1.3,0,0,0-.661-.188Zm12.568.372a.558.558,0,1,1-.558.558A.558.558,0,0,1,16.953,12.3Z"
        transform="translate(-3 -3)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default UserTagIcon;
