import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  LinearProgress,
  Slide,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  DELETE_CHLORINE_DISTRIBUTION,
  GET_CHLORINE_DISTRIBUTION,
  UPDATE_CHLORINE_DISTRIBUTION,
} from "../../../../../api/endpoints";
import { useRequest } from "../../../../../api/hooks";
import CloseIcon from "../../../../../assets/icons/CloseIcon";
import DeleteIcon from "../../../../../assets/icons/DeleteIcon";
import EditIcon from "../../../../../assets/icons/EditIcon";
import DistributionIcon from "../../../../../assets/images/Distribution system chlorine residual.png";
import AccordionComponent from "../../../../../components/accordion/AccordionComponent";
import Button from "../../../../../components/form/button/Button";
import TableHeader from "../../../../../components/table/TableHeader";
import {
  LOCAL_NOW,
  SEVEN_DAYS_AGO,
  getUtcDateTimeString,
  showDate,
} from "../../../../../utils/date";
import Warning from "../commonComponents/Warning";

function Distribution({ siteId, siteName, timeZone }) {
  const [expanded, setExpanded] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [isEditingMode, setIsEditingMode] = useState(false);

  let [startDate, setStartDate] = useState(SEVEN_DAYS_AGO);
  let [endDate, setEndDate] = useState(LOCAL_NOW);

  let ref = useRef(null);

  let [{ data, loading, message, error }, fetch] = useRequest(
    GET_CHLORINE_DISTRIBUTION,
    {
      method: "POST",
      data: {
        siteId: Number(siteId),
        from: getUtcDateTimeString(startDate),
        to: getUtcDateTimeString(endDate),
      },
    }
  );

  let [, updateRequest] = useRequest(UPDATE_CHLORINE_DISTRIBUTION, {
    method: "PUT",
  });

  let [, deleteRequest] = useRequest(DELETE_CHLORINE_DISTRIBUTION, {
    method: "DELETE",
  });

  useEffect(() => {
    if (expanded) {
      fetch({
        siteId: Number(siteId),
        from: getUtcDateTimeString(startDate),
        to: getUtcDateTimeString(endDate),
      });
    }
  }, [startDate, endDate, expanded]);

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    let list = [...tableData];
    list[row?.index] = {
      ...row?.original,
      ...values,
    };
    console.log(row);
    updateRequest({
      ...row?.original,
      ...values,
    }).then(() => {
      setTableData([...list]);
      //required to exit editing mode and close modal
      exitEditingMode();
    });
  };

  const columns = useMemo(() => [
    {
      accessorKey: "reportDateTime",
      header: "Report Date Time",
      size: 140,
      Cell: ({ cell }) => {
        return cell.getValue() ? showDate(cell?.getValue(), timeZone) : " - ";
      },
      muiTableBodyCellEditTextFieldProps: () => ({
        variant: "outlined",
        type: "datetime-local",
        size: "small",
      }),
    },
    {
      accessorKey: "locationName",
      header: "Location",
      enableEditing: false,
      size: 140,
    },
    {
      accessorKey: "operatorName",
      header: "Operator",
      enableEditing: false,
      size: 140,
    },
    {
      accessorKey: "freeChlorine",
      header: "Free Chlorine",
      size: 140,
      muiTableBodyCellEditTextFieldProps: () => ({
        variant: "outlined",
        type: "number",
        size: "small",
      }),
    },
    {
      accessorKey: "totalChlorine",
      header: "Total Chlorine",
      size: 140,
      muiTableBodyCellEditTextFieldProps: () => ({
        variant: "outlined",
        type: "number",
        size: "small",
      }),
    },
  ]);

  function expandHandle() {
    setExpanded(!expanded);
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function RowAction({ row, table }) {
    const {
      getState,
      setEditingRow,
      options: {
        icons: { SaveIcon },
        onEditingRowSave,
        onEditingRowCancel,
      },
    } = table;
    const { editingRow } = getState();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

    const handleClickOpenDeleteDialog = () => {
      setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
      setOpenDeleteDialog(false);
    };

    const handleCancel = () => {
      onEditingRowCancel?.({ row, table });
      setEditingRow(null);
      setIsEditingMode(false);
    };

    const handleSave = () => {
      onEditingRowSave?.({
        exitEditingMode: () => {
          handleCancel();
        },
        row: editingRow ?? row,
        table,
        values: editingRow?._valuesCache ?? { ...row.original },
      });
    };

    if (
      isEditingMode &&
      getState()?.editingRow?.original?.id == row?.original?.id
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "nowrap",
            gap: "8px",
          }}
        >
          <IconButton color="secondary" onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
          <IconButton color="error" onClick={handleSave}>
            <SaveIcon />
          </IconButton>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "nowrap",
          gap: "8px",
        }}
      >
        <IconButton
          color="secondary"
          onClick={() => {
            setEditingRow(row);
            setIsEditingMode(true);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton color="error" onClick={handleClickOpenDeleteDialog}>
          <DeleteIcon />
        </IconButton>
        {openDeleteDialog && (
          <Dialog
            open={openDeleteDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClickOpenDeleteDialog}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>are you sure you want to delete?</DialogTitle>

            <DialogActions>
              <Button variant="outlined" onClick={handleCloseDeleteDialog}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleCloseDeleteDialog();
                  deleteRequest({ id: row?.original?.id }).then(() => {
                    let list = tableData?.filter(
                      (i) => i?.id != row?.original?.id
                    );
                    setTableData([...list]);
                  });
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    );
  }

  return (
    <AccordionComponent
      onChange={expandHandle}
      expanded={expanded}
      name="Distribution System Chlorine Residual"
      icon={DistributionIcon}
    >
      <TableHeader
        siteName={siteName}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        title={false}
        data={tableData}
        headers={columns?.map((c) => c.header)}
        refForPrint={ref}
      />

      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {data ? (
            <>
              <div ref={ref}>
                <MaterialReactTable
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      muiTableHeadCellProps: {
                        align: "center",
                      },
                      size: 80,
                    },
                  }}
                  columns={columns}
                  data={tableData}
                  editingMode="row"
                  enableColumnOrdering={true}
                  enableEditing={true}
                  onEditingRowSave={handleSaveRowEdits}
                  enableRowActions
                  renderRowActions={RowAction}
                />
              </div>
            </>
          ) : (
            <Warning message={message} error={error} />
          )}
        </>
      )}
    </AccordionComponent>
  );
}

export default Distribution;
