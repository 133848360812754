import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SITE_BY_CITY } from "../../api/endpoints";
import { useRequest } from "../../api/hooks";
import CitySelect from "../../components/siteSelect/CitySelect";
import SiteSelect from "../../components/siteSelect/SiteSelect";
import Map from "./components/map/Map";

function Sites() {
  let { city } = useParams();

  let [{ data: sites, loading: siteLoading }, fetchSites] = useRequest(
    SITE_BY_CITY,
    {
      method: "POST",
      data: {
        city,
      },
      autoFetch: false,
    }
  );

  useEffect(() => {
    if (city) {
      fetchSites();
    }
  }, [city]);

  return (
    <div>
      <CitySelect />
      <SiteSelect loading={siteLoading} sites={sites} />
      <Map sites={sites?.[0]?.sites} />
    </div>
  );
}

export default Sites;
