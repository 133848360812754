import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.945"
      height="14.943"
      viewBox="0 0 14.945 14.943"
    >
      <path
        id="Union_569"
        data-name="Union 569"
        d="M-49.52-9224.316l-3.775-3.774a6.077,6.077,0,0,1-3.64,1.22A6.072,6.072,0,0,1-63-9232.935,6.074,6.074,0,0,1-56.935-9239a6.072,6.072,0,0,1,6.063,6.065,6.063,6.063,0,0,1-1.22,3.638l3.775,3.777a.851.851,0,0,1,.234.828.858.858,0,0,1-.61.609.832.832,0,0,1-.216.025A.857.857,0,0,1-49.52-9224.316Zm-11.777-8.618a4.365,4.365,0,0,0,4.361,4.359,4.331,4.331,0,0,0,3.007-1.2,1.04,1.04,0,0,1,.148-.148,4.339,4.339,0,0,0,1.206-3.007,4.365,4.365,0,0,0-4.361-4.361A4.365,4.365,0,0,0-61.3-9232.935Z"
        transform="translate(63.001 9239)"
        fill="#676767"
      />
    </svg>
  );
}

export default SearchIcon;
