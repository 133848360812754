import { Grid } from "@mui/material";
import React from "react";

function ChartInfoRow({ title, value, unit }) {
  return (
    <Grid container direction="row" my={2}>
      <Grid item xs={2.5} sx={{ color: "#484848" }}>
        {title} :
      </Grid>
      <Grid item xs={4} sx={{ fontWeight: "bold" }}>
        {value !== null ? Number(value)?.toFixed(2) : "-"}&nbsp;&nbsp;
        {unit ? <>({unit})</> : null}
      </Grid>
    </Grid>
  );
}

export default ChartInfoRow;
