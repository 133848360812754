import { LinearProgress } from "@mui/material";
import MaterialReactTable from "material-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { GET_MAINTENANCE_ALERTS } from "../../../../../api/endpoints";
import { useRequest } from "../../../../../api/hooks";
import ActionIcon from "../../../../../assets/icons/ActionIcon";
import OrderIcon from "../../../../../assets/icons/OrderIcon";
import RefillIcon from "../../../../../assets/icons/RefillIcon";
import MaintenanceReportIcon from "../../../../../assets/images/Maintenance Report.png";
import AccordionComponent from "../../../../../components/accordion/AccordionComponent";
import Button from "../../../../../components/form/button/Button";
import TableHeader from "../../../../../components/table/TableHeader";
import {
  LOCAL_NOW,
  SEVEN_DAYS_AGO,
  getUtcDateTimeString,
  showDate,
} from "../../../../../utils/date";
import Warning from "../commonComponents/Warning";
import Action from "./Action";
import Order from "./Order";
import Refill from "./Refill";

function MaintenanceReport({ siteId, siteName, timeZone }) {
  const [expanded, setExpanded] = useState(false);
  let [startDate, setStartDate] = useState(SEVEN_DAYS_AGO);
  let [endDate, setEndDate] = useState(LOCAL_NOW);
  const [tableData, setTableData] = useState([]);
  const [row, setRow] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  let ref = useRef(null);

  let [{ data, loading, message, error }, fetch] = useRequest(
    GET_MAINTENANCE_ALERTS,
    {
      method: "POST",
      data: {
        siteID: siteId,
        alertFrom: getUtcDateTimeString(startDate),
        alertTo: getUtcDateTimeString(endDate),
      },
      autoFetch: false,
    }
  );

  useEffect(() => {
    if (expanded) {
      fetch();
    }
  }, [startDate, endDate, expanded]);

  useEffect(() => {
    if (data) {
      let list = [];
      if (data?.action) {
        list.push(...data?.action);
      }
      if (data?.order) {
        list.push(...data?.order);
      }
      if (data?.refill) {
        list.push(...data?.refill);
      }
      setTableData([...list]);
    }
  }, [data]);

  const columns = useMemo(() => [
    {
      accessorKey: "sensorCode",
      header: "Sensor",
      enableColumnOrdering: false,
      size: 50,
    },
    {
      accessorKey: "problem",
      header: "Problem",
      size: 160,
    },
    {
      accessorKey: "startDateTime",
      header: "Start Date",
      size: 100,
      type: "date",
      Cell: ({ cell }) => {
        if (cell?.getValue()) {
          return showDate(cell?.getValue());
        } else {
          return "-";
        }
      },
    },
    {
      accessorKey: "endDateTime",
      header: "End Date",
      type: "date",
      size: 100,
      Cell: ({ cell }) => {
        if (cell?.getValue()) {
          return showDate(cell?.getValue());
        } else {
          return "-";
        }
      },
    },
    {
      accessorKey: "alertType",
      header: "Action",
      size: 60,
      Cell: ({ row, cell }) => {
        return (
          <Button
            onClick={() => {
              setRow(row?.original);
              openModalHandle();
            }}
            sx={{
              width: "120px !important",
              height: "20px !important",
              borderRadius: "30px 30px !important",
              padding: "15px 40px !important",
            }}
            size="small"
            startIcon={
              cell?.getValue() === "action" ? (
                <ActionIcon color="#fff" />
              ) : cell?.getValue() === "order" ? (
                <OrderIcon />
              ) : (
                <RefillIcon />
              )
            }
          >
            {/* {cell?.getValue() === "action" ? (
              <ActionIcon color="#fff" />
            ) : cell?.getValue() === "order" ? (
              <OrderIcon />
            ) : (
              <RefillIcon />
            )} */}
            &nbsp;{cell?.getValue()}
          </Button>
        );
      },
    },
  ]);

  function openModalHandle() {
    setOpenModal(true);
  }

  function closeModalHandle() {
    setOpenModal(false);
    setRow(null);
  }

  function expandHandle() {
    setExpanded(!expanded);
  }

  return (
    <AccordionComponent
      onChange={expandHandle}
      expanded={expanded}
      name="Maintenance Report"
      icon={MaintenanceReportIcon}
    >
      <TableHeader
        siteName={siteName}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        data={tableData}
        headers={columns?.map((c) => c.header)}
        refForPrint={ref}
      />
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {data ? (
            <>
              <div ref={ref}>
                {tableData && (
                  <MaterialReactTable
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        muiTableHeadCellProps: {
                          align: "center",
                        },
                        size: 120,
                      },
                    }}
                    columns={columns}
                    data={tableData}
                  />
                )}
              </div>
            </>
          ) : (
            <Warning message={message} error={error} />
          )}
        </>
      )}

      {row && (
        <>
          {row?.alertType === "action" ? (
            <Action open={openModal} closeModal={closeModalHandle} row={row} />
          ) : row?.alertType === "order" ? (
            <Order open={openModal} closeModal={closeModalHandle} row={row} />
          ) : (
            <Refill open={openModal} closeModal={closeModalHandle} row={row} />
          )}
        </>
      )}
    </AccordionComponent>
  );
}

export default MaintenanceReport;
