import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import classes from "../inputs/inputsStyle.module.scss";
import Label from "../label/Label";

export default function SelectBox({
  optionPropertyName = "name",
  optionValuePropertyName = "value",
  label,
  required,
  selected,
  setSelected,
  setSelectedObject,
  Icon,
  items,
  loading,
  children,
  ...props
}) {
  const [selectValue, setSelectValue] = useState(selected);
  let type = typeof items?.[0] === "string" ? "string" : "object";

  useEffect(() => {
    if (selected) {
      if (type === "string") {
        setSelectValue(selected);
        console.log(selected);
      } else {
        let find = items?.find(
          (item) => item?.[optionValuePropertyName] === selected
        );
        if (find) {
          setSelectValue(find?.[optionValuePropertyName]);
        } else {
          setSelectValue(selected?.[optionValuePropertyName]);
        }
        console.log(selected);
        console.log(find);
      }
    } else {
      setSelectValue(null);
    }
  }, [selected]);

  const handleChange = (event) => {
    let { value } = event?.target;
    setSelectValue(value);
    setSelected?.(value);
    if (setSelectedObject) {
      let find = items?.find(
        (item) => item?.[optionValuePropertyName] === value
      );
      setSelectedObject(find);
    }
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        {label && <Label required={required}>{label}</Label>}
        <Select
          value={selectValue}
          name="selectBox"
          onChange={handleChange}
          className={classes.input}
          input={
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <Icon
                    style={{
                      width: "7px",
                      height: "7px",
                      fontSize: "calc(0.65vw + 3px) !important",
                    }}
                  />
                </InputAdornment>
              }
              {...props}
            />
          }
        >
          {loading && <LinearProgress sx={{ m: 2 }} />}
          {items?.map((item) => {
            if (type === "string") {
              return <MenuItem value={item}>{item}</MenuItem>;
            } else {
              return (
                <MenuItem value={item?.[optionValuePropertyName]}>
                  {item?.[optionPropertyName]}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

let dara = {
  id: 172,
  siteName: "TCNWTP",
  city: "Tataskweyak Cree Nation",
  locationX: -96.10521940094362,
  locationY: 56.24209398200943,
  waterOrWaste: "water",
  wellOrSurface: "surface",
  pumpEnergy: null,
  numberOfHomes: null,
  people: null,
  volumeOfTanks: null,
  timeZoneInfoId: "-6:00",
  sensors: [
    {
      id: 1317,
      sensorCode: "Chlorine Dose PPM",
      description: "Chlorine Dose",
      sensorTypeId: 4,
      siteId: 172,
      sensorValue: 5.5,
      status: null,
      fromLeft: 12,
      fromTop: 12,
      alertConditions: "",
      isVirtual: null,
      formula: null,
      createdAt: "2023-06-10T13:31:29.207",
      isDeleted: false,
    },

    {
      id: 1327,
      sensorCode: "ClearPAC Dose PPM",
      description: "ClearPAC Dose",
      sensorTypeId: 1010,
      siteId: 172,
      sensorValue: 294,
      status: null,
      fromLeft: 54,
      fromTop: 93,
      alertConditions: "",
      isVirtual: null,
      formula: null,
      createdAt: "2023-06-12T08:25:35.257",
      isDeleted: false,
    },

    {
      id: 1328,
      sensorCode: "CL2410 Dose PPM",
      description: "CL2410 Dose",
      sensorTypeId: 1010,
      siteId: 172,
      sensorValue: 0.5,
      status: null,
      fromLeft: 54,
      fromTop: 81,
      alertConditions: "",
      isVirtual: null,
      formula: null,
      createdAt: "2023-06-12T08:25:35.257",
      isDeleted: false,
    },

    {
      id: 1329,
      sensorCode: "ClearPAC Volume m/L",
      description: "ClearPAC Flow",
      sensorTypeId: 2,
      siteId: 172,
      sensorValue: 3744780,
      status: null,
      fromLeft: 8,
      fromTop: 41,
      alertConditions: "",
      isVirtual: null,
      formula: null,
      createdAt: "2023-06-12T08:25:35.257",
      isDeleted: false,
    },

    {
      id: 1330,
      sensorCode: "CL2410 Volume m/L",
      description: "CL2410 Flow",
      sensorTypeId: 0,
      siteId: 172,
      sensorValue: 840,
      status: null,
      fromLeft: 37,
      fromTop: 17,
      alertConditions: "",
      isVirtual: null,
      formula: null,
      createdAt: "2023-06-12T08:30:28.527",
      isDeleted: false,
    },

    {
      id: 1331,
      sensorCode: "Chlorine Volume m/L",
      description: "Chlorine Flow",
      sensorTypeId: 2,
      siteId: 172,
      sensorValue: 25000,
      status: null,
      fromLeft: 37,
      fromTop: 30,
      alertConditions: "",
      isVirtual: null,
      formula: null,
      createdAt: "2023-06-12T08:30:28.527",
      isDeleted: false,
    },

    {
      id: 1332,
      sensorCode: "Inlet Flow",
      description: "Intake Flow",
      sensorTypeId: 1011,
      siteId: 172,
      sensorValue: 6.815072,
      status: null,
      fromLeft: 15,
      fromTop: 94,
      alertConditions: "",
      isVirtual: null,
      formula: null,
      createdAt: "2023-06-12T08:30:28.527",
      isDeleted: false,
    },

    {
      id: 1344,
      sensorCode: "Backwash 1 Flow",
      description: "Backwash 1 Flow",
      sensorTypeId: 1011,
      siteId: 172,
      sensorValue: 0.163562,
      status: null,
      fromLeft: null,
      fromTop: null,
      alertConditions: "",
      isVirtual: null,
      formula: null,
      createdAt: "2023-06-16T08:49:05.643",
      isDeleted: false,
    },

    {
      sensorCode: "Distribution Flow",
      description: "Distribution Flow",
      sensorTypeId: 1011,
      alertConditions: "",
    },

    {
      sensorCode: "Distribution Pressure",
      description: "Distribution Pressure",
      sensorTypeId: 1012,
      alertConditions: "",
    },

    {
      sensorCode: "Pump Chamber Level",
      description: "Pump Chamber Level",
      sensorTypeId: 1013,
      alertConditions: "",
    },

    {
      sensorCode: "Reservoir 1 Usable Volume",
      description: "Reservoir 1 Usable Volume",
      sensorTypeId: 1013,
      alertConditions: "",
    },

    {
      sensorCode: "Reservoir 2 Usable Volume",
      description: "Reservoir 2 Usable Volume",
      sensorTypeId: 1013,
      alertConditions: "",
    },

    {
      sensorCode: "Pump Chamber 2 Usable Volume",
      description: "Pump Chamber 2 Usable Volume",
      sensorTypeId: 1013,
      alertConditions: "",
    },
  ],
};
