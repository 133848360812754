import { Stack, Tab as MuiTab, Tabs } from "@mui/material";
import React from "react";

function Tab({ tab, tabs, setTab }) {
  function tabHandle(e, newValue) {
    setTab(newValue);
  }
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={{ marginBottom: "20px" }}
    >
      <Tabs value={tab} onChange={tabHandle}>
        {tabs?.map((item) => {
          return <MuiTab key={item} label={item} value={item} />;
        })}
      </Tabs>
    </Stack>
  );
}

export default Tab;
