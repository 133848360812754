import { Divider, Grid, InputAdornment, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  ADD_LOCATION,
  DELETE_LOCATION,
  GET_LOCATIONS,
  SITE,
} from "../../../../api/endpoints";
import { useRequest } from "../../../../api/hooks";
import LocationInput2 from "../../../../assets/icons/LocationInput2";
import PrimaryInput from "../../../form/inputs/primaryInput/PrimaryInput";
import SelectBox from "../../../form/selectBox/SelectBox";
import Item from "./Item";
import classes from "./Styles.module.scss";

function SiteLocations({ type = "site" }) {
  let [location, setLocation] = useState();
  let [sitesOptions, setSiteOptions] = useState([]);
  let [site, setSite] = useState(null);
  let [locationError, setLocationError] = useState(false);
  let [siteError, setSiteError] = useState(false);

  let [{ data: sites, loading: sitesLoading }] = useRequest(SITE);

  let [
    {
      data: locationsList,
      setData: setLocationsList,
      loading: siteLocationsLoading,
    },
    fetchLocations,
  ] = useRequest(GET_LOCATIONS + site?.id, {
    method: "GET",
    autoFetch: false,
  });

  let [{ loading: siteLocationsAddLoading }, addReqHandle] = useRequest(
    ADD_LOCATION,
    {
      method: "POST",
      autoFetch: false,
    }
  );

  let [{ loading: siteLocationsDeleteLoading }, deleteRequest] = useRequest(
    DELETE_LOCATION,
    {
      method: "DELETE",
    }
  );

  useEffect(() => {
    if (site) {
      setLocation("");
      fetchLocations();
    }
  }, [site]);

  useEffect(() => {
    if (sites) {
      let list = sites?.map((item) => {
        return `${item?.city} - ${item?.siteName}`;
      });
      setSiteOptions([...list]);
    }
  }, [sites]);

  function addLocationClick(e) {
    //Mediator function is required => Add function does not work directly
    addLocation(e, true);
  }

  function addLocation(e, plusClicked) {
    let { key } = e;
    if (key === "Enter" || plusClicked) {
      if (!site) {
        setSiteError(true);
        return toast?.error("Please select the site first");
      }
      if (!location) {
        setLocationError(true);
        return toast?.error("Please enter the location!");
      }
      addReqHandle({
        siteId: site?.id,
        name: location,
      }).then((res) => {
        console.log(res);
        if (res?.result) {
          setLocationsList([...locationsList, { ...res?.result }]);
          setLocation("");
        }
      });
    }
  }

  function deleteLocation(name) {
    let find = locationsList?.find((m) => m?.name === name);
    deleteRequest({ id: find?.id }).then(() => {
      let list = locationsList?.filter((m) => m?.name !== name);
      setLocationsList([...list]);
    });
  }

  function selectSiteHandle(selected, existSite) {
    let find = existSite;
    if (!existSite) {
      let siteName = selected?.split(" - ")?.[1];
      find = sites?.find((item) => item?.siteName === siteName);
    }
    setSiteError(false);
    setSite(find);
  }

  return (
    <div>
      {type === "site" && <div>3. Choose or edit Locations for the site.</div>}
      <br />
      <br />
      <Grid container>
        <Grid item xs={6}>
          <SelectBox
            label="Site Name"
            Icon={LocationInput2}
            setSelected={selectSiteHandle}
            selected={`${site?.city} - ${site?.siteName}`}
            loading={sitesLoading}
            items={sitesOptions}
            placeholder="Select an User to EditE"
            error={siteError}
          />
          <br />
          <br />
          <div className={classes.select_container}>
            <div>Add or Edit Location To Selected Site</div>
            <br />
            <PrimaryInput
              label="Location"
              Icon={LocationInput2}
              placeholder="Enter the location and press enter"
              value={location}
              onChange={(e) => {
                setLocationError(false);
                setLocation(e.target.value);
              }}
              onKeyDown={addLocation}
              error={locationError}
              endAdornment={
                <InputAdornment position="end">
                  <span
                    onClick={addLocationClick}
                    style={{
                      marginRight: "10px",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  >
                    +
                  </span>
                </InputAdornment>
              }
            />
            <Divider sx={{ mt: "25px", mb: "25px" }} />
            <div className={classes.items_container}>
              {(siteLocationsLoading ||
                siteLocationsAddLoading ||
                siteLocationsDeleteLoading) && <LinearProgress />}
              {locationsList?.map((item) => {
                return (
                  <Item
                    deleteLocation={deleteLocation}
                    name={item?.name}
                    icon={item?.icon}
                  />
                );
              })}
            </div>
          </div>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </div>
  );
}

export default SiteLocations;
