import React from "react";
import Button from "./Button";
import RightArrow from "../../../assets/icons/RightArrow";

function NextButton({ ...props }) {
  return (
    <Button variant="contained" justifyContent="space-between" {...props}>
      <div>Next</div>
      <div>
        <RightArrow />
      </div>
    </Button>
  );
}

export default NextButton;
