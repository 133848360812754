import React from "react";
import classes from "./Style.module.scss";

export default function Label({ children }) {
  return (
    <div className={classes.label_div}>
      <label>{children}</label>&nbsp;
    </div>
  );
}
