import React, { useEffect, useState } from "react";
import classes from "./SensorsList.module.scss";
import { Divider } from "@mui/material";
import SignalIcon from "../../../../../../assets/icons/SignalIcon";
import Item from "./Item";
function SensorsList({ sensors, setSensor, siteName, timeZone }) {
  let [chlorineSensorsCount, setChlorineSensorsCount] = useState(0);
  useEffect(() => {
    if (sensors) {
      let count = 0;
      sensors?.forEach((item) => {
        if (item?.sensorType?.parameter?.includes("Chlorine" || "chlorine")) {
          count += 1;
        }
        setChlorineSensorsCount(count);
      });
    }
  }, [sensors]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>List of Sensors in {siteName}</div>
        <div className={classes.total}>
          <SignalIcon />
          &nbsp; {sensors?.length} Sensors
        </div>
      </div>
      <Divider sx={{ my: "15px" }} />
      <div style={{ fontSize: "12px" }}>
        Chlorine Sensors ({chlorineSensorsCount} Sensors)
      </div>
      <Divider sx={{ my: "15px" }} />
      <div className={classes.list}>
        {sensors?.map((item) => {
          return (
            <Item sensor={item} setSensor={setSensor} timeZone={timeZone} />
          );
        })}
      </div>
    </div>
  );
}

export default SensorsList;
