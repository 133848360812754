import React from "react";

function EditSensorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.475"
      height="15.999"
      viewBox="0 0 14.475 15.999"
    >
      <path
        id="icons8_edit_file"
        d="M9.714,4A1.716,1.716,0,0,0,8,5.714V17.523a1.716,1.716,0,0,0,1.714,1.714h3.635a1.333,1.333,0,0,1,.071-.28h0c.02-.085.046-.175.072-.267l.171-.6H9.714a.572.572,0,0,1-.571-.571V5.714a.572.572,0,0,1,.571-.571h4.381V8.381a1.716,1.716,0,0,0,1.714,1.714h3.238v1.46a2.523,2.523,0,0,1,1.143-.625V9.523a.569.569,0,0,0-.167-.4L15.07,4.167a.569.569,0,0,0-.4-.167Zm5.523,1.951,3,3h-2.43a.572.572,0,0,1-.571-.571Zm5.523,5.668a1.791,1.791,0,0,0-.316.029,1.766,1.766,0,0,0-.93.508L15.02,16.65a1.146,1.146,0,0,0-.289.49l-.6,2.088a.579.579,0,0,0-.037.225A.565.565,0,0,0,14.64,20a.58.58,0,0,0,.227-.037l2.088-.6a1.142,1.142,0,0,0,.489-.289l4.5-4.495a1.766,1.766,0,0,0,.508-.93,1.827,1.827,0,0,0,.028-.316,1.716,1.716,0,0,0-1.714-1.715Z"
        transform="translate(-8 -4)"
        fill="#313131"
      />
    </svg>
  );
}

export default EditSensorIcon;
