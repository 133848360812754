import React from "react";

function SystemInformation({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height="14.257"
      viewBox="0 0 15 14.257"
      {...props}
    >
      <path
        id="icons8_system_information"
        d="M5.688,5A1.7,1.7,0,0,0,4,6.688v7.875A1.7,1.7,0,0,0,5.688,16.25H8.875v1.875H7.563a.563.563,0,1,0,0,1.125H9.345a.563.563,0,0,0,.182,0H13.47a.563.563,0,0,0,.182,0h1.785a.563.563,0,1,0,0-1.125H14.125V16.25h3.188A1.7,1.7,0,0,0,19,14.563V6.688A1.7,1.7,0,0,0,17.313,5Zm0,1.125H17.313a.554.554,0,0,1,.563.563v7.875a.554.554,0,0,1-.562.563H13.655a.563.563,0,0,0-.182,0H9.53a.563.563,0,0,0-.182,0H5.688a.554.554,0,0,1-.562-.562V6.688A.554.554,0,0,1,5.688,6.125ZM14.3,8.37a.563.563,0,0,0-.387.17l-3.352,3.352L9.085,10.415a.563.563,0,1,0-.8.8l1.875,1.875a.563.563,0,0,0,.8,0l3.75-3.75A.563.563,0,0,0,14.3,8.37ZM10,16.25h3v1.875H10Z"
        transform="translate(-4 -5)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default SystemInformation;
