import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AUTHENTICATION } from "../../api/endpoints";
import { useRequest } from "../../api/hooks";
import Logo from "../../assets/images/logo.webp";
import LoginBG from "../../assets/images/login_bg.jpg";
import { loginUser, useUserDispatch } from "../../context/UserContext";
import classes from "./login.module.scss";

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  let navigate = useNavigate();
  let dispatch = useUserDispatch();

  const [{ loading, message, error }, login] = useRequest(AUTHENTICATION, {
    method: "POST",
    data: {
      userName,
      password,
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  function loginHandle() {
    login().then((res) => {
      if (res?.isSucceed) {
        loginUser({ navigate, dispatch, loginData: res?.result });
      }
    });
  }

  return (
    <Grid container direction="row">
      <Grid item xs={false} sm={4} md={7}>
        <img width="100%" height="100%" src={LoginBG} alt="login background" />
      </Grid>

      <Grid
        item
        container
        xs={12}
        sm={8}
        md={5}
        direction="column"
        alignItems="center"
        className={classes.login}
        component={Paper}
        elevation={6}
        square
      >
        <Grid item className={classes.logo_container}>
          <img src={Logo} alt="aqua logo" />
        </Grid>

        <Grid
          item
          container
          spacing={4}
          justifyContent="center"
          className={classes.form_container}
        >
          <Grid item xs={12}>
            <TextField
              label="Email"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              error={error}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="filled-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                error={error}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                }
              />
            </FormControl>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={12}
            sx={{ marginBottom: "5vh" }}
          >
            {message && <span className={classes.error}>{message}</span>}

            <LoadingButton
              className={classes.button}
              size="large"
              fullWidth
              variant="contained"
              onClick={loginHandle}
              loading={loading}
              disabled={!userName && !password}
            >
              Login
            </LoadingButton>
          </Grid>
        </Grid>
        <span className={classes.forget_password}>Forget Password?</span>
      </Grid>
    </Grid>
  );
}

export default Login;
