import { Grid, LinearProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GET_CHEMICAL_ORDERING } from "../../../../../api/endpoints";
import { useRequest } from "../../../../../api/hooks";
import ChemicalConsumptionIcon from "../../../../../assets/images/Chemical Consumption.png";
import AccordionComponent from "../../../../../components/accordion/AccordionComponent";
import { showDate } from "../../../../../utils/date";
import Warning from "../commonComponents/Warning";
import classes from "./chemical.module.scss";
import Progress from "./components/Progress";

function ProgressContainer({ name, date, timeZone, ...props }) {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.progress_container}
      spacing={2}
    >
      <Grid item>
        <Progress {...props} />
      </Grid>
      <Grid item>
        <Grid item className={classes.name}>
          {name}
        </Grid>
        <Grid item className={classes.date}>
          Last Refill: {showDate(date, timeZone)}
        </Grid>
      </Grid>
    </Grid>
  );
}

function ChemicalConsumption({ siteId, timeZone }) {
  const [expanded, setExpanded] = useState(false);

  let [{ data, loading, message, error }, fetch] = useRequest(
    GET_CHEMICAL_ORDERING,
    {
      method: "GET",
      autoFetch: false,
    }
  );

  useEffect(() => {
    if (expanded) {
      fetch();
    }
  }, [expanded]);

  function expandHandle() {
    setExpanded(!expanded);
  }

  return (
    <AccordionComponent
      onChange={expandHandle}
      expanded={expanded}
      name="Chemical Consumption"
      icon={ChemicalConsumptionIcon}
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {data ? (
            <>
              <Stack direction="row" spacing={1}>
                {data?.map((item) => {
                  return (
                    <ProgressContainer
                      name={item?.title}
                      date={item?.lastRefill}
                      value={item?.percentage}
                      timeZone={timeZone}
                    />
                  );
                })}
              </Stack>
            </>
          ) : (
            <Warning message={message} error={error} />
          )}
        </>
      )}
    </AccordionComponent>
  );
}

export default ChemicalConsumption;
