import { Breadcrumbs, ButtonBase, Stack } from "@mui/material";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import classes from "./Style.module.scss";

function AddSite() {
  let { pathname } = useLocation();

  return (
    <>
      <div className={classes.container}>
        <Stack
          direction="row"
          justifyContent="space-between"
          className={classes.header}
        >
          <div className={classes.title}>Add New Site</div>
          <div className={classes.breadcrumb}>
            <Breadcrumbs aria-label="breadcrumb">
              <ButtonBase
                sx={{
                  fontWeight: pathname?.includes("location")
                    ? "bold"
                    : "normal",
                }}
              >
                Location
              </ButtonBase>
              <ButtonBase
                sx={{
                  fontWeight: pathname?.includes("information")
                    ? "bold"
                    : "normal",
                }}
              >
                Information
              </ButtonBase>
              <ButtonBase
                sx={{
                  fontWeight: pathname?.includes("modules") ? "bold" : "normal",
                }}
              >
                Modules
              </ButtonBase>
              <ButtonBase
                sx={{
                  fontWeight: pathname?.includes("image") ? "bold" : "normal",
                }}
              >
                Upload Image
              </ButtonBase>
              <ButtonBase
                sx={{
                  fontWeight: pathname?.includes("sensors") ? "bold" : "normal",
                }}
              >
                Sensors
              </ButtonBase>
            </Breadcrumbs>
          </div>
        </Stack>
        <Outlet />
      </div>
    </>
  );
}

export default AddSite;
