import React from "react";

function HomeIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height="16.25"
      viewBox="0 0 15 16.25"
      {...props}
    >
      <path
        id="icons8_home"
        d="M13.48,4a.625.625,0,0,0-.366.134L7.191,8.8A3.127,3.127,0,0,0,6,11.254v7.954A1.051,1.051,0,0,0,7.042,20.25h4.167a1.051,1.051,0,0,0,1.042-1.042V15.041a.2.2,0,0,1,.208-.208h2.083a.2.2,0,0,1,.208.208v4.167a1.051,1.051,0,0,0,1.042,1.042h4.167A1.051,1.051,0,0,0,21,19.208V11.254A3.127,3.127,0,0,0,19.809,8.8L13.887,4.134A.625.625,0,0,0,13.48,4ZM13.5,5.42l5.535,4.361a1.873,1.873,0,0,1,.715,1.472V19H16V15.041a1.468,1.468,0,0,0-1.458-1.458H12.458A1.468,1.468,0,0,0,11,15.041V19H7.25V11.254a1.873,1.873,0,0,1,.714-1.472Z"
        transform="translate(-6 -3.999)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default HomeIcon;
