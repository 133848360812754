import Axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BASE_URL } from "./endpoints";
import { localStorageTokenName } from "../config";

let token = localStorage.getItem(localStorageTokenName);

export let request_instance = Axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const useRequest = (
  url,
  options = {
    method: "GET",
    autoFetch: true,
  }
) => {
  let [data, setData] = useState(null);
  let [loading, setLoading] = useState(false);
  let [message, setMessage] = useState(null);
  let [error, setError] = useState(null);

  const axiosInstance = Axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  useEffect(() => {
    if (
      options?.method.toUpperCase() === "GET" &&
      !data &&
      options?.autoFetch
    ) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetch(requestData, reqType = "object", customOption = {}) {
    //reqType => "list" or "object"
    setLoading(true);
    if (requestData) {
      if (reqType === "object") {
        options = { ...options, data: { ...options?.data, ...requestData } };
      } else if (reqType === "list") {
        options = { ...options, data: [...requestData] };
      } else {
        options = { ...options, data: requestData }; // for ex :  for formData
      }
    }

    return new Promise(function (resolve, reject) {
      axiosInstance({
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...options,
        ...customOption,
      })
        .then((res) => {
          let resData = res?.data;
          setData(resData?.result);
          setMessage(resData?.message);

          if (resData?.isSucceed === true) {
            if (resData?.message) {
              toast.success(resData?.message);
            }
            resolve(resData);
          } else {
            if (resData?.message) {
              toast.error(resData?.message);
            }
            setError(true);
            // reject(resData);
          }
          if (!resData?.result && resData?.result !== null) {
            setData(resData);
            resolve(resData);
          }
        })
        .catch((err) => {
          toast.error("Network Error!");
          if (err?.response?.status === 401) {
            window.location = "/login";
          }
          reject(err);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }

  return [{ data, loading, message, error, setData }, fetch];
};
