import React from "react";

function ZoomIn({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="0 0 26 26"
      cursor="pointer"
      {...props}
    >
      <g
        id="Group_11017"
        data-name="Group 11017"
        transform="translate(-15 -496)"
      >
        <rect
          id="Rectangle_4550"
          data-name="Rectangle 4550"
          width={26}
          height={26}
          rx={4}
          transform="translate(15 496)"
          fill="#21446c"
        />
        <path
          id="icons8_zoom_in"
          d="M12.25,6a6.25,6.25,0,0,0,0,12.5,6.186,6.186,0,0,0,3.77-1.3l3.557,3.557a.833.833,0,1,0,1.179-1.179L17.2,16.02a6.186,6.186,0,0,0,1.3-3.77A6.263,6.263,0,0,0,12.25,6Zm0,1.667A4.583,4.583,0,1,1,7.667,12.25,4.571,4.571,0,0,1,12.25,7.667Zm-.012,1.655a.833.833,0,0,0-.821.845v1.25h-1.25a.833.833,0,1,0,0,1.667h1.25v1.25a.833.833,0,1,0,1.667,0v-1.25h1.25a.833.833,0,1,0,0-1.667h-1.25v-1.25a.833.833,0,0,0-.846-.845Z"
          transform="translate(15 496)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default ZoomIn;
