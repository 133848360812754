import { Stack } from "@mui/material";
import React from "react";

function ButtonRow({ children, ...props }) {
  return (
    <Stack direction="row" mt="60px" {...props}>
      {children}
    </Stack>
  );
}

export default ButtonRow;
