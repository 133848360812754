import React from "react";

function PlayListIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="icons8_playlist"
        d="M8.444,7A2.455,2.455,0,0,0,6,9.444V20.556A2.455,2.455,0,0,0,8.444,23H19.556A2.455,2.455,0,0,0,22,20.556V9.444A2.455,2.455,0,0,0,19.556,7Zm0,1.333H19.556a1.1,1.1,0,0,1,1.111,1.111V20.556a1.1,1.1,0,0,1-1.111,1.111H8.444a1.1,1.1,0,0,1-1.111-1.111V9.444A1.1,1.1,0,0,1,8.444,8.333Zm1.333,2.889a.889.889,0,1,0,.889.889A.889.889,0,0,0,9.778,11.222Zm2.667.222a.667.667,0,1,0,0,1.333h6.222a.667.667,0,1,0,0-1.333ZM9.778,14.333a.889.889,0,1,0,.889.889A.889.889,0,0,0,9.778,14.333Zm2.667.222a.667.667,0,1,0,0,1.333h6.222a.667.667,0,1,0,0-1.333ZM9.778,17.444a.889.889,0,1,0,.889.889A.889.889,0,0,0,9.778,17.444Zm2.667.222a.667.667,0,1,0,0,1.333h6.222a.667.667,0,1,0,0-1.333Z"
        transform="translate(-6 -7)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default PlayListIcon;
