import { Grid } from "@mui/material";
import React from "react";
import CalendarIcon from "../../../../../assets/icons/CalendarIcon";
import ClockIcon from "../../../../../assets/icons/ClockIcon";
import { showDate, showTime } from "../../../../../utils/date";

function ChartInfoDateTime({ date, time, timeZone, convertTime = false }) {
  return (
    <Grid container direction="row" sx={{ color: "#8F8F8F" }} my={2}>
      <Grid item container alignItems="center" xs={2.5}>
        <CalendarIcon />
        &nbsp; Date : {date ? <>{showDate(date, timeZone)} </> : "-"}
      </Grid>
      <Grid item container alignItems="center" xs={4}>
        <ClockIcon />
        &nbsp;Time :{" "}
        {time ? <>{convertTime ? showTime(time, timeZone) : time}</> : "-"}
      </Grid>
    </Grid>
  );
}

export default ChartInfoDateTime;
