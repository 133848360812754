import React from "react";

function FullScreen({ ...props }) {
  return (
    <svg
      id="Group_10744"
      data-name="Group 10744"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <rect
        id="Rectangle_4377"
        data-name="Rectangle 4377"
        width={24}
        height={24}
        rx={5}
        fill="#21446c"
      />
      <path
        id="icons8_full_screen"
        d="M4.509,3A1.52,1.52,0,0,0,3,4.509V6.772a.754.754,0,1,0,1.509,0V4.509H6.772A.754.754,0,1,0,6.772,3Zm8.3,0a.754.754,0,1,0,0,1.509H15.07V6.772a.754.754,0,1,0,1.509,0V4.509A1.52,1.52,0,0,0,15.07,3ZM3.743,12.042A.754.754,0,0,0,3,12.807V15.07a1.52,1.52,0,0,0,1.509,1.509H6.772a.754.754,0,1,0,0-1.509H4.509V12.807a.754.754,0,0,0-.766-.765Zm12.07,0a.754.754,0,0,0-.743.765V15.07H12.807a.754.754,0,1,0,0,1.509H15.07a1.52,1.52,0,0,0,1.509-1.509V12.807a.754.754,0,0,0-.766-.765Z"
        transform="translate(2.21 2.21)"
        fill="#fff"
      />
    </svg>
  );
}

export default FullScreen;
