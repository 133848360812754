import { isJwtExpired } from "jwt-check-expiration";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes } from "react-router-dom";
import Location from "./components/AddAndEditSite/components/Location";
import SetLocation from "./components/AddAndEditSite/components/SetLocation";
import Information from "./components/AddAndEditSite/components/information/Information";
import Modules from "./components/AddAndEditSite/components/modules/Modules";
import Sensors from "./components/AddAndEditSite/components/sensors/Sensors";
import UploadImage from "./components/AddAndEditSite/components/sensors/UploadImage";
import { localStorageTokenName } from "./config";
import { useUserState } from "./context/UserContext";
import Layout from "./layout/Layout";
import AddSitePage from "./pages/information/addSite/AddSitePage";
import EditModules from "./pages/information/editModules/EditModules";
import EditLocations from "./pages/information/editLocations/EditLocations";
import EditSitePage from "./pages/information/editSite/EditSitePage";
import EditUser from "./pages/information/editUser/EditUser";
import AddUser from "./pages/information/newUser/AddUser";
import Login from "./pages/login/Login";
import Sites from "./pages/sites/Sites";
import System from "./pages/system/System";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRouter>
              <Layout />
            </PrivateRouter>
          }
        >
          <Route path="/" element={<Sites />}>
            <Route path=":city" element={<Sites />} />
            <Route path=":city/:siteId" element={<Sites />} />
          </Route>
          <Route path="/add-site" element={<AddSitePage />}>
            <Route path="" element={<SetLocation />} />
            <Route path=":lat/:lng/information" element={<Information />} />
            <Route path=":siteID/modules" element={<Modules />} />
            <Route path=":siteID/image" element={<UploadImage />} />
            <Route path=":siteID/sensors" element={<Sensors />} />
          </Route>
          <Route path="/edit-sites" element={<EditSitePage />}>
            <Route path=":lat/:lng/:siteID/location" element={<Location />} />
            <Route
              path=":lat/:lng/:siteID/information"
              element={<Information />}
            />
            <Route path=":siteID/modules" element={<Modules />} />
            <Route path=":siteID/image" element={<UploadImage />} />
            <Route path=":siteID/sensors" element={<Sensors />} />
          </Route>
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/edit-users" element={<EditUser />} />
          <Route path="/system" element={<System />}>
            <Route path=":city" element={<System />} />
            <Route path=":city/:siteId" element={<System />} />
          </Route>
          <Route index path="/edit-modules" element={<EditModules />} />
          <Route index path="/edit-Locations" element={<EditLocations />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
      <Toaster
        position="top-center"
        toastOptions={{
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        }}
      />
    </>
  );
}

// #######################################################################

function PrivateRouter({ children }) {
  let { isAuthenticated } = useUserState();

  if (
    isAuthenticated === false ||
    isJwtExpired(localStorage.getItem(localStorageTokenName))
  ) {
    return <Navigate to="/login" replace />;
  } else {
    return children;
  }
}

export default App;
