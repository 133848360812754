import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_SENSORS_TYPE,
  DELETE_SENSORS_TYPE,
  GET_SENSORS_TYPE,
  UPDATE_SENSORS_TYPE,
} from "../../api/endpoints";
import { toast } from "react-hot-toast";
import { request_instance } from "../../api/hooks";

export const getSensorTypes = createAsyncThunk(
  "sensors/GET_SENSOR_TYPES",
  async () => {
    const response = await request_instance({
      method: "GET",
      url: GET_SENSORS_TYPE,
    });
    return response.data;
  }
);

export const createSliceSensorTypes = createSlice({
  name: "sensorTypes",
  initialState: {
    loading: false,
    list: [],
  },
  reducers: {
    addSensorType(state, action) {
      state.list = [action?.payload, ...state?.list];
    },
    updateSensorType(state, action) {
      console.log(action);
      let allData = [...state.list];
      let newItem = action.payload;
      let item = allData?.find((i) => i?.id === newItem?.id);
      let index = allData?.indexOf(item);
      allData[index] = newItem;
      state.list = [...allData];
    },
    deleteSensorType(state, action) {
      let id = action.payload;
      console.log(action);
      state.list = state?.list?.filter((item) => item?.id != id);
    },
  },
  extraReducers: (builder) => {
    // ==================== GET =====================
    builder.addCase(getSensorTypes.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSensorTypes.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.list = action.payload?.result;
      } else {
        state.list = [];
      }
    });

    builder.addCase(getSensorTypes.rejected, (state) => {
      state.loading = false;
      toast.error("server not responding");
    });
  },
});

export default createSliceSensorTypes.reducer;
export const { addSensorType, updateSensorType, deleteSensorType } =
  createSliceSensorTypes.actions;
