import { Grid, LinearProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { GET_CHLORINE_INFO } from "../../../../api/endpoints";
import { useRequest } from "../../../../api/hooks";
import ChlorineManagementIcon from "../../../../assets/images/Chlorination module.png";
import AccordionComponent from "../../../../components/accordion/AccordionComponent";
import Chart from "../../../../components/chart/Chart";
import Tab from "../../../../components/tab/Tab";
import TableHeader from "../../../../components/table/TableHeader";
import ChartInfoDateTime from "./commonComponents/ChartInfoDateTime";
import ChartInfoRow from "./commonComponents/ChartInfoRow";
import Warning from "./commonComponents/Warning";
import { dateByTimeZone } from "../../../../utils/date";

function ChlorineManagement({ siteName, siteId, timeZone }) {
  const [expanded, setExpanded] = useState(false);
  let [tabs, setTabs] = useState(null);
  let [tab, setTab] = useState(null);
  let ref = useRef();

  let [{ data, loading, message, error }, fetch] = useRequest(
    GET_CHLORINE_INFO + "/" + siteId,
    {
      method: "GET",
      autoFetch: false,
    }
  );

  useEffect(() => {
    if (expanded) {
      fetch();
    }
  }, [expanded]);

  useEffect(() => {
    if (data) {
      let sortedTabs = [];
      for (let tab in data) {
        sortedTabs.push(tab);
      }
      setTabs([...sortedTabs]);
      setTab(sortedTabs?.[0]);
    }
  }, [data]);

  function expandHandle() {
    setExpanded(!expanded);
  }

  return (
    <AccordionComponent
      onChange={expandHandle}
      expanded={expanded}
      name="Chlorine Management"
      icon={ChlorineManagementIcon}
    >
      <TableHeader
        title={false}
        data={
          data?.[tab]
            ? [
                ...data?.[tab]?.freeChlorineResidualHistory,
                ...data?.[tab]?.chlorineDemandHistory,
              ]
            : null
        }
        headers={["id", "date", "value"]}
        refForPrint={ref}
        filterDate={false}
      />
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {data ? (
            <div ref={ref} style={{ padding: 15 }}>
              <Tab tabs={tabs} tab={tab} setTab={setTab} />
              <ChartInfoRow
                title="Tank Free Chlorine Residual"
                value={data?.[tab]?.tankFreeChlorineResidual}
                unit="ppm"
              />
              <ChartInfoRow
                title=" Chlorine Demand"
                value={data?.[tab]?.chlorineDemand}
                unit="ppm"
              />
              {tab === "today" && (
                <ChartInfoRow
                  title="Chlorine Flow"
                  value={data?.[tab]?.chlorineFlow}
                  unit="mL/min"
                />
              )}
              <ChartInfoDateTime
                date={data?.[tab]?.reportDateTime}
                time={data?.[tab]?.reportDateTime}
                timeZone={timeZone}
                convertTime={true}
              />

              <br />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Chart
                    series={[
                      {
                        data: data?.[tab]?.freeChlorineResidualHistory?.map(
                          (item) => {
                            return {
                              x: dateByTimeZone(item?.dateTime, timeZone),
                              y: item?.value,
                            };
                          }
                        ),
                      },
                    ]}
                    title="Free Chlorine Residual"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Chart
                    series={[
                      {
                        data: data?.[tab]?.chlorineDemandHistory?.map(
                          (item) => {
                            return {
                              x: dateByTimeZone(item?.dateTime, timeZone),
                              y: item?.value,
                            };
                          }
                        ),
                      },
                    ]}
                    title="Chlorine Demand"
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <Warning message={message} error={error} />
          )}
        </>
      )}
    </AccordionComponent>
  );
}

export default ChlorineManagement;
