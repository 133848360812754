import { LinearProgress } from "@mui/material";
import MaterialReactTable from "material-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { GET_ALL_REPORTS } from "../../../../api/endpoints";
import { useRequest } from "../../../../api/hooks";
import ManagementReportIcon from "../../../../assets/images/Managment Report.png";
import AccordionComponent from "../../../../components/accordion/AccordionComponent";
import TableHeader from "../../../../components/table/TableHeader";
import {
  LOCAL_NOW,
  SEVEN_DAYS_AGO,
  getUtcDateTimeString,
  showDate,
  showTime,
} from "../../../../utils/date";
import Warning from "./commonComponents/Warning";

function ManagementReport({ siteName, siteId, timeZone }) {
  const [expanded, setExpanded] = useState(false);

  let [startDate, setStartDate] = useState(SEVEN_DAYS_AGO);
  let [endDate, setEndDate] = useState(LOCAL_NOW);
  const [tableData, setTableData] = useState([]);
  let ref = useRef(null);

  let [{ data, loading, message, error }, fetch] = useRequest(GET_ALL_REPORTS, {
    method: "POST",
    data: {
      siteID: siteId,
      reportFrom: getUtcDateTimeString(startDate),
      reportTo: getUtcDateTimeString(endDate),
    },
  });

  useEffect(() => {
    if (expanded) {
      fetch();
    }
  }, [startDate, endDate, expanded]);

  useEffect(() => {
    if (data) {
      setTableData([...data?.records]);
    }
  }, [data]);

  const columns = useMemo(() => [
    {
      accessorKey: "subject",
      header: "Subject",
      size: 140,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 90,
    },
    {
      accessorKey: "actionDateTime",
      header: "Date",
      size: 80,
      Cell: ({ cell }) => {
        return cell.getValue() ? showDate(cell.getValue(), timeZone) : " - ";
      },
    },
    {
      accessorKey: "actionDateTime",
      header: "Time",
      size: 80,
      Cell: ({ cell }) => {
        return cell.getValue() ? showTime(cell.getValue(), timeZone) : " - ";
      },
    },
    {
      accessorKey: "maintenanceTime",
      header: "Maint. Duration",
      size: 80,
    },
    {
      accessorKey: "cost",
      header: "Cost",
      size: 80,
      Cell: ({ cell }) => {
        return cell.getValue() ? "$" + cell.getValue() : " - ";
      },
    },
  ]);

  function expandHandle() {
    setExpanded(!expanded);
  }

  return (
    <AccordionComponent
      onChange={expandHandle}
      expanded={expanded}
      name="Management Report"
      icon={ManagementReportIcon}
    >
      <TableHeader
        startDate={startDate}
        endDate={endDate}
        siteName={siteName}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        title={false}
        data={tableData}
        headers={columns?.map((c) => c.header)}
        refForPrint={ref}
      />
      {loading ? (
        <LinearProgress />
      ) : (
        <div ref={ref}>
          {data ? (
            <MaterialReactTable
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  muiTableHeadCellProps: {
                    align: "center",
                  },
                  size: 120,
                },
              }}
              columns={columns}
              data={tableData}
            />
          ) : (
            <Warning message={message} error={error} />
          )}
        </div>
      )}
    </AccordionComponent>
  );
}

export default ManagementReport;
