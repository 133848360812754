import { ButtonBase, Grid } from "@mui/material";
import React from "react";
import DeleteIcon from "../../../../../assets/icons/DeleteIcon";
import EditIcon from "../../../../../assets/icons/EditIcon";
import SignalInput from "../../../../../assets/icons/SignalInput";
import classes from "./Style.module.scss";
function SensorItem({ sensor, sensorTypes, deleteSensor, setSensorForEdit }) {
  let sensorType = sensorTypes?.find(
    (item) => item?.id === sensor?.sensorTypeId
  );

  function editHandle() {
    let find = document?.getElementById("sensorFormEdit");
    if (find) {
      find?.scrollIntoView({
        behavior: "smooth",
      });
    }
    setSensorForEdit(sensor);
  }

  function deleteHandle() {
    deleteSensor(sensor);
  }
  return (
    <div className={classes.sensor_item_container}>
      <Grid container spacing={1} mb={1}>
        <Grid item xs={1}>
          <SignalInput color="#000" />
        </Grid>
        <Grid item xs={9}>
          {sensor?.sensorCode}
        </Grid>
        <Grid item container justifyContent="flex-end" spacing={2} xs={2}>
          <Grid item>
            <ButtonBase onClick={editHandle}>
              <EditIcon />
            </ButtonBase>
          </Grid>
          <Grid item>
            <ButtonBase onClick={deleteHandle}>
              <DeleteIcon />
            </ButtonBase>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={1}></Grid>
        <Grid
          item
          container
          direction="column"
          xs={10}
          sx={{ fontSize: "13px", color: "#9E9E9E" }}
        >
          {sensorType?.parameter && (
            <Grid item>
              {sensorType?.parameter} ( {sensorType?.unit} )
            </Grid>
          )}

          <Grid item>{sensor?.alertConditions}</Grid>
          <Grid item>{sensor?.description}</Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default SensorItem;
