import React from "react";

function ActionIcon({ color = "#9e9e9e" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.98"
      height={10}
      viewBox="0 0 9.98 10"
    >
      <path
        id="icons8_support_1"
        d="M13.684,8.674a.921.921,0,0,1-1.269.032.956.956,0,0,1,0-1.369l1.547-1.547a.394.394,0,0,0-.156-.655A3.144,3.144,0,0,0,12.885,5,3.193,3.193,0,0,0,9.759,7.814,3.122,3.122,0,0,0,9.94,9.255L6.485,12.709a1.363,1.363,0,0,0-.01,1.938A1.316,1.316,0,0,0,8.3,14.614l3.5-3.5a3.121,3.121,0,0,0,1.441.181A3.192,3.192,0,0,0,16.055,8.17a3.144,3.144,0,0,0-.134-.923.394.394,0,0,0-.655-.156Z"
        transform="translate(-6.075 -5)"
        fill={color}
      />
    </svg>
  );
}

export default ActionIcon;
