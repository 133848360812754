import React from "react";

function AlarmIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.455"
      height={15}
      viewBox="0 0 18.455 15"
      {...props}
    >
      <path
        id="icons8_alarm"
        d="M1.333,3.986A.577.577,0,0,0,.985,5.019L2.908,6.557a.577.577,0,1,0,.721-.9L1.706,4.118a.577.577,0,0,0-.373-.131Zm15.772,0a.577.577,0,0,0-.356.131L14.826,5.655a.577.577,0,1,0,.721.9L17.47,5.019A.577.577,0,0,0,17.1,3.986Zm-7.877.005A5.776,5.776,0,0,0,3.46,9.759v3.147L2.4,15.333a.978.978,0,0,0,.881,1.346H6.921a2.307,2.307,0,1,0,4.614,0h3.641a.978.978,0,0,0,.881-1.346l-1.062-2.427V9.759A5.776,5.776,0,0,0,9.227,3.991Zm0,1.153a4.6,4.6,0,0,1,4.614,4.614v3.268a.577.577,0,0,0,.048.231l.993,2.268H3.573l.993-2.268a.577.577,0,0,0,.048-.231V9.759A4.6,4.6,0,0,1,9.227,5.145ZM.577,8.605a.577.577,0,1,0,0,1.153H2.115a.577.577,0,1,0,0-1.153Zm15.764,0a.577.577,0,1,0,0,1.153h1.538a.577.577,0,1,0,0-1.153ZM8.074,16.679h2.307a1.153,1.153,0,0,1-2.307,0Z"
        transform="translate(0 -3.986)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default AlarmIcon;
