import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SiteItem from "./SiteItem";

function Site({ sites, noAnySite, setSite }) {
  let { city } = useParams();
  let navigate = useNavigate();

  return (
    <ScrollingCarousel>
      {noAnySite && <>No site has been registered for this city</>}
      {sites?.map((item) => {
        return (
          <SiteItem
            site={item}
            onClick={() => {
              if (setSite) {
                setSite(item);
              } else {
                navigate(`${city}/${item?.id}`);
              }
            }}
          />
        );
      })}
    </ScrollingCarousel>
  );
}

export default Site;
