import { ButtonBase, FormControl, FormLabel, Grid, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
// import { GET_SENSORS_TYPE } from "../../../../api/endpoints";
// import { useRequest } from "../../../../api/hooks";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ActionIcon from "../../../../../assets/icons/ActionIcon";
import ClockArrow from "../../../../../assets/icons/ClockArrow";
import CloseIcon from "../../../../../assets/icons/CloseIcon";
import DollarIcon from "../../../../../assets/icons/DollarIcon";
import Button from "../../../../../components/form/button/Button";
import ButtonRow from "../../../../../components/form/button/ButtonRow";
import PrimaryInput from "../../../../../components/form/inputs/primaryInput/PrimaryInput";
import SelectBox from "../../../../../components/form/selectBox/SelectBox";
import classes from "./Styles.module.scss";
import { useRequest } from "../../../../../api/hooks";
import { ADD_MAINTENANCE_ACTION } from "../../../../../api/endpoints";
import moment from "moment";
import { LOCAL_NOW } from "../../../../../utils/date";

export default function Action({ row, open, closeModal }) {
  console.log(row);
  let [{ loading }, actionReq] = useRequest(ADD_MAINTENANCE_ACTION, {
    method: "POST",
  });
  const [action, setAction] = useState("");
  const [hour, setHour] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [cost, setCost] = useState("");
  const [time, setTime] = useState(LOCAL_NOW);
  const [date, setDate] = useState(LOCAL_NOW);

  function hourHandle(e) {
    setHour(e.target.value);
  }

  function minutesHandle(e) {
    setMinutes(e.target.value);
  }
  function costHandle(e) {
    setCost(e.target.value);
  }

  function clickHandle() {
    actionReq({
      alertId: row?.id,
      siteId: row?.siteId,
      action,
      cost,
      maintenanceTime: hour + ":" + minutes,
      actionDateTime: moment(date)
        .hours(moment(time).hours())
        .minutes(moment(time).minutes())
        .format(),
    }).then(() => {
      closeModal();
    });
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.modal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.modal_header}
          >
            <div className={classes.title}>Action</div>
            <div>
              <ButtonBase onClick={closeModal}>
                <CloseIcon />
              </ButtonBase>
            </div>
          </Stack>
          <div className={classes.modal_body}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SelectBox
                    label="Action"
                    Icon={ActionIcon}
                    selected={action}
                    setSelected={setAction}
                    placeholder="Choose Your Action"
                    items={["Fixed", "Replaced"]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PrimaryInput
                    label="Duration (Hour)"
                    Icon={ClockArrow}
                    value={hour}
                    onChange={hourHandle}
                    placeholder="Enter Alert Condition"
                  />
                </Grid>
                <Grid item xs={4}>
                  <PrimaryInput
                    label="Duration (Minutes)"
                    Icon={ClockArrow}
                    value={minutes}
                    onChange={minutesHandle}
                    placeholder="Enter Alert Condition"
                  />
                </Grid>
                <Grid item xs={4}>
                  <PrimaryInput
                    label="Cost (CAD)"
                    Icon={DollarIcon}
                    value={cost}
                    onChange={costHandle}
                    placeholder="Enter Alert Condition"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <FormLabel sx={{ pb: 1, fontSize: "12px", color: "black" }}>
                      Date :
                    </FormLabel>
                    <DatePicker
                      value={date}
                      onChange={setDate}
                      className={classes.input}
                      size="small"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <FormLabel sx={{ pb: 1, fontSize: "12px", color: "black" }}>
                      Time :
                    </FormLabel>
                    <TimePicker
                      value={time}
                      onChange={setTime}
                      className={classes.input}
                      size="small"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <ButtonRow justifyContent="flex-end" mt={4}>
                <Button
                  loading={loading}
                  onClick={clickHandle}
                  variant="contained"
                >
                  Save
                </Button>
              </ButtonRow>
            </LocalizationProvider>
          </div>
        </div>
      </Modal>
    </div>
  );
}
