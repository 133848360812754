import { Container, Divider, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import MailIcon from "../../assets/icons/MailIcon";
import UserIcon from "../../assets/icons/UserIcon";
import UserTagIcon from "../../assets/icons/UserTagIcon";
import Button from "../form/button/Button";
import PrimaryInput from "../form/inputs/primaryInput/PrimaryInput";
import SelectBox from "../form/selectBox/SelectBox";
import ButtonRow from "../form/button/ButtonRow";
import classes from "./Style.module.scss";
import Item from "./Item";
import LocationInput2 from "../../assets/icons/LocationInput2";
import { useRequest } from "../../api/hooks";
import { SITE, USERS } from "../../api/endpoints";
import { toast } from "react-hot-toast";
import DeleteModal from "../DeleteModal/DeleteModal";

function AddAndEditUser({ type = "add" }) {
  // useRequest------------------------------
  let [{ data: users, loading: usersLoading }, fetchUsers] = useRequest(USERS);
  let [{ data: sites, loading: siteLoading }] = useRequest(SITE);

  // useState--------------------------------
  const [sitesAsObserver, setSitesAsObserver] = useState([]);
  const [sitesAsOperator, setSitesAsOperator] = useState([]);
  const [user, setUser] = useState(null);
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    isAdmin: false,
  });

  let [{ loading: addLoading }, addRequest] = useRequest(USERS, {
    method: "POST",
  });

  let [{ loading: updateLoading }, updateRequest] = useRequest(
    USERS + "/" + user?.id,
    { method: "PUT" }
  );

  //------------------------------------------------
  let [{ loading: deleteLoading }, deleteUser] = useRequest(
    USERS + "/" + user?.id,
    {
      method: "DELETE",
    }
  );

  //------------------------------------------------
  useEffect(() => {
    if (user) {
      setFormValues({
        fullName: user?.fullName,
        email: user?.email,
        isAdmin: user?.isAdmin,
      });
      let asOperatorList = [];
      let asObserverList = [];
      let asOperator = user?.operatorInSiteIds;
      let asObserver = user?.observerInSiteIds;
      for (let i = 0; i < asOperator?.length; i++) {
        let find = sites?.find(
          (item) => Number(item?.id) === Number(asOperator[i])
        );
        if (find) {
          asOperatorList.push(find);
        }
      }
      for (let ii = 0; ii < asObserver?.length; ii++) {
        let find = sites?.find(
          (item) => Number(item?.id) === Number(asObserver[ii])
        );
        if (find) {
          asObserverList.push(find);
        }
      }
      setSitesAsObserver([...asObserverList]);
      setSitesAsOperator([...asOperatorList]);
    }
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  function sitesAsObserverHandle(site) {
    let list = [...sitesAsObserver];
    if (!list?.includes(site)) {
      list?.push(site);
      setSitesAsObserver([...list]);
    }
  }

  function sitesAsOperatorHandle(site) {
    let list = [...sitesAsOperator];
    if (!list?.includes(site)) {
      list?.push(site);
      setSitesAsOperator([...list]);
    }
  }

  function deleteSiteAsObserver(site) {
    let list = [...sitesAsObserver];
    let newList = list?.filter((i) => i?.id !== site?.id);
    setSitesAsObserver([...newList]);
  }

  function deleteSiteAsOperator(site) {
    let list = [...sitesAsOperator];
    let newList = list?.filter((i) => i?.id !== site?.id);
    setSitesAsOperator([...newList]);
  }

  function saveHandle() {
    let observerList = sitesAsObserver?.map((item) => item?.id);
    let operatorList = sitesAsOperator?.map((item) => item?.id);
    if (type === "add") {
      addRequest({
        user: {
          ...formValues,
        },
        observerOnTheseSites: observerList,
        operatorOnTheseSites: operatorList,
      })
        .then(() => {
          toast?.success("User Added Successful");
          reset();
        })
        .catch(() => {
          toast?.error("Error");
        });
    } else {
      let objData = {
        user: {
          ...formValues,
          id: user?.id,
        },
        observerOnTheseSites: observerList,
        operatorOnTheseSites: operatorList,
      };

      updateRequest({
        ...objData,
      })
        .then(() => {
          toast?.success("User Updated Successful");
          reset();
        })
        .catch(() => {
          toast?.error("Error");
        });
    }
  }

  function reset() {
    setUser(null);
    setFormValues({
      fullName: "",
      email: "",
      isAdmin: false,
    });
    setSitesAsObserver([]);
    setSitesAsOperator([]);
    fetchUsers();
  }

  function DeleteRequest() {
    deleteUser()
      .then(() => {
        toast?.success("User Deleted Successful");
        fetchUsers();
        reset();
      })
      .catch(() => {
        toast?.error("Error");
      });
  }

  return (
    <div className={classes.container}>
      <Stack
        direction="row"
        justifyContent="space-between"
        className={classes.header}
      >
        <div className={classes.title}>
          {type === "add" ? "Add New User" : `Edit User Information`}
        </div>
      </Stack>
      <div>Complete the following information and click on the Add button.</div>
      <br />
      <br />
      {type === "edit" && (
        <>
          <SelectBox
            label="Users"
            Icon={UserIcon}
            placeholder="Select an User to Edit"
            items={users}
            optionPropertyName="fullName"
            optionValuePropertyName="id"
            setSelectedObject={setUser}
            selected={user}
            style={{ width: "35%" }}
            loading={usersLoading}
          />
          <Divider sx={{ my: "45px" }} />
        </>
      )}

      <div>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <PrimaryInput
                label="Full Name"
                name="fullName"
                Icon={UserIcon}
                value={formValues?.fullName}
                onChange={handleChange}
                placeholder="Enter Full Name"
                disabled={type === "edit" && !user}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PrimaryInput
                label="Email"
                Icon={MailIcon}
                value={formValues?.email}
                name="email"
                onChange={handleChange}
                placeholder="Enter User Email"
                disabled={type === "edit" && !user}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectBox
                label="Job Rank"
                Icon={UserTagIcon}
                selected={formValues.isAdmin ? "admin" : "operator"}
                setSelected={(value) => {
                  setFormValues({
                    ...formValues,
                    isAdmin: value === "admin" ? true : false,
                  });
                }}
                placeholder="Select Job Rank"
                disabled={type === "edit" && !user}
                items={["admin", "operator"]}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Grid container spacing={2} mt={4}>
        <Grid item md={6} xs={12}>
          <div className={classes.box_container}>
            <div>Assigned Sites as an Operator</div>
            <br />
            <SelectBox
              Icon={LocationInput2}
              setSelected={sitesAsOperatorHandle}
              placeholder="Select a Site to Assigned as an Operator"
              loading={siteLoading}
              optionPropertyName="siteName"
              optionValuePropertyName="id"
              setSelectedObject={sitesAsOperatorHandle}
              items={sites}
            />
            <Divider sx={{ mt: "25px", mb: "25px" }} />
            <div className={classes.items_container}>
              {sitesAsOperator?.map((item) => {
                return (
                  <Item
                    site={item}
                    deleteSiteAsOperator={deleteSiteAsOperator}
                  />
                );
              })}
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.box_container}>
            <div>Assigned Sites as an Observer</div>
            <br />
            <SelectBox
              Icon={LocationInput2}
              loading={siteLoading}
              setSelected={sitesAsObserverHandle}
              placeholder="Select a Site to Assigned as an Operator"
              optionPropertyName="siteName"
              optionValuePropertyName="id"
              setSelectedObject={sitesAsObserverHandle}
              items={sites}
            />
            <Divider sx={{ mt: "25px", mb: "25px" }} />
            <div className={classes.items_container}>
              {sitesAsObserver?.map((item) => {
                return (
                  <Item
                    site={item}
                    deleteSiteAsObserver={deleteSiteAsObserver}
                  />
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>

      <ButtonRow justifyContent="flex-end" spacing={2}>
        {type === "edit" && (
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <DeleteModal
              disabled={type === "edit" && !user}
              onClick={DeleteRequest}
              deleteLoading={deleteLoading}
            >
              Delete user
            </DeleteModal>
          </div>
        )}

        <Button onClick={reset} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={saveHandle}
          variant="contained"
          loading={addLoading || updateLoading}
          disabled={type === "edit" && !user}
        >
          {type === "add" ? "Add New User" : "Edit User"}
        </Button>
      </ButtonRow>
    </div>
  );
}

export default AddAndEditUser;
