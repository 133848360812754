import { CircularProgress, ClickAwayListener, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useResizeDetector } from "react-resize-detector";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SENSORS, SITE } from "../../../../api/endpoints";
import { useRequest } from "../../../../api/hooks";
import DeleteIcon from "../../../../assets/icons/DeleteIcon2";
import EditSensorIcon from "../../../../assets/icons/EditSensorIcon";
import SensorPointIcon from "../../../../assets/icons/SensorPointIcon";
import FullScreen from "../../../../assets/icons/FullScreen";
import SignalIcon from "../../../../assets/icons/SignalIcon";
import Button from "../../../form/button/Button";
import ButtonRow from "../../../form/button/ButtonRow";
import NextButton from "../../../form/button/NextButton";
import Sensor from "../../../sensor/Sensor";
import Success from "../success/Success";
import NewSensorModal from "./EditSensorModal";
import classes from "./Styles.module.scss";
import PrismaZoom from "react-prismazoom";
import ZoomIn from "../../../../assets/icons/ZoomIn";
import ZoomOut from "../../../../assets/icons/ZoomOut";

function Sensors() {
  let { siteID } = useParams();
  let navigate = useNavigate();
  let { pathname } = useLocation();
  let addMode = pathname?.includes("add");

  let [{ data: site, loading: siteLoading }] = useRequest(SITE + "/" + siteID, {
    method: "GET",
    autoFetch: true,
  });

  let [, updateSensorReq] = useRequest(SENSORS, {
    method: "PUT",
    autoFetch: false,
  });

  const [sensorForAction, setSensorForAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [allSensors, setAllSensors] = useState([]);
  const [assignedSensors, setAssignedSensors] = useState([]);
  const [notAssignedSensors, setNotAssignedSensors] = useState([]);
  const [listPosition, setListPosition] = useState();
  const [success, setSuccess] = useState(false);

  const { width, height, ref } = useResizeDetector();
  const [containerWidth, setWidth] = useState(width);
  const [containerHeight, setHeight] = useState(height);
  const [isFullScreen, setIsFullScreen] = useState(false);

  let image_ref = useRef();
  let container_ref = useRef();
  let box_container = useRef();

  useEffect(() => {
    if (box_container.current) {
      if (isFullScreen === true) {
        box_container.current?.requestFullscreen();
      } else if (isFullScreen === false) {
        document?.exitFullscreen();
      }
    }
  }, [isFullScreen]);

  function fullscreenHandle() {
    setIsFullScreen(!isFullScreen);
  }

  useEffect(() => {
    if (site?.sensors) {
      setAllSensors(site?.sensors);
    }
  }, [site]);

  useEffect(() => {
    if (allSensors) {
      let assigned = [];
      let notAssigned = [];
      allSensors?.map((item) => {
        if (item?.fromTop === null || item?.fromLeft === null) {
          notAssigned.push(item);
        } else {
          assigned.push(item);
        }
      });
      setAssignedSensors([...assigned]);
      setNotAssignedSensors([...notAssigned]);
    }
  }, [allSensors]);

  function onContextMenuHandle(e) {
    e.preventDefault();

    if (e.target !== image_ref.current) {
      return;
    }
    let positionX = e.clientX - ref.current.getBoundingClientRect().left;
    let positionY = e.clientY - ref.current.getBoundingClientRect().top;
    let percentX = Math.round((100 * positionX) / width);
    let percentY = Math.round((100 * positionY) / height);

    setListPosition([percentX, percentY]);
  }

  function setSensorHandle(s) {
    updateSensorHandle(s, {
      fromLeft: listPosition?.[0],
      fromTop: listPosition?.[1],
    });
  }

  function updateSensorInModalHandle(s) {
    let list = [...allSensors];
    let find = list?.find((item) => item?.id === s?.id);
    let index = list?.indexOf(find);
    list[index] = {
      ...s,
    };
    setAllSensors([...list]);
  }

  function updateSensorHandle(sensor, newData) {
    updateSensorReq(null, null, {
      url: SENSORS + "/" + sensor?.id,
      method: "PUT",
      data: {
        ...sensor,
        ...newData,
      },
    }).then(() => {
      let list = [...allSensors];
      let find = list?.find((item) => item?.id === sensor?.id);
      let index = list?.indexOf(find);
      list[index] = {
        ...sensor,
        ...newData,
      };
      setAllSensors([...list]);
      setListPosition(null);
    });
  }
  function deleteHandle(s) {
    let newList = allSensors?.filter((item) => item?.id !== s?.id);
    setAllSensors([...newList]);
  }

  const closeMenu = () => {
    setSensorForAction(null);
  };

  const onEdit = () => {
    openModalHandle();
  };

  const onDelete = () => {
    updateSensorReq(null, null, {
      method: "DELETE",
      url: SENSORS + "/" + sensorForAction?.id,
    }).then(() => {
      deleteHandle(sensorForAction);
      setSensorForAction(null);
    });
  };

  function closeModalHandle() {
    setOpenModal(false);
    setSensorForAction(null);
  }

  function openModalHandle() {
    setOpenModal(true);
  }

  function nextHandle() {
    setSuccess(true);
  }
  function noPhotoHandle() {
    navigate(
      addMode ? `/add-site/${siteID}/image` : `/edit-sites/${siteID}/image`
    );
  }

  function zoomIn(e) {
    e.stopPropagation();
    e.preventDefault();
    let newWidth = width + 80;
    let newHeight = width + 80;
    if (newWidth >= container_ref?.current?.offsetWidth) {
      setWidth(newWidth);
      setHeight(newHeight);
    }
  }

  function zoomOut(e) {
    e.stopPropagation();
    e.preventDefault();
    let newWidth = width - 80;
    let newHeight = width - 80;
    if (newWidth >= container_ref?.current?.offsetWidth - 40) {
      setWidth(newWidth);
      setHeight(newHeight);
    }
  }

  // function zoomControl(e) { //this is for zoom control by wheel
  //   e.target.scrolling = "no";
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (e?.shiftKey) {
  //     return;
  //   }

  //   if (e.deltaY < 0) {
  //     // wheel up
  //     zoomIn();
  //   } else if (e.deltaY > 0) {
  //     //wheel down
  //     zoomOut();
  //   }
  // }

  if (success) {
    return <Success addMode={addMode} site={site} />;
  }

  return (
    <div>
      <div>4. Add your sensors and click on the Next button.</div>
      <br />
      <br />
      <Stack
        justifyContent="center"
        alignItems="center"
        className={classes.sensor_image_container}
        ref={box_container}
      >
        <FullScreen
          className={classes.full_screen}
          onClick={fullscreenHandle}
        />
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          className={classes.zoom_control}
        >
          <ZoomIn onClick={zoomIn} />
          <ZoomOut
            onClick={zoomOut}
            color={
              width <= container_ref?.current?.offsetWidth
                ? "#aeaeae"
                : "#21446c"
            }
          />
        </Stack>
        {site?.picture ? (
          <div
            style={{ width: "80%", height: "100%", overflow: "auto" }}
            ref={container_ref}
            onWheel={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className={classes.image_container}
              ref={ref}
              // onWheel={zoomControl}
              onContextMenu={onContextMenuHandle}
              style={{ width: containerWidth, height: containerHeight }}
            >
              <img src={site?.picture} alt="site img" ref={image_ref} />
              {/* =============== Assigned Sensors =============== */}
              {assignedSensors?.map((item) => {
                return (
                  <AssignedSensor
                    parentHeight={height}
                    parentWidth={width}
                    sensor={item}
                    parentRef={ref?.current}
                    setSensorForAction={setSensorForAction}
                    updateSensorReq={updateSensorReq}
                    allSensors={allSensors}
                    setAllSensors={setAllSensors}
                  />
                );
              })}

              {/* =============== Not Assigned Sensors =============== */}

              {listPosition && (
                <>
                  <SensorPointIcon
                    style={{
                      zIndex: 2,
                      position: "absolute",
                      left: `calc(${listPosition[0] + "%"} - 12px)`,
                      top: `calc(${listPosition[1] + "%"} - 12px)`,
                    }}
                  />
                  <ClickAwayListener
                    onClickAway={() => {
                      setListPosition(null);
                    }}
                  >
                    <div
                      className={classes.not_assigned_list}
                      style={{
                        left: listPosition[0] + "%",
                        top: listPosition[1] + "%",
                      }}
                    >
                      {notAssignedSensors?.length === 0 && (
                        <div style={{ padding: 10 }}>No sensor found</div>
                      )}
                      {notAssignedSensors?.map((item) => {
                        return (
                          <div
                            className={classes.not_assigned_item}
                            onClick={() => {
                              setSensorHandle(item);
                            }}
                          >
                            <div>
                              <SignalIcon color="#000" />
                            </div>
                            &nbsp; &nbsp;
                            <div>{item?.sensorCode}</div>
                          </div>
                        );
                      })}
                    </div>
                  </ClickAwayListener>
                </>
              )}

              {/* =============== Action Menu =============== */}
              {sensorForAction && (
                <ClickAwayListener onClickAway={closeMenu}>
                  <div
                    className={classes.sensor_clicked_menu}
                    style={{
                      zIndex: 1,
                      left: sensorForAction?.fromLeft + "%",
                      top: sensorForAction?.fromTop + "%",
                    }}
                  >
                    <div
                      className={classes.sensor_clicked_menu_item}
                      onClick={onEdit}
                    >
                      <div>
                        <EditSensorIcon />
                      </div>
                      &nbsp; &nbsp;
                      <div>Edit Information</div>
                    </div>
                    <div
                      className={classes.sensor_clicked_menu_item}
                      onClick={onDelete}
                    >
                      <div>
                        <DeleteIcon color="red" />
                      </div>
                      &nbsp; &nbsp;
                      <div style={{ color: "red" }}>Delete Sensor</div>
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
        ) : siteLoading ? (
          <CircularProgress />
        ) : (
          <>
            No photo has been uploaded for this site
            <Button onClick={noPhotoHandle} sx={{ marginTop: 4 }}>
              Upload Picture
            </Button>
          </>
        )}
      </Stack>
      <NewSensorModal
        sensor={sensorForAction}
        open={openModal}
        closeModal={closeModalHandle}
        setSensor={updateSensorInModalHandle}
      />
      <ButtonRow justifyContent="flex-end">
        <NextButton onClick={nextHandle} />
      </ButtonRow>
    </div>
  );
}

function AssignedSensor({
  parentWidth,
  parentHeight,
  sensor,
  setSensorForAction,
  updateSensorReq,
  allSensors,
  setAllSensors,
}) {
  const sensorContextMenuHandle = () => {
    setSensorForAction(sensor);
  };
  console.log(sensor);

  const dragStopHandle = (e, data) => {
    let transformX = Math.round((100 * data?.x) / parentWidth);
    let transformY = Math.round((100 * data?.y) / parentHeight);
    // let list = [...allSensors];
    // let find = list?.find((item) => item?.id === sensor?.id);
    // let index = list?.indexOf(find);
    // list[index] = {
    //   ...sensor,
    //   fromTop: sensor?.fromTop + transformY,
    //   fromLeft: sensor?.fromLeft + transformX,
    // };
    // setAllSensors([...list]);
    updateSensorReq(null, null, {
      url: SENSORS + "/" + sensor?.id,
      method: "PUT",
      data: {
        ...sensor,
        fromTop: sensor?.fromTop + transformY,
        fromLeft: sensor?.fromLeft + transformX,
      },
    });
  };

  return (
    <Draggable bounds="parent" onStop={dragStopHandle}>
      <div
        aria-describedby={"sensor" + sensor?.id}
        onContextMenu={sensorContextMenuHandle}
        style={{
          position: "absolute",
          left: sensor?.fromLeft + "%",
          top: sensor?.fromTop + "%",
          cursor: "move",
          transform: "scale(0) !important",
        }}
      >
        <div style={{ position: "relative" }}>
          <Sensor
            key={"abc" + sensor?.id}
            name={sensor?.sensorCode}
            value={sensor?.sensorValue}
            status={sensor?.status}
            top={sensor?.fromTop}
            left={sensor?.fromLeft}
          />
        </div>
      </div>
    </Draggable>
  );
}

export default Sensors;
