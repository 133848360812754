import React from "react";

function LittleLocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.203"
      height={12}
      viewBox="0 0 10.203 12"
    >
      <path
        id="icons8_Location"
        d="M12.1,4a5.1,5.1,0,0,0-3.878,8.412l0,0,0,0s2.17,2.476,2.943,3.214a1.355,1.355,0,0,0,1.861,0c.881-.84,2.944-3.215,2.944-3.215v0l0,0A5.1,5.1,0,0,0,12.1,4Zm0,.9a4.2,4.2,0,0,1,3.193,6.927s-2.115,2.419-2.884,3.152a.439.439,0,0,1-.618,0c-.642-.613-2.877-3.145-2.884-3.152h0A4.2,4.2,0,0,1,12.1,4.9Zm0,2.4a1.787,1.787,0,0,0-1.349.573,1.894,1.894,0,0,0,0,2.455,1.874,1.874,0,0,0,2.7,0,1.894,1.894,0,0,0,0-2.455A1.787,1.787,0,0,0,12.1,7.3Zm0,.9a.83.83,0,0,1,.676.271,1,1,0,0,1,0,1.259A.83.83,0,0,1,12.1,10a.83.83,0,0,1-.676-.271,1,1,0,0,1,0-1.259A.83.83,0,0,1,12.1,8.2Z"
        transform="translate(-7 -4)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default LittleLocationIcon;
