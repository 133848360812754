import React from "react";

function ChartChipIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.844"
      height="7.006"
      viewBox="0 0 12.844 7.006"
    >
      <path
        id="svgexport-6"
        d="M13.844,7.168a1.171,1.171,0,0,1-1.168,1.168.992.992,0,0,1-.3-.041L10.3,10.367a1.031,1.031,0,0,1,.041.3,1.168,1.168,0,1,1-2.335,0,1.031,1.031,0,0,1,.041-.3L6.558,8.878a1.148,1.148,0,0,1-.607,0L3.294,11.54a.992.992,0,0,1,.041.3A1.168,1.168,0,1,1,2.168,10.67a.992.992,0,0,1,.3.041L5.128,8.055a1.031,1.031,0,0,1-.041-.3,1.168,1.168,0,0,1,2.335,0,1.031,1.031,0,0,1-.041.3L8.87,9.544a1.148,1.148,0,0,1,.607,0l2.073-2.078a.992.992,0,0,1-.041-.3,1.168,1.168,0,1,1,2.335,0Z"
        transform="translate(-1 -6)"
        fill="#484848"
      />
    </svg>
  );
}

export default ChartChipIcon;
