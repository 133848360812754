import React from "react";
import classes from "./Sensor.module.scss";
import OKSensorIcon from "../../assets/icons/OKSensorIcon";
import AlertSensorIcon from "../../assets/icons/AlertSensorIcon";
import { Tooltip } from "@mui/material";
function Sensor({ status, name, value, top, left, unit, ...props }) {
  return (
    <Tooltip title={name} placement="top">
      <div
        style={{ top: top + "%", left: left + "%" }}
        className={classes.point}
        {...props}
      >
        <div className={classes.sensor_container}>
          <div
            className={classes.sensor}
            style={{
              background: status && status !== "OK" ? "#FF2A2A" : "#0d9aab",
            }}
          >
            {!status ? null : status === "OK" ? (
              <OKSensorIcon width="0.7vw" height="0.7vw" />
            ) : (
              <AlertSensorIcon width="0.7vw" height="0.7vw" />
            )}
            &nbsp;&nbsp;
            <div>
              {value ? (
                Number(value).toFixed(2)
              ) : (
                <span style={{ padding: "0.4vw", fontSize: "0.7vw" }}>-</span>
              )}
            </div>
            &nbsp;&nbsp;
            <div> {value ? unit : null}</div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
}

export default Sensor;
