import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import classNames from "classnames";
import React from "react";
import Label from "../../label/Label";
import classes from "../inputsStyle.module.scss";

export default function PrimaryInput({ Icon, label, required, ...props }) {
  return (
    <div style={{ width: "100%" }}>
      {label && <Label required={required}>{label}</Label>}
      <OutlinedInput
        className={classNames(classes.input, {
          [classes.disabled]: props.disabled,
        })}
        fullWidth
        startAdornment={
          <InputAdornment position="start">
            <Icon
              style={{
                width: " 1.46vw",
                height: " 1.46vw",
                minWidth: "15px",
                minHeight: " 15px",
              }}
            />
          </InputAdornment>
        }
        {...props}
      />
    </div>
  );
}
