import React from "react";

function MenuSensorsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.714"
      height={18}
      viewBox="0 0 13.714 18"
    >
      <path
        id="icons8_tree_structure"
        d="M9.5,3A1.51,1.51,0,0,0,8,4.5V7.071a1.51,1.51,0,0,0,1.5,1.5h1.071v8.786a1.51,1.51,0,0,0,1.5,1.5h3.214V19.5a1.51,1.51,0,0,0,1.5,1.5h3.429a1.51,1.51,0,0,0,1.5-1.5V16.929a1.51,1.51,0,0,0-1.5-1.5H16.786a1.51,1.51,0,0,0-1.5,1.5v.643H12.071a.2.2,0,0,1-.214-.214V12h3.429v.643a1.51,1.51,0,0,0,1.5,1.5h3.429a1.51,1.51,0,0,0,1.5-1.5V10.071a1.51,1.51,0,0,0-1.5-1.5H16.786a1.51,1.51,0,0,0-1.5,1.5v.643H11.857V8.571h1.071a1.51,1.51,0,0,0,1.5-1.5V4.5a1.51,1.51,0,0,0-1.5-1.5Zm0,1.286h3.429a.2.2,0,0,1,.214.214V7.071a.2.2,0,0,1-.214.214H11.32a.643.643,0,0,0-.208,0H9.5a.2.2,0,0,1-.214-.214V4.5A.2.2,0,0,1,9.5,4.286Zm7.286,5.571h3.429a.2.2,0,0,1,.214.214v2.571a.2.2,0,0,1-.214.214H16.786a.2.2,0,0,1-.214-.214v-1.18a.643.643,0,0,0,0-.208V10.071A.2.2,0,0,1,16.786,9.857Zm0,6.857h3.429a.2.2,0,0,1,.214.214V19.5a.2.2,0,0,1-.214.214H16.786a.2.2,0,0,1-.214-.214V18.32a.643.643,0,0,0,0-.208V16.929A.2.2,0,0,1,16.786,16.714Z"
        transform="translate(-8 -3)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default MenuSensorsIcon;
