import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import React from "react";
import { NextArrow, PrevArrow } from "../sliderComponents/Arrows";
import Item from "./Item";
import { useNavigate, useParams } from "react-router-dom";

export default function City({ setCity, cities }) {
  let { city } = useParams();
  let navigate = useNavigate();

  return (
    <div
      style={{
        position: "relative",
        top: "-15px",
        left: "-15px",
      }}
    >
      <ScrollingCarousel
        swiping={true}
        slide={2}
        leftArrow={PrevArrow}
        rightArrow={NextArrow}
      >
        {cities?.map((item) => {
          return (
            <Item
              key={item}
              city={item}
              active={city}
              onClick={() => {
                if (setCity) {
                  setCity(item);
                } else {
                  navigate(item);
                }
              }}
            />
          );
        })}
      </ScrollingCarousel>
    </div>
  );
}
