import React from "react";
import ApexChart from "react-apexcharts";
import chart from "apexcharts";
import ExcelIcon from "../../assets/icons/ExcelIcon";
import { Stack } from "@mui/material";
import classes from "./Chart.module.scss";
import { ExportToCsv } from "export-to-csv";

function Chart({
  series,
  title = "Chlorine Management Unit",
  lineColor = "#54D2BC",
  areaColor = "#54D2BC",
  showYLabels = true,
}) {
  let options = {
    legend: {
      show: true,
      position: "bottom",
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor: "#DFDFDF",
      strokeDashArray: 7,
      row: {
        colors: ["#fff"],
        opacity: 0.5,
      },
    },
    colors: [lineColor],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    yaxis: {
      labels: {
        show: showYLabels,

        style: {
          color: "#8F8F8F",
          cssClass: "chart_labels",
        },
        formatter: function (val) {
          return val?.toFixed(2);
        },
      },
      tickAmount: 3,
      decimalsInFloat: 0,
      min: 0,
      // title: {
      //   text: title,
      //   style: {
      //     color: "#214663",
      //     fontWeight: 200,
      //     margin: 13,
      //   },
      //},
      categories: [0, 100, 200, 300],
    },
    fill: {
      type: "gradient",
      gradient: {
        colors: [areaColor],
        stops: [64],
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "d MMM hh:mm",
        style: {
          color: "#8F8F8F",
          cssClass: "chart_labels",
        },
        rotate: -45,
        minHeight: 70,
        rotateAlways: true,
        datetimeUTC: false,
      },
    },

    tooltip: {
      x: {
        format: "MMM d, yyyy  hh:mm:ss TT",
      },
      y: {
        formatter: undefined,
        title: {
          formatter: () => "",
        },
      },
    },
    title: {
      text: undefined,
    },
    markers: {
      size: 4,
    },
  };
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: title,
    Headers: ["DateTime", "Value"],
  };
  const csvExporter = new ExportToCsv(csvOptions);

  function exportToExcelHandle() {
    if (series?.[0]) {
      csvExporter.generateCsv(series?.[0]?.data);
    }
  }
  return (
    <>
      <div className="chart">
        <ApexChart height={400} options={options} series={series} type="area" />
      </div>
      <Stack
        direction="row"
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ cursor: "pointer" }}
      >
        <div className={classes.title}>{title}</div>
        <div onClick={exportToExcelHandle} className={classes.button}>
          <ExcelIcon /> &nbsp; Excel Export
        </div>
      </Stack>
    </>
  );
}

export default Chart;
