import L from "leaflet";
import React, { useEffect, useState } from "react";
import {
  LayersControl,
  MapContainer,
  Marker,
  TileLayer,
  useMap,
} from "react-leaflet";
import LocationIcon from "../../../assets/images/setMarker.png";
import { useMapEvent } from "react-leaflet/hooks";

import { useNavigate, useParams } from "react-router-dom";
import ButtonRow from "../../form/button/ButtonRow";
import NextButton from "../../form/button/NextButton";

const marker = new L.Icon({
  iconUrl: LocationIcon,
  iconRetinaUrl: LocationIcon,
  iconAnchor: [41, 65],
  iconSize: [85, 95],
});

function FlyToSite({ location }) {
  const map = useMap();
  if (location) map.flyTo(location, 14);
}

function Location() {
  let { lat, lng, siteID } = useParams();
  let navigate = useNavigate();

  const [position, setPosition] = useState();
  let MAP_CENTER = [49.2827, -123.1207];
  let { BaseLayer } = LayersControl;

  useEffect(() => {
    if (lat && lng) {
      setPosition([lat, lng]);
    }
  }, [lat, lng]);

  function ClickHandle() {
    useMapEvent("click", (e) => {
      setPosition(e.latlng);
    });
    return null;
  }

  function nextHandle() {
    navigate(
      `/edit-sites/${position?.lat ?? lat}/${
        position?.lng ?? lng
      }/${siteID}/information`
    );
  }

  return (
    <div>
      <div>
        1. Choose your location in the map and click in the Next button.
      </div>
      <br />
      <br />
      <MapContainer
        center={MAP_CENTER}
        zoom={10}
        scrollWheelZoom={true}
        style={{ height: "600px", width: "100%" }}
        zoomControl={false}
      >
        <LayersControl>
          <BaseLayer checked name="Google Map">
            <TileLayer
              url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
          </BaseLayer>
          <BaseLayer name="OpenStreetMap">
            <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </BaseLayer>
          <BaseLayer name="OpenTopoMap">
            <TileLayer url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png" />
          </BaseLayer>
          <BaseLayer name="satellite">
            <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          </BaseLayer>
        </LayersControl>
        <FlyToSite location={position} />
        <ClickHandle />

        {position && <Marker icon={marker} position={position} />}
      </MapContainer>
      <ButtonRow justifyContent="flex-end">
        <NextButton disabled={lat && lng ? false : true} onClick={nextHandle} />
      </ButtonRow>
    </div>
  );
}

export default Location;
