import {
  ButtonBase,
  FormControl,
  FormLabel,
  Modal,
  Stack,
  Tooltip,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ExportToCsv } from "export-to-csv";
import React, { useState } from "react";
import DateFilter from "../../assets/icons/DateFilter";
import DownloadIcon from "../../assets/icons/DownloadIcon";
import PrintIcon from "../../assets/icons/PrintIcon";
import { LOCAL_NOW, SEVEN_DAYS_AGO, toLocalDateTime } from "../../utils/date";
import Button from "../form/button/Button";
import ButtonRow from "../form/button/ButtonRow";
import classes from "./Table.module.scss";

function TableHeader({
  startDate = SEVEN_DAYS_AGO,
  endDate = LOCAL_NOW,
  siteName,
  setStartDate,
  setEndDate,
  data,
  headers,
  refForPrint,
  filterDate = true,
  print = true,
  download = true,
  title = true,
}) {
  let [openDatePicker, setOpenDatePicker] = useState(false);
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers,
  };

  const csvExporter = new ExportToCsv(csvOptions);

  function openDatePickerHandler() {
    setOpenDatePicker(true);
  }

  function closeDatePickerHandler() {
    setOpenDatePicker(false);
  }

  function filterClickHandle() {
    setStartDate(start);
    setEndDate(end);
    closeDatePickerHandler();
  }
  function exportHandle() {
    if (data) {
      csvExporter.generateCsv(data);
    }
  }

  function printHandle() {
    if (refForPrint?.current) {
      let tdata = refForPrint?.current.innerHTML;
      let myWindow = window.open("", "my div", "height=400,width=600");
      myWindow.document.write(`<html><head> <style>@media print {
      .MuiTableSortLabel-icon ,.MuiSvgIcon-root  {
        display: none;
      }
    }</style><title>my div</title>`);
      /*optional stylesheet*/ //myWindow.document.write('<link rel="stylesheet" href="main.css" type="text/css" />');
      myWindow.document.write("</head><body >");
      myWindow.document.write(tdata);
      myWindow.document.write("</body></html>");
      myWindow.document.close(); // necessary for IE >= 10

      myWindow.onload = function () {
        // necessary if the div contain images

        myWindow.focus(); // necessary for IE >= 10
        myWindow.print();
        // myWindow.close();
      };
    }
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      className={classes.table_header}
      alignItems="center"
      sx={{ mb: 2 }}
    >
      <div className={classes.header}>
        {title && <>Table of Assessments for Site {siteName}</>}
      </div>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        className={classes.button_container}
      >
        {filterDate && (
          <Tooltip title="Filter">
            <ButtonBase
              onClick={openDatePickerHandler}
              className={classes.button}
            >
              <DateFilter />
            </ButtonBase>
          </Tooltip>
        )}
        {download && (
          <Tooltip title="Download">
            <ButtonBase onClick={exportHandle} className={classes.button}>
              <DownloadIcon />
            </ButtonBase>
          </Tooltip>
        )}
        {print && (
          <Tooltip title="Print">
            <ButtonBase onClick={printHandle} className={classes.button}>
              <PrintIcon />
            </ButtonBase>
          </Tooltip>
        )}
        {/* <Tooltip title="Full Screen">
          <ButtonBase className={classes.button}>
            <FullScreenTableIcon />
          </ButtonBase>
        </Tooltip> */}
        {/* <Tooltip title="">
          <InputBase
            placeholder="Search Here..."
            size="small"
            className={classes.search}
            startAdornment={
              <InputAdornment position="start">
                &nbsp; &nbsp;
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Tooltip> */}
      </Stack>
      <Modal open={openDatePicker} onClose={closeDatePickerHandler}>
        <div className={classes.datePicker_modal}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormControl>
              <FormLabel>From : </FormLabel>
              <DatePicker
                value={start}
                onChange={(date) => {
                  setStart(toLocalDateTime(date));
                }}
              />
            </FormControl>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <FormControl>
              <FormLabel>To : </FormLabel>
              <DatePicker
                value={end}
                onChange={(date) => {
                  setEnd(toLocalDateTime(date));
                }}
              />
            </FormControl>
          </LocalizationProvider>

          <ButtonRow justifyContent="flex-end">
            <Button onClick={filterClickHandle}>Filter</Button>
          </ButtonRow>
        </div>
      </Modal>
    </Stack>
  );
}

export default TableHeader;
