import React from "react";

function SignalIcon({ color = "#484848 ", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.793"
      height={11}
      viewBox="0 0 12.793 11"
      {...props}
    >
      <path
        id="icons8_rfid_signal_1"
        d="M12.592,5.978a.458.458,0,0,0-.222.85,5.459,5.459,0,0,1,0,9.294.458.458,0,1,0,.483.777,6.4,6.4,0,0,0,0-10.848.458.458,0,0,0-.261-.073Zm-6.291,0a.458.458,0,0,0-.247.073A6.4,6.4,0,0,0,6.071,16.9a.458.458,0,1,0,.483-.777A5.462,5.462,0,0,1,6.54,6.83.458.458,0,0,0,6.3,5.981Zm5.121,2.136a.458.458,0,0,0-.255.84,3.031,3.031,0,0,1,0,5.04.458.458,0,1,0,.513.758,3.971,3.971,0,0,0,0-6.557.458.458,0,0,0-.258-.082Zm-3.951,0a.458.458,0,0,0-.244.082,3.971,3.971,0,0,0,0,6.557A.458.458,0,1,0,7.741,14a3.03,3.03,0,0,1,0-5.04.458.458,0,0,0-.269-.84Zm1.981,2.136a1.221,1.221,0,1,0,1.221,1.221A1.221,1.221,0,0,0,9.453,10.257Z"
        transform="translate(-3.063 -5.978)"
        fill={color}
      />
    </svg>
  );
}

export default SignalIcon;
