import React from "react";
import Button from "../../components/form/button/Button";
import Dialog from "@mui/material/Dialog";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonMui from "@mui/material/Button";
import classes from "./DeletedModal.module.scss";

export default function DeleteModal({ onClick, deleteLoading, ...props }) {
  const [open, setOpen] = React.useState(false);

  const CloseClick = () => {
    setOpen(false);
  };

  const agreeClick = () => {
    onClick();
    CloseClick();
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="contained" {...props}>
        Delete USER
      </Button>
      <Dialog open={open} onClose={CloseClick}>
        <DialogTitle
          sx={{
            textAlign: "center",
            padding: "10px",
            borderBottom: "1.3px solid #21446c",
            marginBottom: "20px",
            fontSize: "15px",
            color: "red",
          }}
        >
          DELETE USER !
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center" }}>
            Are you sure about deleting the user?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: "auto" }}>
          <ButtonMui
            autoFocus
            variant="contained"
            onClick={CloseClick}
            className={classes.btns_deletedModal}
          >
            CANCEL
          </ButtonMui>
          &nbsp; &nbsp;
          <LoadingButton
            loading={deleteLoading}
            onClick={agreeClick}
            variant="contained"
            className={classes.btns_deletedModal}
          >
            OK
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
