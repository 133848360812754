import React from "react";

function ShutDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.991"
      height={16}
      viewBox="0 0 15.991 16"
    >
      <path
        id="icons8_shutdown"
        d="M10.985,2.986a.666.666,0,0,0-.656.675v7.329a.666.666,0,1,0,1.333,0V3.662a.666.666,0,0,0-.677-.675ZM7.661,3.8a.666.666,0,0,0-.3.077,8,8,0,1,0,7.269,0,.666.666,0,1,0-.606,1.187,6.663,6.663,0,1,1-6.056,0A.666.666,0,0,0,7.661,3.8Z"
        transform="translate(-3 -2.986)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default ShutDownIcon;
