import React from "react";

function RightArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11}
      height={10}
      viewBox="0 0 11 10"
    >
      <path
        id="Union_579"
        data-name="Union 579"
        d="M4.293.292l-4,4a1,1,0,0,0,0,1.415l4,4A1,1,0,0,0,5.707,8.293L3.414,6H10a1,1,0,1,0,0-2H3.415L5.707,1.708A1,1,0,0,0,4.293.292Z"
        transform="translate(11 10) rotate(180)"
        fill="#fff"
      />
    </svg>
  );
}

export default RightArrow;
