import { Grid, Stack } from "@mui/material";
import React from "react";
import { LocationIcon } from "../../assets/icons";
import classes from "./Style.module.scss";
import CloseIcon from "../../assets/icons/CloseIcon";
import LittleLocationIcon from "../../assets/icons/LittleLocationIcon";

function Item({ deleteSiteAsOperator, deleteSiteAsObserver, site }) {
  function deleteHandle() {
    if (deleteSiteAsOperator) {
      deleteSiteAsOperator(site);
    } else {
      deleteSiteAsObserver(site);
    }
  }

  return (
    <Grid container alignItems="center" p="14px" className={classes.item}>
      <Grid item xs={2}>
        <LocationIcon />
      </Grid>
      <Grid item xs={9}>
        <div className={classes.name}>{site?.siteName}</div>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          className={classes.info}
        >
          <LittleLocationIcon />
          <div>{site?.city}</div>
        </Stack>
      </Grid>
      <Grid item sx={{ cursor: "pointer" }} xs={1} onClick={deleteHandle}>
        <CloseIcon />
      </Grid>
    </Grid>
  );
}

export default Item;
