import React from "react";

function ClockArrow({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={15}
      viewBox="0 0 15 15"
      {...props}
    >
      <path
        id="icons8_clock_arrow"
        d="M11.5,4A7.509,7.509,0,0,0,4,11.5v.187a.563.563,0,1,0,1.125,0V11.5a6.375,6.375,0,1,1,12.75,0v.187a.563.563,0,1,0,1.125,0V11.5A7.509,7.509,0,0,0,11.5,4Zm-.2,2.992a.563.563,0,0,0-.554.571v4.125a.563.563,0,0,0,.562.562h3a.563.563,0,1,0,0-1.125H11.875V7.562a.563.563,0,0,0-.571-.571ZM5.025,13.368a.563.563,0,0,0-.534.771,7.49,7.49,0,0,0,13.384,1.311v1.113a.563.563,0,1,0,1.125,0V13.937a.563.563,0,0,0-.562-.562H15.812a.563.563,0,1,0,0,1.125h1.317q-.012.019-.023.038a6.367,6.367,0,0,1-11.564-.8A.563.563,0,0,0,5.025,13.368Z"
        transform="translate(-4 -4)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default ClockArrow;
