import { ButtonBase, FormControl, FormLabel, Grid, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
// import { GET_SENSORS_TYPE } from "../../../../api/endpoints";
// import { useRequest } from "../../../../api/hooks";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { toast } from "react-hot-toast";
import { ADD_MAINTENANCE_REFILL } from "../../../../../api/endpoints";
import { useRequest } from "../../../../../api/hooks";
import ClockArrow from "../../../../../assets/icons/ClockArrow";
import CloseIcon from "../../../../../assets/icons/CloseIcon";
import Button from "../../../../../components/form/button/Button";
import ButtonRow from "../../../../../components/form/button/ButtonRow";
import PrimaryInput from "../../../../../components/form/inputs/primaryInput/PrimaryInput";
import SelectBox from "../../../../../components/form/selectBox/SelectBox";
import classes from "./Styles.module.scss";
import { LOCAL_NOW } from "../../../../../utils/date";

let units = [
  { name: "Kilograms", value: "kg" },
  { name: "Pounds", value: "lb" },
  { name: "Liters", value: "l" },
  {
    name: "Cubic Meters (m^3)",
    value: "m^3",
  },
  { name: "Gallons", value: "gal" },
];

export default function Refill({ row, open, closeModal }) {
  const [weight, setWeight] = useState("");
  const [unit, setUnit] = useState(null);
  const [time, setTime] = useState(LOCAL_NOW);
  const [date, setDate] = useState(LOCAL_NOW);

  let [{ loading }, refillReq] = useRequest(ADD_MAINTENANCE_REFILL, {
    method: "POST",
  });

  function weightHandle(e) {
    setWeight(e.target.value);
  }
  function clickHandle() {
    if (!unit) {
      return toast.error("Unit is Required");
    }
    refillReq({
      alertId: row?.id,
      siteId: row?.siteId,
      weight,
      unit,
      refillDateTime: moment(date)
        .hours(moment(time).hours())
        .minutes(moment(time).minutes())
        .format(),
    }).then(() => {
      closeModal();
    });
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.modal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.modal_header}
          >
            <div className={classes.title}>Refill</div>
            <div>
              <ButtonBase onClick={closeModal}>
                <CloseIcon />
              </ButtonBase>
            </div>
          </Stack>
          <div className={classes.modal_body}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PrimaryInput
                    label="Weight/Volume"
                    Icon={ClockArrow}
                    value={weight}
                    onChange={weightHandle}
                    placeholder="Enter Weight/Volume"
                  />
                </Grid>

                <Grid item xs={6}>
                  <SelectBox
                    label="Unit"
                    Icon={ClockArrow}
                    selected={unit}
                    setSelected={setUnit}
                    items={units}
                    placeholder="Enter Unit"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel sx={{ pb: 1, fontSize: "12px", color: "black" }}>
                      Date :
                    </FormLabel>
                    <DatePicker
                      value={date}
                      onChange={setDate}
                      className={classes.input}
                      size="small"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel sx={{ pb: 1, fontSize: "12px", color: "black" }}>
                      Time :
                    </FormLabel>
                    <TimePicker
                      value={time}
                      onChange={setTime}
                      className={classes.input}
                      size="small"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <ButtonRow justifyContent="flex-end" mt={4}>
                <Button
                  loading={loading}
                  onClick={clickHandle}
                  variant="contained"
                >
                  Save
                </Button>
              </ButtonRow>
            </LocalizationProvider>
          </div>
        </div>
      </Modal>
    </div>
  );
}
