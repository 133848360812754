import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import { Link } from "react-router-dom";
import MenuLocationIcon from "../../assets/icons/MenuLocationIcon";
import MenuSensorsIcon from "../../assets/icons/MenuSensorsIcon";
import MenuSettingIcon from "../../assets/icons/MenuSettingIcon";
import classes from "./Sidebar.module.scss";
import ShutDownIcon from "../../assets/icons/ShutDownIcon";
import { signOut, useUserDispatch } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: "#9E9E9E",
    padding: "15px",
    borderRadius: "6px",
    width: "90%",
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingLeft: 0,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `#F3F5F7`,
      color: "#21446C",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    // borderLeft: `1px dashed black`,
    marginLeft: 36,
    paddingLeft: 0,
    position: "relative",

    // [`& .${treeItemClasses.content}::before`]: {
    //   // content: " ",

    // },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    subMenu,
    // count,
    link,
    ...other
  } = props;

  return (
    <Link to={link}>
      <StyledTreeItemRoot
        label={
          <Box sx={{ display: "flex", alignItems: "center", p: 0.5 }}>
            <Box component={LabelIcon} />
            {subMenu && <div className={classes.before_dashed} />}
            <Typography
              variant="body2"
              sx={{ fontWeight: "inherit", flexGrow: 1, pl: 1 }}
            >
              {labelText}
            </Typography>
            {/* {count && <Badge badgeContent={count} color="error" />} */}
          </Box>
        }
        {...other}
      />
    </Link>
  );
}

export default function Sidebar() {
  let navigate = useNavigate();
  let dispatch = useUserDispatch();
  const signOutHandle = () => {
    signOut(dispatch, navigate);
  };
  return (
    <div className={classes.sidebar}>
      <TreeView
        aria-label="gmail"
        defaultExpanded={["3"]}
        // defaultCollapseIcon={<ArrowDropDownIcon />}
        // defaultExpandIcon={<ArrowRightIcon />}
        // defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <StyledTreeItem
          nodeId="1"
          labelText="Explore All Sites"
          labelIcon={MenuLocationIcon}
          link="/"
        />
        <StyledTreeItem
          nodeId="2"
          labelText="System's Sensors"
          labelIcon={MenuSensorsIcon}
          // count={2}
          link="system"
        />
        <StyledTreeItem
          nodeId="3"
          labelText="Edit Information"
          labelIcon={MenuSettingIcon}
        >
          <StyledTreeItem
            subMenu={true}
            nodeId="5"
            labelText="Add New Site"
            link="add-site"
          />
          <StyledTreeItem
            subMenu={true}
            nodeId="6"
            labelText="Edit Sites"
            link="edit-sites"
          >
            <StyledTreeItem
              subMenu={true}
              nodeId="7"
              labelText="Edit Modules"
              link="edit-modules"
            />
            <StyledTreeItem
              subMenu={true}
              nodeId="8"
              labelText="Edit Locations"
              link="edit-Locations"
            />
          </StyledTreeItem>
          <StyledTreeItem
            subMenu={true}
            nodeId="9"
            labelText="Add New User"
            link="add-user"
          />
          <StyledTreeItem
            subMenu={true}
            nodeId="10"
            labelText="Edit Users"
            link="edit-users"
          />
        </StyledTreeItem>
      </TreeView>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 0.5,
          ml: 3.5,
          mt: 5,
          color: "#9E9E9E",
          cursor: "pointer",
        }}
        onClick={signOutHandle}
      >
        <Box component={ShutDownIcon} />
        <Typography
          variant="body2"
          sx={{ fontWeight: "inherit", flexGrow: 1, pl: 1 }}
        >
          Sign Out
        </Typography>
      </Box>
    </div>
  );
}
