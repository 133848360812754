import React from "react";

const DashedDivider = () => {
  return (
    <div
      style={{
        width: "100%",
        border: "1.3px dashed #DFDFDF",
        borderRadius: "1px",
        marginTop: "40px",
        marginBottom: "40px",
      }}
    />
  );
};

export default DashedDivider;
