import ArrowIcon from "../../../../assets/icons/ArrowIcon";
import classes from "../../SiteSelect.module.scss";

export function NextArrow(props) {
  return (
    <div className={classes.arrow} {...props}>
      <ArrowIcon color="white" />
    </div>
  );
}

export function PrevArrow(props) {
  return (
    <div className={classes.arrow} {...props}>
      <ArrowIcon color="white" style={{ transform: "rotate(-180deg)" }} />
    </div>
  );
}
