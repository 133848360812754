import { Breadcrumbs, ButtonBase, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { SITE_BY_CITY } from "../../api/endpoints";
import { useRequest } from "../../api/hooks";
import CitySelect from "../siteSelect/CitySelect";
import SiteSelect from "../siteSelect/SiteSelect";
import classes from "./Style.module.scss";

function EditSite() {
  let { pathname } = useLocation();
  let { siteID } = useParams();
  const [city, setCity] = useState();
  const [site, setSite] = useState();
  let navigate = useNavigate();

  let [{ data: sites, loading: siteLoading }, fetchSites] = useRequest(
    SITE_BY_CITY,
    {
      method: "POST",
      data: {
        city,
      },
      autoFetch: false,
    }
  );
  useEffect(() => {
    if (city) {
      fetchSites();
    }
  }, [city]);

  function setSiteHandle(selectedSite) {
    setSite(selectedSite);
    navigate(
      `${selectedSite?.locationY}/${selectedSite?.locationX}/${selectedSite?.id}/location`
    );
  }

  let isSelectSitePage = !siteID || pathname?.includes("location");

  return (
    <>
      {isSelectSitePage && (
        <>
          <CitySelect setCity={setCity} />
          <SiteSelect
            loading={siteLoading}
            sites={sites}
            setSite={setSiteHandle}
          />
        </>
      )}

      <div className={classes.container}>
        {siteID && (
          <Stack
            direction="row"
            justifyContent="space-between"
            className={classes.header}
          >
            <div className={classes.title}>Edit {site?.siteName} Site</div>
            <div className={classes.breadcrumb}>
              <Breadcrumbs aria-label="breadcrumb">
                <ButtonBase
                  sx={{
                    fontWeight: pathname?.includes("location")
                      ? "bold"
                      : "normal",
                  }}
                >
                  Location
                </ButtonBase>
                <ButtonBase
                  sx={{
                    fontWeight: pathname?.includes("information")
                      ? "bold"
                      : "normal",
                  }}
                >
                  Information
                </ButtonBase>
                <ButtonBase
                  sx={{
                    fontWeight: pathname?.includes("modules")
                      ? "bold"
                      : "normal",
                  }}
                >
                  Modules
                </ButtonBase>
                <ButtonBase
                  sx={{
                    fontWeight: pathname?.includes("image") ? "bold" : "normal",
                  }}
                >
                  Upload Image
                </ButtonBase>
                <ButtonBase
                  sx={{
                    fontWeight: pathname?.includes("sensors")
                      ? "bold"
                      : "normal",
                  }}
                >
                  Sensors
                </ButtonBase>
              </Breadcrumbs>
            </div>
          </Stack>
        )}
        <Outlet />
      </div>
    </>
  );
}

export default EditSite;
