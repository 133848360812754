import { LinearProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { GET_FILTRATION } from "../../../../api/endpoints";
import { useRequest } from "../../../../api/hooks";
import FiltrationManagementIcon from "../../../../assets/images/Filteration Module.png";
import AccordionComponent from "../../../../components/accordion/AccordionComponent";
// import Tab from "../../../../components/tab/Tab";
import TableHeader from "../../../../components/table/TableHeader";

import Chart from "../../../../components/chart/Chart";
import {
  LOCAL_NOW,
  SEVEN_DAYS_AGO,
  dateByTimeZone,
  getUtcDateTimeString,
} from "../../../../utils/date";
import ChartInfoDateTime from "./commonComponents/ChartInfoDateTime";
import ChartInfoRow from "./commonComponents/ChartInfoRow";
import Warning from "./commonComponents/Warning";

function FiltrationManagement({ siteName, siteId, timeZone }) {
  const [expanded, setExpanded] = useState(false);
  let [startDate, setStartDate] = useState(SEVEN_DAYS_AGO);
  let [endDate, setEndDate] = useState(LOCAL_NOW);
  // let [tabs, setTabs] = useState(null);
  // let [tab, setTab] = useState(null);

  let ref = useRef(null);

  let [{ data, loading, message, error }, fetch] = useRequest(GET_FILTRATION, {
    method: "POST",
    data: {
      siteId: siteId,
      from: getUtcDateTimeString(startDate),
      to: getUtcDateTimeString(endDate),
    },
  });

  // useEffect(() => {
  //   let sortedTabs = [];
  //   for (let tab in data) {
  //     sortedTabs.push(tab);
  //   }
  //   setTabs([...sortedTabs]);
  //   setTab(sortedTabs?.[0]);
  // }, [data]);

  useEffect(() => {
    if (expanded) {
      fetch();
    }
  }, [startDate, endDate, expanded]);

  function expandHandle() {
    setExpanded(!expanded);
  }

  data = data?.filter ?? null;
  return (
    <AccordionComponent
      name="Filtration Management"
      icon={FiltrationManagementIcon}
      onChange={expandHandle}
      expanded={expanded}
    >
      <TableHeader
        startDate={startDate}
        endDate={endDate}
        siteName={siteName}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        title={false}
        // data={data?.[tab]?.data}
        data={data?.data}
        headers={["id", "dateTime", "value"]}
        refForPrint={ref}
      />
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {data ? (
            <div ref={ref} style={{ padding: 15 }}>
              {/* <Tab tabs={tabs} tab={tab} setTab={setTab} /> */}

              <ChartInfoRow
                title="Recovery"
                // value={data?.[tab]?.recovery}
                value={data?.recovery}
              />
              <ChartInfoDateTime
                // date={data?.[tab]?.date}
                //   time={data?.[tab]?.time}
                timeZone={timeZone}
                date={data?.date}
                time={data?.time}
              />
              <br />
              <Chart
                // series={[
                //   {
                //     data: data?.[tab]?.data?.map((item) => {
                //       return { x: item?.dateTime, y: item?.value };
                //     }),
                //   },
                // ]}
                series={[
                  {
                    data: data?.data?.map((item) => {
                      return {
                        x: dateByTimeZone(item?.dateTime, timeZone),
                        y: item?.value,
                      };
                    }),
                  },
                ]}
                title="Recovery"
              />
            </div>
          ) : (
            <Warning message={message} error={error} />
          )}
        </>
      )}
    </AccordionComponent>
  );
}

export default FiltrationManagement;
