import React from "react";

function DownloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height="18.021"
      viewBox="0 0 18 18.021"
    >
      <path
        id="icons8_desktop_download_1"
        d="M14.989,5.978a.75.75,0,0,0-.739.76l-.009,7.691-1.221-1.221A.75.75,0,1,0,11.96,14.27l2.5,2.5a.75.75,0,0,0,1.061,0l2.5-2.5a.75.75,0,1,0-1.061-1.061l-1.219,1.219L15.75,6.74a.75.75,0,0,0-.761-.762ZM8.75,5.989A2.761,2.761,0,0,0,6,8.739v12.5a2.761,2.761,0,0,0,2.75,2.75H20.127a.75.75,0,0,0,.243,0h.88A2.761,2.761,0,0,0,24,21.239V8.739a2.761,2.761,0,0,0-2.75-2.75h-3a.75.75,0,1,0,0,1.5h3a1.239,1.239,0,0,1,1.25,1.25v12.5a1.239,1.239,0,0,1-1.25,1.25H21v-1.75a1.762,1.762,0,0,0-1.75-1.75H16.873a.75.75,0,0,0-.243,0H13.373a.685.685,0,0,0-.243,0H10.75A1.762,1.762,0,0,0,9,20.739v1.75H8.75a1.239,1.239,0,0,1-1.25-1.25V8.739a1.239,1.239,0,0,1,1.25-1.25h3a.75.75,0,1,0,0-1.5Zm2,14.5H12.5v2h-2v-1.75A.239.239,0,0,1,10.75,20.489Zm3.25,0h2v2H14Zm3.5,0h1.75a.239.239,0,0,1,.25.25v1.75h-2Z"
        transform="translate(-6 -5.978)"
        fill="#484848"
      />
    </svg>
  );
}

export default DownloadIcon;
