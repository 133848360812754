import React from "react";

function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={15}
      viewBox="0 0 15 15"
    >
      <path
        id="icons8_clock_2"
        d="M11.5,4A7.5,7.5,0,1,0,19,11.5,7.509,7.509,0,0,0,11.5,4Zm0,1.125A6.375,6.375,0,1,1,5.125,11.5,6.366,6.366,0,0,1,11.5,5.125Zm-.571,1.867a.563.563,0,0,0-.554.571v4.125a.563.563,0,0,0,.165.4l1.875,1.875a.563.563,0,1,0,.8-.8l-1.71-1.71V7.563a.563.563,0,0,0-.571-.571Z"
        transform="translate(-4 -4)"
        fill="#8f8f8f"
      />
    </svg>
  );
}

export default ClockIcon;
