import React from "react";

function PeopleIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height="13.5"
      viewBox="0 0 15 13.5"
      {...props}
    >
      <path
        id="icons8_people"
        d="M11.5,6a2.233,2.233,0,0,0-1.686.716,2.367,2.367,0,0,0,0,3.067,2.342,2.342,0,0,0,3.372,0,2.367,2.367,0,0,0,0-3.067A2.233,2.233,0,0,0,11.5,6Zm-4.875.75a1.871,1.871,0,0,0-1.4.6,1.966,1.966,0,0,0,0,2.552,1.946,1.946,0,0,0,2.81,0,1.966,1.966,0,0,0,0-2.552A1.871,1.871,0,0,0,6.625,6.75Zm9.75,0a1.871,1.871,0,0,0-1.4.6,1.966,1.966,0,0,0,0,2.552,1.946,1.946,0,0,0,2.81,0,1.966,1.966,0,0,0,0-2.552A1.871,1.871,0,0,0,16.375,6.75ZM11.5,7.125a1.037,1.037,0,0,1,.845.338,1.246,1.246,0,0,1,0,1.573,1.037,1.037,0,0,1-.845.338,1.037,1.037,0,0,1-.845-.338,1.246,1.246,0,0,1,0-1.573A1.037,1.037,0,0,1,11.5,7.125Zm-4.875.75a.675.675,0,0,1,.564.221.845.845,0,0,1,0,1.058.675.675,0,0,1-.564.221.675.675,0,0,1-.564-.221.845.845,0,0,1,0-1.058A.675.675,0,0,1,6.625,7.875Zm9.75,0a.675.675,0,0,1,.564.221.845.845,0,0,1,0,1.058.83.83,0,0,1-1.128,0,.845.845,0,0,1,0-1.058A.675.675,0,0,1,16.375,7.875ZM5.313,11.25A1.314,1.314,0,0,0,4,12.563V15a3,3,0,0,0,3,3,2.931,2.931,0,0,0,.773-.1,4.188,4.188,0,0,1-.338-1.077A1.843,1.843,0,0,1,7,16.875,1.876,1.876,0,0,1,5.125,15V12.563a.186.186,0,0,1,.188-.187H7.386a2.027,2.027,0,0,1,.461-1.125Zm4.125,0a1.321,1.321,0,0,0-1.312,1.313v3.563a3.375,3.375,0,0,0,6.75,0V12.563a1.321,1.321,0,0,0-1.312-1.312Zm5.715,0a2.027,2.027,0,0,1,.461,1.125h2.073a.186.186,0,0,1,.187.188V15A1.876,1.876,0,0,1,16,16.875a1.843,1.843,0,0,1-.435-.053,4.188,4.188,0,0,1-.338,1.077A2.931,2.931,0,0,0,16,18a3,3,0,0,0,3-3V12.563a1.314,1.314,0,0,0-1.312-1.312ZM9.438,12.375h4.125a.179.179,0,0,1,.188.188v3.563a2.25,2.25,0,0,1-4.5,0V12.563A.179.179,0,0,1,9.438,12.375Z"
        transform="translate(-4 -6)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default PeopleIcon;
