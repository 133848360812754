import { LinearProgress, Stack, FormControl, FormLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import ApexChart from "react-apexcharts";
import { FILTER_DATA } from "../../../../../../api/endpoints";
import { useRequest } from "../../../../../../api/hooks";

import {
  LOCAL_NOW,
  SEVEN_DAYS_AGO,
  dateByTimeZone,
  getUtcDateTimeString,
  toLocalDateTime,
} from "../../../../../../utils/date";
import classes from "./SensorsList.module.scss";
import ExcelIcon from "../../../../../../assets/icons/ExcelIcon";
import { ExportToCsv } from "export-to-csv";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Button from "../../../../../../components/form/button/Button";

function Chart({
  sensor,
  title,
  lineColor = "#54D2BC",
  areaColor = "#54D2BC",
  showYLabels = true,
  timeZone,
}) {
  const [stateFrom, setStateFrom] = useState(SEVEN_DAYS_AGO);
  const [stateTo, setStateTo] = useState(LOCAL_NOW);

  let [{ data, loading }, getData] = useRequest(FILTER_DATA, {
    method: "POST",
    data: {
      sensorId: sensor?.id,
      reportDateTimeStart: getUtcDateTimeString(stateFrom),
      reportDateTimeEnd: getUtcDateTimeString(stateTo),
    },
  });

  let options = {
    legend: {
      show: true,
      position: "bottom",
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor: "#DFDFDF",
      strokeDashArray: 7,
      row: {
        colors: ["#fff"],
        opacity: 0.5,
      },
    },
    colors: [lineColor],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    yaxis: {
      labels: {
        show: showYLabels,
        style: {
          color: "#8F8F8F",
          cssClass: "chart_labels",
        },
        formatter: function (val) {
          return val?.toFixed(2);
        },
      },
      min: 0,
      tickAmount: 3,
      decimalsInFloat: 4,
      categories: [0, 100, 200, 300],
    },
    fill: {
      type: "gradient",
      gradient: {
        colors: [areaColor],
        stops: [64],
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "d MMM h:mm",
        style: {
          color: "#8F8F8F",
          cssClass: "chart_labels",
        },
        rotate: -45,
        minHeight: 70,
        rotateAlways: true,
        datetimeUTC: false,
      },
    },
    tooltip: {
      x: {
        format: "MMM d, yyyy  hh:mm:ss TT",
      },
      y: {
        formatter: undefined,
        title: {
          formatter: () => "",
        },
      },
    },
    title: {
      text: undefined,
    },
    // markers: {
    //   size: 5,
    //   hover: {
    //     size: 9,
    //   },
    // },
  };

  useEffect(() => {
    getData();
  }, []);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: title,
    Headers: ["DateTime", "Value"],
  };

  function filterClickHandle() {
    getData();
  }

  const csvExporter = new ExportToCsv(csvOptions);

  function exportToExcelHandle() {
    if (data) {
      csvExporter.generateCsv(
        data?.map((item) => {
          return {
            x: dateByTimeZone(item?.reportDateTime, timeZone),
            y: item?.sensorValue,
          };
        })
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <div className={classes.chart}>
            <div style={{ display: "flex" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormControl>
                  <FormLabel>From : </FormLabel>
                  <DatePicker
                    value={stateFrom}
                    onChange={(date) => {
                      setStateFrom(toLocalDateTime(date));
                    }}
                  />
                </FormControl>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <FormControl>
                  <FormLabel>To : </FormLabel>
                  <DatePicker
                    value={stateTo}
                    onChange={(date) => {
                      setStateTo(toLocalDateTime(date));
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
              <div
                style={{
                  marginTop: "20px",
                  marginLeft: "20px",
                }}
              >
                <Button onClick={filterClickHandle}>Filter</Button>
              </div>
            </div>

            {data && data?.length ? (
              <>
                <ApexChart
                  height={500}
                  width={900}
                  options={options}
                  series={[
                    {
                      data: data?.map((item) => {
                        return {
                          x: dateByTimeZone(item?.reportDateTime, timeZone),
                          y: item?.sensorValue,
                        };
                      }),
                    },
                  ]}
                  type="area"
                />
                <Stack
                  direction="row"
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ cursor: "pointer" }}
                >
                  {/* <div className={classes.title}>{title}</div> */}
                  <div onClick={exportToExcelHandle} className={classes.button}>
                    <ExcelIcon /> &nbsp; Excel Export
                  </div>
                </Stack>
              </>
            ) : (
              <div
                style={{ height: 200, display: "flex", alignItems: "center" }}
              >
                There is no data to display
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Chart;
