import React from "react";

function PrintIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height="19.83"
      viewBox="0 0 18 19.83"
    >
      <path
        id="icons8_send_to_printer"
        d="M12.989.933a.675.675,0,0,0-.664.684V2.742H11.65A.676.676,0,0,0,11.173,3.9l1.35,1.35a.675.675,0,0,0,.954,0l1.35-1.35a.675.675,0,0,0-.477-1.153h-.675V1.617a.675.675,0,0,0-.686-.684ZM8.264,3.2a.675.675,0,0,0-.664.685V6.363H6.925A2.935,2.935,0,0,0,4,9.288v6.3a2.035,2.035,0,0,0,2.025,2.025H7.6v1.125a2.035,2.035,0,0,0,2.025,2.025h6.75A2.035,2.035,0,0,0,18.4,18.738V17.613h1.575A2.035,2.035,0,0,0,22,15.588v-6.3a2.935,2.935,0,0,0-2.925-2.925H18.4V3.888a.675.675,0,1,0-1.35,0V6.363H8.95V3.888A.675.675,0,0,0,8.264,3.2ZM6.925,7.713H8.164a.675.675,0,0,0,.219,0h9.231a.675.675,0,0,0,.219,0h1.242A1.565,1.565,0,0,1,20.65,9.288v6.3a.665.665,0,0,1-.675.675H18.4V14.688a2.035,2.035,0,0,0-2.025-2.025H9.625A2.035,2.035,0,0,0,7.6,14.688v1.575H6.025a.665.665,0,0,1-.675-.675v-6.3A1.565,1.565,0,0,1,6.925,7.713Zm2.7,6.3h6.75a.665.665,0,0,1,.675.675v4.05a.665.665,0,0,1-.675.675H9.625a.665.665,0,0,1-.675-.675v-4.05A.665.665,0,0,1,9.625,14.013Z"
        transform="translate(-4 -0.933)"
        fill="#484848"
      />
    </svg>
  );
}

export default PrintIcon;
