import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import icon from "../../../../assets/images/locationMarker.png";
import DashedDivider from "../../../dashedDivider/DashedDivider";
import Button from "../../../form/button/Button";
import classes from "./Styles.module.scss";
import { LOCAL_NOW, showDate } from "../../../../utils/date";

function Success({ addMode, site }) {
  let navigate = useNavigate();
  return (
    <div className={classes.success}>
      <div className={classes.container}>
        <div className={classes.box}>
          <div className={classes.icon}>
            <img src={icon} alt="location icon" />
          </div>
          <div className={classes.title}>
            The New Site Has Been Successfully {addMode ? "Added" : "Updated"} .
          </div>
          <div className={classes.date}>{showDate(LOCAL_NOW)}</div>
          <DashedDivider />
          <div className={classes.info_container}>
            <div className={classes.info}>
              <div className={classes.key}>Site Name :</div>
              <div className={classes.value}>{site?.siteName}</div>
            </div>
            <div className={classes.info}>
              <div className={classes.key}>City:</div>
              <div className={classes.value}>{site?.city}</div>
            </div>
            <div className={classes.info}>
              <div className={classes.key}>Population:</div>
              <div className={classes.value}>{site?.people}</div>
            </div>
            <div className={classes.info}>
              <div className={classes.key}>Energy of Pumps:</div>
              <div className={classes.value}>{site?.pumpEnergy}</div>
            </div>
          </div>
          <Button
            onClick={() => {
              navigate("/");
            }}
          >
            Main Page
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Success;
