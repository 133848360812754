import React from "react";

export default function BottomArrowIcon({ color = "white", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.594"
      height="5.049"
      viewBox="0 0 8.594 5.049"
      {...props}
    >
      <g
        id="_271228"
        data-name="271228"
        transform="translate(8.594) rotate(90)"
      >
        <g id="Group_4" data-name="Group 4" transform="translate(0 0)">
          <path
            id="Path_4"
            data-name="Path 4"
            d="M4.912,4.632,1.087,8.457a.471.471,0,0,1-.665,0L.141,8.175a.471.471,0,0,1,0-.665L3.352,4.3.137,1.084a.471.471,0,0,1,0-.665L.419.137a.471.471,0,0,1,.665,0L4.912,3.965a.474.474,0,0,1,0,.667Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
