import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { getSensorTypes } from "../redux/sensors/SensorSlice";
import classes from "./Layout.module.scss";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";

function Layout() {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSensorTypes());
  }, []);
  return (
    <div className={classes.container}>
      <Header />
      <Grid container>
        <Grid item lg={2.5} md={3} sm={4}>
          <Sidebar />
        </Grid>
        <Grid item lg={9.5} md={9} sm={8}>
          <div className={classes.content}>
            <div className={classes.paper}>
              <Outlet />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Layout;
