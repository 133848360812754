import React from "react";
import Modules from "../../../components/AddAndEditSite/components/modules/Modules";

function EditModules() {
  return (
    <div>
      <div style={{ fontSize: 20, fontWeight: "bold", marginBottom: "10px" }}>
        Edit Modules
      </div>
      Complete the following information and click on the Save button.
      <Modules type="page" />
    </div>
  );
}

export default EditModules;
