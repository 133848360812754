import React from "react";

function OKIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      viewBox="0 0 14 14"
    >
      <path
        id="icons8_ok"
        d="M9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2ZM7.6,12.79,4.305,9.495l.99-.99L7.6,10.81l5.105-5.105.99.99Z"
        transform="translate(-2 -2)"
        fill="#00bc2d"
      />
    </svg>
  );
}

export default OKIcon;
