import { Box, Grid, IconButton, LinearProgress, Modal } from "@mui/material";
import MaterialReactTable from "material-react-table";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_SENSORS_TYPE,
  DELETE_SENSORS_TYPE,
  UPDATE_SENSORS_TYPE,
} from "../../api/endpoints";
import { useRequest } from "../../api/hooks";
import CloseIcon from "../../assets/icons/CloseIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import EditIcon from "../../assets/icons/EditIcon";
import SignalInput from "../../assets/icons/SignalInput";
import {
  addSensorType,
  deleteSensorType,
  updateSensorType,
} from "../../redux/sensors/SensorSlice";
import Button from "../form/button/Button";
import PrimaryInput from "../form/inputs/primaryInput/PrimaryInput";
import classes from "./SensorTypeModal.module.scss";

function SensorTypeModal({ open, onClose, siteId, ...props }) {
  const [parameter, setParameter] = useState(null);
  const [unit, setUnit] = useState(null);
  const [isEditingMode, setIsEditingMode] = useState(false);

  const sensorTypes = useSelector((state) => state?.sensorTypes?.list);
  const fetchTypesLoading = useSelector((state) => state?.sensorTypes?.loading);

  let dispatch = useDispatch();

  let [{ loading: addLoading }, addReqHandle] = useRequest(ADD_SENSORS_TYPE, {
    method: "POST",
    autoFetch: false,
  });

  let [, updateReqHandle] = useRequest(UPDATE_SENSORS_TYPE, {
    method: "PUT",
    autoFetch: false,
  });

  let [, deleteReqHandle] = useRequest(DELETE_SENSORS_TYPE, {
    method: "DELETE",
    autoFetch: false,
  });

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    updateReqHandle({
      id: row?.original?.id,
      ...values,
    }).then(function () {
      dispatch(
        updateSensorType({
          ...row?.original,
          ...values,
        })
      );
      exitEditingMode();
    });
  };

  const columns = useMemo(() => [
    {
      accessorKey: "parameter",
      header: "Parameter",
      size: 140,
      muiTableBodyCellEditTextFieldProps: () => ({
        variant: "outlined",
        size: "small",
      }),
    },
    {
      accessorKey: "unit",
      header: "Unit",
      size: 140,
      muiTableBodyCellEditTextFieldProps: () => ({
        variant: "outlined",
        size: "small",
      }),
    },
  ]);

  function addNewTypeHandle() {
    let newRow = {
      parameter,
      unit,
    };

    addReqHandle(newRow).then((req) => {
      resetForm();
      dispatch(addSensorType(req?.result));
    });
  }
  function resetForm() {
    setParameter("");
    setUnit("");
  }

  function RowAction({ row, table }) {
    const {
      getState,
      setEditingRow,
      options: {
        icons: { SaveIcon },
        onEditingRowSave,
        onEditingRowCancel,
      },
    } = table;
    const { editingRow } = getState();

    const handleCancel = () => {
      onEditingRowCancel?.({ row, table });
      setEditingRow(null);
      setIsEditingMode(false);
    };

    const handleSave = () => {
      onEditingRowSave?.({
        exitEditingMode: () => {
          handleCancel();
        },
        row: editingRow ?? row,
        table,
        values: editingRow?._valuesCache ?? { ...row.original },
      });
    };

    if (
      isEditingMode &&
      getState()?.editingRow?.original?.id == row?.original?.id
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "nowrap",
            gap: "8px",
          }}
        >
          <IconButton color="secondary" onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
          <IconButton color="error" onClick={handleSave}>
            <SaveIcon />
          </IconButton>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "nowrap",
          gap: "8px",
        }}
      >
        <IconButton
          color="secondary"
          onClick={() => {
            setEditingRow(row);
            setIsEditingMode(true);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="error"
          onClick={() => {
            deleteReqHandle({ id: row?.original?.id }).then(() => {
              dispatch(deleteSensorType(row?.original?.id));
            });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Modal open={open} onClose={onClose} {...props}>
      {fetchTypesLoading ? (
        <LinearProgress />
      ) : (
        <>
          <div className={classes.modal}>
            <Grid container alignItems="flex-end" spacing={2} mb={2}>
              <Grid item xs={5}>
                <PrimaryInput
                  label="Sensor Type *"
                  name="sensorTypeInput"
                  Icon={SignalInput}
                  value={parameter}
                  onChange={(e) => {
                    setParameter(e.target.value);
                  }}
                  placeholder="Enter Sensor Type"
                />
              </Grid>
              <Grid item xs={4}>
                <PrimaryInput
                  label="Unit *"
                  name="unit"
                  Icon={SignalInput}
                  value={unit}
                  onChange={(e) => {
                    setUnit(e.target.value);
                  }}
                  placeholder="Enter Unit"
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={addNewTypeHandle}
                  disabled={!parameter || !unit}
                  loading={addLoading}
                  sx={{ width: "50% !important" }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>

            <div className={classes.table_container}>
              {sensorTypes && (
                <MaterialReactTable
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      muiTableHeadCellProps: {
                        align: "center",
                      },
                      size: 80,
                    },
                  }}
                  columns={columns}
                  data={sensorTypes}
                  editingMode="row"
                  enableColumnOrdering={true}
                  enableEditing={true}
                  onEditingRowSave={handleSaveRowEdits}
                  enableFullScreenToggle={false}
                  enablePagination={false}
                  enableRowActions
                  renderRowActions={RowAction}
                />
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}

export default SensorTypeModal;
