import React from "react";

function DeleteIcon({ color = "#fff", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.102"
      height="15.3"
      viewBox="0 0 13.102 15.3"
      {...props}
    >
      <path
        id="Union_538"
        data-name="Union 538"
        d="M-9828.459-4461.7a2.185,2.185,0,0,1-2.123-2.237v-9.331h-.708a.731.731,0,0,1-.711-.746.731.731,0,0,1,.711-.746h1.316a.41.41,0,0,1,.084-.009h.012a.62.62,0,0,1,.1.009h2.03a2.356,2.356,0,0,1,2.294-2.241,2.359,2.359,0,0,1,2.3,2.241h2.032a.541.541,0,0,1,.2,0l1.315,0a.731.731,0,0,1,.708.749.728.728,0,0,1-.708.746h-.707v9.331a2.185,2.185,0,0,1-2.125,2.237Zm-.708-2.237a.72.72,0,0,0,.708.746h6.02a.721.721,0,0,0,.71-.746v-9.331h-7.437Zm2.851-10.822h1.735a.887.887,0,0,0-.868-.749A.885.885,0,0,0-9826.316-4474.759Zm1.607,9.856a.766.766,0,0,1-.21-.528v-5.6a.728.728,0,0,1,.7-.752h.009a.683.683,0,0,1,.5.218.788.788,0,0,1,.21.537v5.6a.733.733,0,0,1-.711.749A.678.678,0,0,1-9824.709-4464.9Zm-2.481,0a.763.763,0,0,1-.206-.528v-5.6a.728.728,0,0,1,.7-.752h.011a.686.686,0,0,1,.5.218.767.767,0,0,1,.206.537v5.6a.731.731,0,0,1-.708.749A.692.692,0,0,1-9827.19-4464.9Z"
        transform="translate(9832.001 4477)"
        fill={color}
      />
    </svg>
  );
}

export default DeleteIcon;
