import { LinearProgress } from "@mui/material";
import React from "react";
import Site from "./components/site/Site";

function SiteSelect({ sites, loading, setSite }) {
  return (
    <div style={{ paddingBottom: "16px" }}>
      {loading ? (
        <LinearProgress />
      ) : (
        <Site
          noAnySite={sites && !sites?.[0]}
          sites={sites?.[0]?.sites}
          setSite={setSite}
        />
      )}
    </div>
  );
}

export default SiteSelect;
