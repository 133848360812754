import React from "react";

function OKSensorIcon({
  width = "16.773",
  height = "16.774",
  color = "#fff",
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16.773 16.774"
      {...props}
    >
      <path
        id="Subtraction_820"
        data-name="Subtraction 820"
        d="M8.386,16.774a8.387,8.387,0,1,1,8.386-8.388A8.4,8.4,0,0,1,8.386,16.774ZM3.719,7.156h0L1.865,9.01l4.759,4.759,8.284-8.286L13.052,3.629l-6.429,6.43-2.9-2.9Z"
        fill={color}
      />
    </svg>
  );
}

export default OKSensorIcon;
