import React from "react";

function MenuSettingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.285"
      height={18}
      viewBox="0 0 17.285 18"
    >
      <path
        id="icons8_settings_2"
        d="M13.437,4a9.018,9.018,0,0,0-2.047.242.675.675,0,0,0-.518.584l-.143,1.306A1.349,1.349,0,0,1,8.844,7.22h0l-1.2-.528a.675.675,0,0,0-.765.156,8.983,8.983,0,0,0-2.055,3.542.675.675,0,0,0,.247.74l1.064.78a1.35,1.35,0,0,1,0,2.178l-1.064.78a.675.675,0,0,0-.247.74,8.981,8.981,0,0,0,2.055,3.543.675.675,0,0,0,.765.156l1.2-.528a1.351,1.351,0,0,1,1.886,1.089l.143,1.306a.675.675,0,0,0,.517.584,8.777,8.777,0,0,0,4.095,0A.675.675,0,0,0,16,21.174l.143-1.306a1.35,1.35,0,0,1,1.885-1.089l1.2.528A.675.675,0,0,0,20,19.151a8.987,8.987,0,0,0,2.055-3.543.675.675,0,0,0-.247-.74l-1.064-.78a1.35,1.35,0,0,1,0-2.178l1.064-.78a.675.675,0,0,0,.247-.74A8.987,8.987,0,0,0,20,6.849a.675.675,0,0,0-.765-.156l-1.2.528a1.35,1.35,0,0,1-1.885-1.089L16,4.826a.675.675,0,0,0-.517-.584A9,9,0,0,0,13.437,4Zm0,1.35a7.352,7.352,0,0,1,1.281.153l.084.776a2.7,2.7,0,0,0,3.771,2.178l.714-.314a7.587,7.587,0,0,1,1.285,2.216l-.633.464a2.7,2.7,0,0,0,0,4.354l.633.464a7.587,7.587,0,0,1-1.285,2.216l-.714-.314A2.7,2.7,0,0,0,14.8,19.721l-.084.776a7.374,7.374,0,0,1-1.281.153,7.355,7.355,0,0,1-1.281-.153l-.084-.776A2.7,2.7,0,0,0,8.3,17.543l-.714.314A7.582,7.582,0,0,1,6.3,15.641l.633-.464a2.7,2.7,0,0,0,0-4.355L6.3,10.358A7.583,7.583,0,0,1,7.587,8.142l.713.314a2.7,2.7,0,0,0,3.771-2.177l.084-.776A7.374,7.374,0,0,1,13.437,5.35Zm0,4.05a3.6,3.6,0,1,0,3.6,3.6A3.61,3.61,0,0,0,13.437,9.4Zm0,1.35A2.25,2.25,0,1,1,11.187,13,2.24,2.24,0,0,1,13.437,10.75Z"
        transform="translate(-4.795 -4)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default MenuSettingIcon;
