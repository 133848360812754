import React from "react";

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.799"
      height="19.799"
      viewBox="0 0 19.799 19.799"
    >
      <path
        id="Path_5657"
        data-name="Path 5657"
        d="M13.045,6.045H7.955V.955a.955.955,0,1,0-1.909,0V6.045H.955a.955.955,0,1,0,0,1.909H6.045v5.091a.955.955,0,1,0,1.909,0V7.955h5.091a.955.955,0,1,0,0-1.909Z"
        transform="translate(9.899) rotate(45)"
        fill="#484848"
      />
    </svg>
  );
}

export default CloseIcon;
