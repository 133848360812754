import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import classes from "./progress.module.scss";

function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        backgroundColor: "rgba(33,70,99 , 0.08) !important",
        borderRadius: "50%",
        width: "111px",
        height: "111px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        className={classes.circle}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={classes.percent}>
          {`${Math.round(props.value)}%`}
          <span>Remaining</span>
        </div>
      </Box>
    </Box>
  );
}

function Progress({ color, value, ...props }) {
  return (
    <CircularProgressWithLabel
      color={
        value <= 10
          ? "error"
          : value > 10 && value < 80
          ? "warning"
          : value >= 80
          ? "success"
          : "default"
      }
      value={value}
      {...props}
    />
  );
}

export default Progress;
