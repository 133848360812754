import CoagulationManagementIcon from "../../../../assets/images/Coagulation Module.png";
import AccordionComponent from "../../../../components/accordion/AccordionComponent";

import { Grid, LinearProgress } from "@mui/material";
// import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { GET_COAGULATION } from "../../../../api/endpoints";
import { useRequest } from "../../../../api/hooks";
import Chart from "../../../../components/chart/Chart";
import Tab from "../../../../components/tab/Tab";
import TableHeader from "../../../../components/table/TableHeader";
import ChartInfoRow from "./commonComponents/ChartInfoRow";
import ChartInfoDateTime from "./commonComponents/ChartInfoDateTime";
import Warning from "./commonComponents/Warning";
import { dateByTimeZone } from "../../../../utils/date";
function CoagulationManagement({ siteName, siteId, timeZone }) {
  const [expanded, setExpanded] = useState(false);
  // let [startDate, setStartDate] = useState(moment().subtract(7, "d"));
  // let [endDate, setEndDate] = useState(moment());
  let [tabs, setTabs] = useState(null);
  let [tab, setTab] = useState(null);
  let ref = useRef();

  let [{ data, message, error, loading }, fetch] = useRequest(
    GET_COAGULATION + siteId,
    {
      method: "GET",
      // data: {
      //   siteId: siteId,
      //   from: startDate,
      //   to: endDate,
      // },
      autoFetch: false,
    }
  );

  useEffect(() => {
    if (expanded) {
      fetch();
    }
  }, [expanded]);

  useEffect(() => {
    if (data) {
      let sortedTabs = [];
      for (let tab in data) {
        sortedTabs.push(tab);
      }
      setTabs([...sortedTabs]);
      setTab(sortedTabs?.[0]);
    }
  }, [data]);

  function expandHandle() {
    setExpanded(!expanded);
  }

  return (
    <AccordionComponent
      onChange={expandHandle}
      expanded={expanded}
      name="Coagulation Management"
      icon={CoagulationManagementIcon}
    >
      <TableHeader
        // startDate={startDate}
        // endDate={endDate}
        siteName={siteName}
        // setStartDate={setStartDate}
        // setEndDate={setEndDate}
        title={false}
        data={
          data?.[tab]
            ? [
                ...data?.[tab]?.treatedWaterTurbidityHistory,
                ...data?.[tab]?.rawWaterTurbidityHistory,
              ]
            : null
        }
        headers={["id", "date", "value"]}
        refForPrint={ref}
        filterDate={false}
      />
      <div ref={ref}>
        {loading ? (
          <LinearProgress />
        ) : (
          <>
            {data ? (
              <div style={{ padding: 15 }}>
                <Tab tabs={tabs} tab={tab} setTab={setTab} />
                <ChartInfoRow
                  title="Treated Water Turbidity"
                  value={data?.[tab]?.treatedWaterTurbidity}
                  unit="NTU"
                />
                <ChartInfoRow
                  title="Raw Water Turbidity"
                  value={data?.[tab]?.rawWaterTurbidity}
                  unit="NTU"
                />
                <ChartInfoRow
                  title="Performance"
                  value={data?.[tab]?.performance}
                  unit="%"
                />
                {tab === "today" && (
                  <>
                    <ChartInfoRow
                      title="ClearPAC"
                      value={data?.[tab]?.coagulantFlow1}
                      unit="mL/min"
                    />
                    <ChartInfoRow
                      title="CL2410"
                      value={data?.[tab]?.coagulantFlow2}
                      unit="mL/min"
                    />
                  </>
                )}

                <ChartInfoDateTime
                  date={data?.[tab]?.reportDateTime}
                  time={data?.[tab]?.reportDateTime}
                  convertTime={true}
                  timeZone={timeZone}
                />

                <br />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Chart
                      series={[
                        {
                          data: data?.[tab]?.treatedWaterTurbidityHistory?.map(
                            (item) => {
                              return {
                                x: dateByTimeZone(item?.dateTime, timeZone),
                                y: item?.value,
                              };
                            }
                          ),
                        },
                      ]}
                      title="Treated Water Turbidity"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Chart
                      series={[
                        {
                          data: data?.[tab]?.rawWaterTurbidityHistory?.map(
                            (item) => {
                              return {
                                x: dateByTimeZone(item?.dateTime, timeZone),
                                y: item?.value,
                              };
                            }
                          ),
                        },
                      ]}
                      lineColor="#E3B49B"
                      areaColor="#E3B49B"
                      title="Raw Water Turbidity"
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <Warning message={message} error={error} />
            )}
          </>
        )}
      </div>
    </AccordionComponent>
  );
}

export default CoagulationManagement;
