import moment from "moment-timezone";

export function formatDate(date) {
  return date ? moment(date).format("D MMM YYYY") : "";
}

export function getTime(date) {
  return date ? moment(date).format("h:mm a") : "";
}

export function convertDateTime(date, time) {
  // data picker and time picker for server request
  return date
    ? moment(
        moment(date).format("YYYY/MM/DDT") + time,
        "YYYY/MM/DDThh:mm a"
      ).format()
    : "";
}

export function fixTimeZone(timeZone) {
  if (timeZone === "-8:00") {
    timeZone = "-08:00";
  } else if (timeZone === "-7:00") {
    timeZone = "-07:00";
  } else if (timeZone === "-6:00") {
    timeZone = "-06:00";
  } else if (timeZone === "-5:00") {
    timeZone = "-05:00";
  } else if (timeZone === "-3:30") {
    timeZone = "-03:30";
  }
  return timeZone;
}

export function showDate(date, timeZone) {
  if (timeZone) {
    timeZone = fixTimeZone(timeZone);
    return date
      ? moment(date).parseZone().utcOffset(timeZone).format("lll")
      : "";
  } else {
    return date ? moment(date).format("lll") : "";
  }
}

export function showTime(date, timeZone) {
  if (timeZone) {
    timeZone = fixTimeZone(timeZone);
    return date
      ? moment(date).parseZone().utcOffset(timeZone).format("LTS")
      : "";
  } else {
    return date ? moment(date).format("LTS") : "";
  }
}

export let UTC = moment().utc();
export let LOCAL_NOW = moment(UTC).local();
export let SEVEN_DAYS_AGO = moment(UTC).local().subtract(7, "d");

export function toLocalDateTime(date) {
  return date ? moment(date).local() : "";
}

export function getLocalDateTimeString(date) {
  return date ? moment(date).local().format() : null;
}

export function dateByTimeZone(date, timeZone) {
  if (timeZone) {
    timeZone = fixTimeZone(timeZone);
    return date
      ? moment(date).parseZone().utcOffset(timeZone).format("lll")
      : null;
  } else {
    return date ? moment(date).parseZone().format() : null;
  }
}

export function getUtcDateTimeString(date) {
  return date ? moment(date).utc().format() : null;
}
