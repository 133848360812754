import React from "react";

function DangerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={15}
      viewBox="0 0 15 15"
    >
      <g id="Group_10912" data-name="Group 10912" transform="translate(-0.262)">
        <circle
          id="Ellipse_4697"
          data-name="Ellipse 4697"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(0.262)"
          fill="#ff2a2a"
        />
        <path
          id="Union_479"
          data-name="Union 479"
          d="M.35,9.5A1.216,1.216,0,0,1,0,8.624a1.176,1.176,0,0,1,.354-.883A1.217,1.217,0,0,1,1.239,7.4,1.183,1.183,0,0,1,2.466,8.624a1.228,1.228,0,0,1-.34.889,1.183,1.183,0,0,1-.887.351A1.181,1.181,0,0,1,.35,9.5ZM.685,6.576a.339.339,0,0,1-.34-.324L.121.349A.338.338,0,0,1,.462,0H1.991a.338.338,0,0,1,.34.349l-.237,5.9a.338.338,0,0,1-.34.323Z"
          transform="translate(6.02 2.466)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default DangerIcon;
