import React from "react";

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.001"
      height={15}
      viewBox="0 0 12.001 15"
    >
      <path
        id="icons8_user"
        d="M14,4a3.75,3.75,0,1,0,3.75,3.75A3.759,3.759,0,0,0,14,4Zm0,1.125A2.625,2.625,0,1,1,11.375,7.75,2.616,2.616,0,0,1,14,5.125ZM9.492,13A1.5,1.5,0,0,0,8,14.492v.571a3.5,3.5,0,0,0,1.99,3.023A8.44,8.44,0,0,0,14,19a8.44,8.44,0,0,0,4.01-.914,3.625,3.625,0,0,0,1.9-2.461H20V14.492A1.5,1.5,0,0,0,18.508,13Zm0,1.125h9.016a.359.359,0,0,1,.368.367V14.5h0v.563A2.285,2.285,0,0,1,17.467,17.1,7.475,7.475,0,0,1,14,17.875a7.475,7.475,0,0,1-3.467-.773,2.285,2.285,0,0,1-1.408-2.039v-.571A.359.359,0,0,1,9.492,14.125Z"
        transform="translate(-8 -4)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default UserIcon;
