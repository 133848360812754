import React from "react";

function DeleteIcon({ color = "#9e9e9e", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.5"
      height={15}
      viewBox="0 0 13.5 15"
      {...props}
    >
      <path
        id="icons8_trash_can_5"
        d="M12.75,4A2.259,2.259,0,0,0,10.5,6.25H6.562a.563.563,0,1,0,0,1.125H7.5v9.563A2.065,2.065,0,0,0,9.562,19h6.375A2.065,2.065,0,0,0,18,16.938V7.375h.938a.563.563,0,1,0,0-1.125H15A2.259,2.259,0,0,0,12.75,4Zm0,1.125A1.117,1.117,0,0,1,13.875,6.25h-2.25A1.117,1.117,0,0,1,12.75,5.125ZM11.062,9.25a.563.563,0,0,1,.563.563v5.625a.562.562,0,0,1-1.125,0V9.813A.563.563,0,0,1,11.062,9.25Zm3.375,0A.563.563,0,0,1,15,9.813v5.625a.562.562,0,0,1-1.125,0V9.813A.563.563,0,0,1,14.437,9.25Z"
        transform="translate(-6 -4)"
        fill={color}
      />
    </svg>
  );
}

export default DeleteIcon;
