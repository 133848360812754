import React from "react";

function OrderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.567"
      height="12.499"
      viewBox="0 0 18.567 12.499"
    >
      <path
        id="Subtraction_869"
        data-name="Subtraction 869"
        d="M2174.766,805a2.148,2.148,0,0,1-2.05-1.552l-.051-.182H2166.9l-.052.182a2.13,2.13,0,0,1-4.1,0l-.051-.182h-.5a1.7,1.7,0,0,1-1.7-1.7v-.87a.4.4,0,0,1,.394-.406h.006a.4.4,0,0,1,.282.117.4.4,0,0,1,.117.292v.867a.891.891,0,0,0,.9.9h.5l.051-.182a2.13,2.13,0,0,1,4.1,0l.052.182h5.3V794.2a.89.89,0,0,0-.9-.9H2170a.4.4,0,1,1,0-.8h1.3a1.7,1.7,0,0,1,1.68,1.511l.025.221h3.083a1.707,1.707,0,0,1,1.539.978l1.292,2.908.01.022a1.694,1.694,0,0,1,.137.668v2.758a1.7,1.7,0,0,1-1.7,1.7h-.5l-.052.182A2.148,2.148,0,0,1,2174.766,805Zm0-3.466a1.333,1.333,0,1,0,1.334,1.333A1.319,1.319,0,0,0,2174.766,801.534Zm-9.967,0a1.314,1.314,0,0,0-1.327,1.259v.019l0,.018a.532.532,0,0,1,0,.074l0,.018v.019a1.329,1.329,0,0,0,2.654,0v-.019l0-.018a.31.31,0,0,1,0-.074l0-.018v-.019A1.314,1.314,0,0,0,2164.8,801.534Zm9.967-.8a2.147,2.147,0,0,1,2.05,1.552l.052.182h.5a.89.89,0,0,0,.9-.9v-2.758a.521.521,0,0,0,0-.063v-.009l-.023-.238H2173v3.067l.41-.34A2.123,2.123,0,0,1,2174.766,800.734Zm-1.767-5.7V797.7h4.858l-.948-2.132a.9.9,0,0,0-.822-.535Zm-9.067,3.467H2160.9a.4.4,0,1,1,0-.8h3.033a.4.4,0,1,1,0,.8Zm1.733-2.6H2160.9a.4.4,0,1,1,0-.8h4.766a.4.4,0,1,1,0,.8Zm-4.766-2.6a.4.4,0,1,1,0-.8h6.5a.4.4,0,1,1,0,.8Z"
        transform="translate(-2160.5 -792.5)"
        fill="#fff"
      />
    </svg>
  );
}

export default OrderIcon;
