import { Button, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  SITE,
  SITE_REMOVE_PICTURE,
  SITE_UPLOAD_PICTURE,
} from "../../../../api/endpoints";
import { useRequest } from "../../../../api/hooks";
import DeleteIcon from "../../../../assets/icons/DeleteIcon2";
import OKIcon from "../../../../assets/icons/OKIcon";
import UploadIcon from "../../../../assets/icons/Upload";
import ButtonRow from "../../../form/button/ButtonRow";
import NextButton from "../../../form/button/NextButton";
import classes from "./Styles.module.scss";

function UploadImage() {
  let { siteID } = useParams();
  let navigate = useNavigate();
  let { pathname } = useLocation();
  let addMode = pathname?.includes("add");

  let [{ data: siteData, loading }] = useRequest(SITE + "/" + siteID, {
    method: "GET",
    autoFetch: true,
  });
  let [{ loading: uploadLoading }, uploadImageReq] = useRequest(
    SITE_UPLOAD_PICTURE,
    {
      method: "POST",
      autoFetch: false,
    }
  );
  let [{ loading: removeLoading }, removeSiteImage] = useRequest(
    SITE_REMOVE_PICTURE + "/" + siteID,
    {
      method: "GET",
      autoFetch: false,
    }
  );
  let [picture, setPicture] = useState(null);
  let [file, setFile] = useState(null);

  function fileChangeHandle(e) {
    let { files } = e.target;
    console?.log(files?.[0]);
    var reader = new FileReader();
    reader.onloadend = function () {
      let formData = new FormData();
      formData.append("Id", Number(siteID));
      formData.append("Image", files?.[0]);
      uploadImageReq(formData, "formData").then(() => {
        setFile(files?.[0]);
        setPicture(reader.result);
      });
    };
    reader.readAsDataURL(files?.[0]);
  }

  useEffect(() => {
    if (siteID && siteData) {
      setPicture(siteData?.picture);
    }
  }, [siteData]);

  function readableBytes(bytes) {
    var i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
  }

  function deleteHandle() {
    removeSiteImage().then(() => {
      setFile(null);
      setPicture(null);
    });
  }

  function nextHandle() {
    navigate(`${addMode ? "/add-site/" : "/edit-sites/"}${siteID}/sensors`);
  }
  return (
    <div>
      <div>
        4. Upload your telemetry design map as png/jpg file and click on the
        Next button.
      </div>
      <Stack direction="row" justifyContent="center">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.input_container}
        >
          {loading || uploadLoading || removeLoading ? (
            <CircularProgress />
          ) : picture ? (
            <>
              <div>
                <img
                  src={picture}
                  alt="site img"
                  className={classes.uploaded_image}
                />
              </div>
              <br />

              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ width: "500px" }}
              >
                <Stack direction="row" spacing={2}>
                  <OKIcon />
                  {file && (
                    <div>
                      <div style={{ fontSize: "13px" }}>{file?.name}</div>
                      <div style={{ color: "#9E9E9E", fontSize: "10px" }}>
                        {readableBytes(file?.size)}
                      </div>
                    </div>
                  )}
                </Stack>
                <Button
                  sx={{
                    color: "white !important",
                    background: "#9E9E9E",
                  }}
                  onClick={deleteHandle}
                >
                  <DeleteIcon color="white" /> &nbsp; Delete
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <div>
                <UploadIcon />
              </div>
              <br />

              <div>Select the map image file</div>
              <div
                style={{
                  fontSize: "13px",
                  color: "#9E9E9E",
                  marginTop: "10px",
                }}
              >
                Allowed formats: PNG, JPG (up to 2mb)
              </div>
              <input accept="image/*" type="file" onChange={fileChangeHandle} />
            </>
          )}
        </Stack>
      </Stack>

      <ButtonRow justifyContent="flex-end">
        <NextButton onClick={nextHandle} />
      </ButtonRow>
    </div>
  );
}

export default UploadImage;
