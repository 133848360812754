import React from "react";

function CompassIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="icons8_compass"
        d="M12,4a.6.6,0,1,0,.6.6A.6.6,0,0,0,12,4Zm2.2.33a.6.6,0,0,0-.18,1.176,6.82,6.82,0,0,1,4.473,4.473.6.6,0,1,0,1.146-.356,8.016,8.016,0,0,0-5.263-5.263A.6.6,0,0,0,14.2,4.33Zm-4.421,0a.6.6,0,0,0-.159.029A8.016,8.016,0,0,0,4.359,9.622a.6.6,0,1,0,1.146.356A6.82,6.82,0,0,1,9.978,5.505a.6.6,0,0,0-.2-1.175ZM15.385,8a.6.6,0,0,0-.213.045s-.865.356-1.859.841a10.842,10.842,0,0,0-2.737,1.69,10.842,10.842,0,0,0-1.69,2.737c-.484.994-.841,1.859-.841,1.859a.6.6,0,0,0,.791.78s.887-.38,1.891-.876a11.027,11.027,0,0,0,2.7-1.652,11.027,11.027,0,0,0,1.652-2.7c.5-1,.876-1.891.876-1.891A.6.6,0,0,0,15.385,8ZM14.178,9.81c-.089.188-.078.182-.179.387a13.2,13.2,0,0,1-1.423,2.379A13.2,13.2,0,0,1,10.2,14c-.2.1-.2.09-.387.179.078-.168.065-.159.154-.341a11.956,11.956,0,0,1,1.46-2.413,11.956,11.956,0,0,1,2.413-1.46C14.019,9.875,14.01,9.888,14.178,9.81ZM4.6,11.4a.6.6,0,1,0,.6.6A.6.6,0,0,0,4.6,11.4Zm7.4,0a.6.6,0,1,0,.6.6A.6.6,0,0,0,12,11.4Zm7.4,0a.6.6,0,1,0,.6.6A.6.6,0,0,0,19.4,11.4ZM4.92,13.592a.6.6,0,0,0-.561.786,8.016,8.016,0,0,0,5.263,5.263.6.6,0,0,0,.356-1.146,6.82,6.82,0,0,1-4.473-4.473.6.6,0,0,0-.585-.43Zm14.141,0a.6.6,0,0,0-.567.43,6.82,6.82,0,0,1-4.473,4.473.6.6,0,1,0,.356,1.146,8.016,8.016,0,0,0,5.263-5.263.6.6,0,0,0-.579-.786ZM12,18.8a.6.6,0,1,0,.6.6A.6.6,0,0,0,12,18.8Z"
        transform="translate(-4 -4)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default CompassIcon;
