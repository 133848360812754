import React from "react";

function SensorPointIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.84"
      height="23.83"
      viewBox="0 0 23.84 23.83"
      {...props}
    >
      <path
        id="icons8_pain_point"
        d="M15.01,3.014A11.915,11.915,0,1,1,3.09,14.934,11.937,11.937,0,0,1,15.01,3.014Zm0,2a9.915,9.915,0,1,0,9.92,9.92A9.931,9.931,0,0,0,15.01,5.014Zm0,1.92a8,8,0,1,1-8,8A8.011,8.011,0,0,1,15.01,6.934Zm0,2a6,6,0,1,0,6,6A6,6,0,0,0,15.01,8.934Zm.012,4.057A2.013,2.013,0,1,1,13.01,15,2.013,2.013,0,0,1,15.022,12.99Z"
        transform="translate(-3.09 -3.014)"
        fill="#ff2a2a"
      />
    </svg>
  );
}

export default SensorPointIcon;
