import React from "react";

function LocationInput({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height="17.642"
      viewBox="0 0 15 17.642"
      {...props}
    >
      <path
        id="icons8_Location_3"
        d="M14.5,4A7.5,7.5,0,0,0,8.8,16.367l0,0,0,0S12,20.014,13.132,21.1a1.992,1.992,0,0,0,2.736,0c1.295-1.235,4.328-4.726,4.328-4.726l0,0,0,0A7.494,7.494,0,0,0,14.5,4Zm0,1.324a6.171,6.171,0,0,1,4.694,10.183c-.006.007-3.11,3.557-4.239,4.634a.645.645,0,0,1-.909,0c-.944-.9-4.23-4.623-4.239-4.634h0A6.171,6.171,0,0,1,14.5,5.324Zm0,3.529a2.627,2.627,0,0,0-1.984.843,2.785,2.785,0,0,0,0,3.609,2.756,2.756,0,0,0,3.967,0,2.785,2.785,0,0,0,0-3.609A2.627,2.627,0,0,0,14.5,8.853Zm0,1.324a1.22,1.22,0,0,1,.994.4,1.466,1.466,0,0,1,0,1.851,1.22,1.22,0,0,1-.994.4,1.22,1.22,0,0,1-.994-.4,1.466,1.466,0,0,1,0-1.851A1.22,1.22,0,0,1,14.5,10.176Z"
        transform="translate(-7 -4)"
        fill="#9e9e9e"
      />
    </svg>
  );
}

export default LocationInput;
