import { LoadingButton } from "@mui/lab";
import classNames from "classnames";
import React from "react";
import classes from "./Button.module.scss";

function Button({ justifyContent, variant = "contained", children, ...props }) {
  return (
    <LoadingButton
      className={classNames(classes.container, {
        [classes.contained]: variant === "contained",
        [classes.outlined]: variant === "outlined",
        [classes.space_between]: justifyContent === "space-between",
      })}
      variant={variant}
      {...props}
    >
      {children}
    </LoadingButton>
  );
}

export default Button;
