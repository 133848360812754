import { Container, Grid } from "@mui/material";
import React from "react";
import BottleIcon from "../../../../../assets/icons/BottleIcon";
import ClockArrow from "../../../../../assets/icons/ClockArrow";
import CompassIcon from "../../../../../assets/icons/CompassIcon";
import HomeIcon from "../../../../../assets/icons/HomeIcon";
import LocationInput from "../../../../../assets/icons/LocationInput";
import PeopleIcon from "../../../../../assets/icons/PeopleIcon";
import PlayListIcon from "../../../../../assets/icons/PlayListIcon";
import SystemInformation from "../../../../../assets/icons/SystemInformation";
import ThirstIcon from "../../../../../assets/icons/ThirstIcon";
import PrimaryInput from "../../../../form/inputs/primaryInput/PrimaryInput";
import SelectBox from "../../../../form/selectBox/SelectBox";

let timeZones = [
  { value: "-08:00", name: "(GMT -8:00) Pacific Time (US & Canada)" },
  { value: "-07:00", name: "(GMT -7:00) Mountain Time (US & Canada)" },
  {
    value: "-06:00",
    name: "(GMT -6:00) Central Time (US & Canada), Mexico City",
  },
  {
    value: "-05:00",
    name: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
  },
  { value: "-03:30", name: "(GMT -3:30) Newfoundland" },
];

const SiteForm = ({ values, setValues }) => {
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "number" ? Number(value) : value,
    }));
  };

  function timeZoneChange(value) {
    setValues((prevValues) => ({
      ...prevValues,
      timeZoneInfoId: value,
    }));
  }

  function waterChange(value) {
    setValues((prevValues) => ({
      ...prevValues,
      waterOrWaste: value,
    }));
  }

  function wellChange(value) {
    setValues((prevValues) => ({
      ...prevValues,
      wellOrSurface: value,
    }));
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PrimaryInput
            label="Longitude (Autofill)"
            name="locationX"
            Icon={CompassIcon}
            disabled
            value={values?.locationX}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <PrimaryInput
            label="Latitude (Autofill)"
            name="locationY"
            Icon={CompassIcon}
            disabled
            value={values?.locationY}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectBox
            label="Time Zone"
            Icon={ClockArrow}
            name="timeZoneInfoId"
            selected={values?.timeZoneInfoId}
            setSelected={timeZoneChange}
            optionPropertyName="name"
            optionValuePropertyName="value"
            placeholder="Enter Time"
            items={timeZones}
          />
        </Grid>
        <Grid item xs={4}>
          <PrimaryInput
            label="Site Name"
            name="siteName"
            Icon={SystemInformation}
            value={values?.siteName}
            onChange={handleChange}
            placeholder="Enter Site Name"
          />
        </Grid>
        <Grid item xs={4}>
          <PrimaryInput
            label="City"
            name="city"
            Icon={LocationInput}
            value={values?.city}
            onChange={handleChange}
            placeholder="Enter City"
          />
        </Grid>
        <Grid item xs={4}>
          <PrimaryInput
            label="Population"
            name="people"
            type="number"
            Icon={PeopleIcon}
            value={values?.people}
            onChange={handleChange}
            placeholder="Enter the Population"
          />
        </Grid>
        <Grid item xs={4}>
          <PrimaryInput
            label="Number of Households"
            name="numberOfHomes"
            type="number"
            Icon={HomeIcon}
            value={values?.numberOfHomes}
            onChange={handleChange}
            placeholder="Enter Number of Households"
          />
        </Grid>
        <Grid item xs={4}>
          <SelectBox
            label="Water or Waste"
            name="waterOrWaste"
            Icon={ThirstIcon}
            selected={values?.waterOrWaste}
            setSelected={waterChange}
            placeholder="Select an Item"
            items={["water", "waste"]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectBox
            label="Well or Surface"
            name="wellOrSurface"
            Icon={PlayListIcon}
            selected={values?.wellOrSurface}
            setSelected={wellChange}
            placeholder="Select an Item"
            items={["well", "surface"]}
          />
        </Grid>
        <Grid item xs={4}>
          <PrimaryInput
            label="Energy of Pumps (kWh)"
            name="pumpEnergy"
            type="number"
            Icon={PlayListIcon}
            value={values?.pumpEnergy}
            onChange={handleChange}
            placeholder="Enter Energy of Pumps"
          />
        </Grid>
        <Grid item xs={4}>
          <PrimaryInput
            label="Volume of Tanks (Gallon)"
            name="volumeOfTanks"
            type="number"
            Icon={BottleIcon}
            value={values?.volumeOfTanks}
            onChange={handleChange}
            placeholder="Enter Volume of Tanks"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SiteForm;
