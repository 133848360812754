import React from "react";

function ExcelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.135"
      height={18}
      viewBox="0 0 17.135 18"
    >
      <path
        id="icons8_microsoft_excel"
        d="M13.842,4.042a1.54,1.54,0,0,0-.358.029L6.631,5.441A2.036,2.036,0,0,0,5,7.431v11.22a2.036,2.036,0,0,0,1.631,1.99l6.853,1.37a1.571,1.571,0,0,0,1.831-1.3h4.34a2.49,2.49,0,0,0,2.48-2.48V8.306a2.49,2.49,0,0,0-2.48-2.48H15.371V5.618A1.6,1.6,0,0,0,13.842,4.042ZM13.749,5.4a.213.213,0,0,1,.27.221V20.464a.213.213,0,0,1-.27.221h0L6.9,19.314h0a.668.668,0,0,1-.543-.662V7.431A.67.67,0,0,1,6.9,6.768Zm1.622,1.782h4.284a1.117,1.117,0,0,1,1.127,1.127v9.921a1.117,1.117,0,0,1-1.127,1.127H15.371Zm1.578,1.8a.676.676,0,1,0,0,1.353h1.8a.676.676,0,1,0,0-1.353Zm-8.343.973A.676.676,0,0,0,8.018,11l1.347,2.117L8.018,15.23a.677.677,0,0,0,1.141.727l1.008-1.584,1.008,1.583a.678.678,0,0,0,.572.314.667.667,0,0,0,.362-.106.678.678,0,0,0,.208-.934L10.97,13.112,12.316,11a.677.677,0,1,0-1.141-.727l-1.008,1.583L9.16,10.269A.675.675,0,0,0,8.607,9.956ZM16.95,12.59a.676.676,0,1,0,0,1.353h1.8a.676.676,0,1,0,0-1.353Zm0,3.607a.676.676,0,1,0,0,1.353h1.8a.676.676,0,1,0,0-1.353Z"
        transform="translate(-5 -4.041)"
        fill="#484848"
      />
    </svg>
  );
}

export default ExcelIcon;
