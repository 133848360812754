import { ButtonBase, Grid, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AlarmIcon from "../../../../assets/icons/AlarmIcon";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import PlayListIcon from "../../../../assets/icons/PlayListIcon";
import SignalInput from "../../../../assets/icons/SignalInput";
import Button from "../../../form/button/Button";
import ButtonRow from "../../../form/button/ButtonRow";
import PrimaryInput from "../../../form/inputs/primaryInput/PrimaryInput";
import SelectBox from "../../../form/selectBox/SelectBox";
import classes from "./Styles.module.scss";
import { useRequest } from "../../../../api/hooks";
import { SENSORS } from "../../../../api/endpoints";
import { toast } from "react-hot-toast";

export default function SensorModal({ sensor, setSensor, open, closeModal }) {
  const [formValues, setFormValues] = useState({
    ...sensor,
  });

  const [sortedSensorTypes, setSortedSensorTypes] = useState([]);
  const [formulaDisabled, setFormulaDisabled] = useState(true);

  let sensorTypes = useSelector((state) => state?.sensorTypes?.list);
  let sensorTypesLoading = useSelector((state) => state?.sensorTypes?.loading);

  useEffect(() => {
    if (sensorTypes) {
      let list = sensorTypes?.map((item) => {
        return {
          ...item,
          parameter: `${item?.parameter} (${item?.unit})`,
        };
      });
      setSortedSensorTypes([...list]);
    }
  }, [sensorTypes]);

  let [{ loading }, update] = useRequest(SENSORS + "/" + sensor?.id, {
    method: "PUT",
    data: {
      ...formValues,
    },
  });

  useEffect(() => {
    if (sensor) {
      setFormValues({ ...sensor });
    }
  }, [sensor]);

  const sensorTypeHandle = (typeId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      sensorTypeId: typeId,
    }));
  };
  const physicalHandle = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      isVirtual: value === "Virtual" ? true : false,
      formula: value === "Virtual" ? prevValues?.formula : "",
    }));
    setFormulaDisabled(value === "Virtual" ? false : true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  function updateHandle() {
    if (!formValues?.sensorCode || !formValues?.description) {
      return toast?.error("Sensor Code and Description is required");
    }
    update({ ...formValues }).then(() => {
      setSensor?.({ ...formValues });
      closeModal();
    });
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.modal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.modal_header}
          >
            <div className={classes.title}>Edit Sensor</div>
            <div>
              <ButtonBase onClick={closeModal}>
                <CloseIcon />
              </ButtonBase>
            </div>
          </Stack>
          <div className={classes.modal_body}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <PrimaryInput
                  label="Sensor Code *"
                  name="sensorCode"
                  Icon={SignalInput}
                  value={formValues?.sensorCode}
                  onChange={handleChange}
                  placeholder="Enter Sensor Code"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  label="Physical / Virtual Sensor"
                  name="isVirtual"
                  Icon={SignalInput}
                  selected={formValues?.isVirtual ? "Virtual" : "Physical"}
                  setSelected={physicalHandle}
                  placeholder="Select an Item"
                  items={["Physical", "Virtual"]}
                />
              </Grid>
              <Grid item xs={6}>
                <PrimaryInput
                  name="formula"
                  label="Formula"
                  Icon={SignalInput}
                  value={formValues?.formula}
                  onChange={handleChange}
                  placeholder="Enter the formula"
                  disabled={formulaDisabled}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  name="sensorTypeId"
                  label="Sensor Type"
                  loading={sensorTypesLoading}
                  Icon={SignalInput}
                  optionPropertyName="parameter"
                  optionValuePropertyName="id"
                  selected={formValues?.sensorTypeId}
                  setSelected={sensorTypeHandle}
                  placeholder="Select an Sensor Type"
                  items={sortedSensorTypes}
                />
              </Grid>
              <Grid item xs={12}>
                <PrimaryInput
                  label="Alert Condition"
                  name="alertConditions"
                  Icon={AlarmIcon}
                  value={formValues?.alertConditions}
                  onChange={handleChange}
                  placeholder="Enter Alert Condition"
                />
              </Grid>
              <Grid item xs={12}>
                <PrimaryInput
                  label="Description *"
                  name="description"
                  Icon={PlayListIcon}
                  value={formValues?.description}
                  onChange={handleChange}
                  placeholder="Enter Description"
                />
              </Grid>
            </Grid>
            <ButtonRow justifyContent="flex-end" mt={4}>
              <Button
                onClick={updateHandle}
                loading={loading}
                variant="contained"
              >
                Edit sensor
              </Button>
            </ButtonRow>
          </div>
        </div>
      </Modal>
    </div>
  );
}
